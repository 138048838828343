import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdminUserDetail } from "../../redux/thunk/admin/adUser";
const schema = yup.object().shape({
  username: yup.string().required("User Name is a required field"),
  email: yup.string().required("Email is a required field"),
  //   leads: yup.string().required(),
});
function EditAdminProfile() {
  const dispatch = useDispatch();
  //   const adminUsername = useSelector((state) => statepre.adAuth.adminData.username);
  //   const { adminUserDetail } = useSelector((state) => state.adUserList);
  const { adminData } = useSelector((state) => state?.adAuth);
  let data = { accessToken: adminData?.signInUserSession?.idToken?.jwtToken };
  const { id } = useParams();

  const handleOnSubmit = (values) => {
    const formData = {
      username: values.username,
      email: values.email,
    };

    // const data = {
    //   adminAuthtoken,
    //   values: {
    //     ...formData,
    //   }
    // };
    // dispatch(addSubscriptionPlan(data));
  };

  useEffect(() => {
    // dispatch(getAdminUserDetail({ ...data, id: id }));
  }, [id]);

  const formik = useFormik({
    initialValues: {
      //   username: adminUserDetail?.data[0]?.Username,
      //   email: adminUserDetail?.data[0]?.Email,
      username: "",
      email: "",
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });
  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );
  return (
    <div>
      <div className="card-sec bg-white p-3">
        <h3>Edit Admin Profile</h3>
        <div className="form-section mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-6 col-12">
              <div className="input_label_wrap">
                <label>User name</label>
                <input
                  type="text"
                  placeholder=" Enter user name"
                  value={formik.values.username}
                  onChange={(e) => setInputValue("username", e.target.value)}
                />
                <small className="error-text">
                  {formik.touched.username && formik.errors.username}
                </small>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="input_label_wrap">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter email"
                  value={formik.values.email}
                  onChange={(e) => setInputValue("email", e.target.value)}
                />
                <small className="error-text">
                  {formik.touched.email && formik.errors.email}
                </small>
              </div>
            </div>

            {!!formik.errors.planPrice && <br />}
            <div className="common_btns">
              <button
                title="Download"
                type="submit"
                // disabled={!formik.isValid}
                className="primary_btn"
              >
                Update profile
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default EditAdminProfile;
