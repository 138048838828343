import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CustomModal,
  Input,
  Loader,
  ReactDataTable,
} from "../../components/common";
import { getLeadRequestAllServices } from "../../services/admin/GetLeadReq/getLeadReqService";
import { getProgramCategoriesList } from "../../redux/thunk/user/usrCategories";
import DropDownField from "../../components/common/Dropdown";
import { toast } from "react-toastify";

export default function GetLeadRequest() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [userLeadMangementData, setUserLeadMangementData] = useState({
    userName: {
      value: "",
      warning: false,
    },
    leadRequest: {
      value: "",
      warning: false,
    },
    category: {
      value: "",
      warning: false,
    },
    ID: {
      value: "",
      warning: false,
    },
  });
  const [paginationItem, setPaginationItem] = useState({
    page: 1,
    itemsPerPage: 10,
  });
  const [dropDownData, setDropDownData] = useState([]);
  const [openUserDataModal, setOpenUserDataModal] = useState(false);
  const token = useSelector(
    (state) => state?.adAuth.adminData.signInUserSession.idToken.jwtToken
  );

  const columns = [
    {
      name: <div className="showFilter">Influencer-username</div>,
      selector: (row, index) => row.Insta_Username,
      width: "250px",
    },
    {
      name: <div className="showFilter"> Username</div>,
      selector: (row, index) => row.username,
      width: "350px",
    },

    {
      name: <div className="showFilter">Leadcount</div>,
      selector: (row) => row.Lead_Request,
    },
    {
      name: "Status",
      selector: (row, index) => (
        <div className="actions_btns">
          <div className="action_btns">
            {/* <DropDownField
              value={row.status_}
              data={[
                { label: "Pending", value: "Pending" },
                { label: "Fullfield", value: "Fullfield" },
              ]}
              name={""}
              attr={[row, index]}
              onChange={()=>{}}
            /> */}
            {row.status_}
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row, index) => (
        <div className="actions_btns">
          <div className="action_btns">
            {row.status_ === "Pending" ? (
              <Button
                onClick={() => {
                  setUserLeadMangementData((prev) => ({
                    ...prev,
                    userName: { ...prev.userName, value: row.Insta_Username },
                    leadRequest: {
                      ...prev.leadRequest,
                      value: row.Lead_Request,
                    },
                    ID: { ...prev.ID, value: row.ID },
                  }));
                  setOpenUserDataModal(true);
                }}
                className={"btn btn-primary button-width"}
                title={"Send"}
                style={{ minWidth: "81px" }}
              />
            ) : (
              <div className="actions_btns">
                <Button
                  className="button-width"
                  title={"Sent   "}
                  style={{ minWidth: "81px" }}
                />
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getLeadRequestData(paginationItem.page);
    dispatch(getProgramCategoriesList()).then((res) => {
      /// ** manipulate the dropdown data get from the backend as per the frontend required **
      let data = [];
      if (res?.payload?.body?.data && res?.payload?.body?.data?.length > 0) {
        res?.payload?.body?.data?.forEach((element) => {
          data.push({
            label: element,
            value: element,
          });
        });
        data.unshift({
          value: "Choose a Category",
          label: "Choose a Category",
        });
        setDropDownData(data);
      }
    });
  }, [paginationItem]);
  const getLeadRequestData = (pageState) => {
    const dataToSend = {
      page_no: pageState,
      page_size: paginationItem.itemsPerPage,
    };
    setLoading(true);
    const onSuccess = (res) => {
      let data = { ...res?.data };
      let arrPending = [];
      let arrFullfiled = [];
      res?.data?.data?.forEach((resItem) => {
        if (resItem.status_ === "Pending") {
          arrPending.push(resItem);
        }
        if (resItem.status_ === "fullfilled") {
          arrFullfiled.push(resItem);
        }
      });
      data = { ...data, data: arrPending.concat(arrFullfiled) };
      setLeadData(data);
      setLoading(false);
    };
    const onError = (err) => {
      console.log(err);
      setLoading(false);
    };
    getLeadRequestAllServices.getLeadRequest(
      token,
      dataToSend,
      onSuccess,
      onError,
      {}
    );
  };
  const onSubmitUserLeadData = (e) => {
    e.preventDefault();
    let data = { ...userLeadMangementData };
    data = {
      ...data,
      category: {
        ...data.category,
        warning:
          data.category.value === "" ||
          data.category.value === "Choose a Category"
            ? true
            : false,
      },
      userName: {
        ...data.userName,
        warning: data.userName.value === "" ? true : false,
      },
    };
    setUserLeadMangementData({ ...data });
    if (!data.category.warning && !data.userName.warning) {
      sendInfluencerDataThroughEmail();
    }
  };

  const sendInfluencerDataThroughEmail = () => {
    setLoading(true);
    setOpenUserDataModal(false);
    const onSuccess = (res) => {
      toast.success(res?.data?.message);
      getLeadRequestData(paginationItem.page);
      setLoading(false);
      setUserLeadMangementData({
        userName: {
          value: "",
          warning: false,
        },
        leadRequest: {
          value: "",
          warning: false,
        },
        category: {
          value: "",
          warning: false,
        },
      });
    };
    const onError = (err) => {
      console.log("er", err);
      setLoading(false);
    };
    const body = {
      ID: userLeadMangementData.ID.value,
      category: userLeadMangementData.category.value,
    };
    getLeadRequestAllServices.sendINfluencerDataThroughEmail(
      token,
      body,
      onSuccess,
      onError,
      {}
    );
  };
  return (
    <div>
      <CustomModal
        handleClose={() => {
          setOpenUserDataModal(false);
          setUserLeadMangementData({
            userName: {
              value: "",
              warning: false,
            },
            leadRequest: {
              value: "",
              warning: false,
            },
            category: {
              value: "",
              warning: false,
            },
          });
        }}
        show={openUserDataModal}
        modalHead={"User Lead Data"}
      >
        <form onSubmit={(e) => onSubmitUserLeadData(e)}>
          <div className="input_label_wrap">
            <Input
              label={"UserName"}
              value={userLeadMangementData.userName.value}
              onChange={(e) =>
                setUserLeadMangementData((prev) => ({
                  ...prev,
                  userName: { value: e.target.value, warning: false },
                }))
              }
              error={
                userLeadMangementData.userName.warning ? "Enter user name" : ""
              }
            />
            <Input
              label={"Count"}
              disabled={true}
              value={userLeadMangementData.leadRequest.value}
            />
            <DropDownField
              attr={"category"}
              data={
                dropDownData && dropDownData?.length > 0 ? dropDownData : []
              }
              value={userLeadMangementData.category.value}
              onChange={(attr, value) => {
                setUserLeadMangementData((prev) => ({
                  ...prev,
                  [attr]: { value: value, warning: false },
                }));
              }}
              name={"Category"}
              errorText={`Select Category`}
              error={userLeadMangementData.category.warning}
            />
            <button className="btn btn-primary mt-2" type="submit">
              Send
            </button>
          </div>
        </form>
      </CustomModal>
      {loading ? (
        <Loader />
      ) : (
        <div className="card-sec bg-white">
          <h3>Lead Management</h3>
          <div className="user-manage-table mt-4">
            <ReactDataTable
              onChangePage={() => {}}
              total_records={leadData?.total_data_length_}
              isHaveNextPage={leadData?.next_page}
              data={leadData.data}
              columns={columns}
              setPaginationItem={setPaginationItem}
              paginationItem={paginationItem}
            />
          </div>
        </div>
      )}
    </div>
  );
}
