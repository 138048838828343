export const userLeadsteps = [
  {
    target: ".title",
    content: "Welcome to Audiences Builder.Experience the power of our platform risk-free with our 2-week free trial, where you can pull 50 records per pull, up to 2 pulls. Unlock even more potential with our flexible pricing options starting at just $79 for 1000 credits. Get started today and unleash the insights you need to succeed!",
    disableBeacon: true,
  },
  {
    target: "#enterLead",
    content: "Here you can Enter the number of Leads you want to pull.",
    disableBeacon: true,
  },
  {
    target: "#categories",
    content: "Choose Catageory ",
    disableBeacon: true,
  },
];
