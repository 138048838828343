import { createSlice } from "@reduxjs/toolkit";
import { getUserHistory, getUserHistoryById } from "../../thunk/user/usrUsers";
import { toast } from "react-toastify";

const initialState = {
  userHistory: {},
  userHistoryById: [],
};
const userHistorySlice = createSlice({
  name: "userHistorySlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get user history

    builder
      .addCase(getUserHistory.pending, (state) => {
        state.status = false;
      })
      .addCase(getUserHistory.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload.success === true) {
          // let sortedArray = payload.data.sort((a, b) => new Date(a.Date) - new Date(b.Date));
          // let reverseArray = sortedArray.reverse();
          state.userHistory = payload;
          state.status = true;
        } else {
          toast.error(payload.message);
        }
      })
      .addCase(getUserHistory.rejected, (state, action) => {
        state.status = false;
      });

    //  ----------- getUserHistoryById ----------------

    builder
      .addCase(getUserHistoryById.pending, (state) => {
        // state.status = false;
      })
      .addCase(getUserHistoryById.fulfilled, (state, action) => {
        const { payload } = action;
      })
      .addCase(getUserHistoryById.rejected, (state, action) => {
        // state.status = false;
      });
  },
});

export default userHistorySlice.reducer;
