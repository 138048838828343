import {
  Subscriptions,
  Dashboard,
  EditUserProfile,
  Speaker,
  Tags,
  AdminLogin,
  UserList,
  AddPricing,
  TermAndConditions,
  SubscriptionManagement,
  AdminTable,
  History,
  EditPricingPlan,
  EditAdminProfile,
} from "../../pages/admin";
import BillingHistory from "../../pages/admin/BillingHistory";
import CreateSubAdmin from "../../pages/admin/CreateSubAdmin";
import GetLeadRequest from "../../pages/admin/GetLeadRequest";
import UserManagement from "../../pages/admin/UserManagement";
import AdminChat from "../../pages/admin/adminChat/adminChat";
import NotFound from "../../pages/common/NotFound";
import { ROUTES } from "../constants";

const {
  adLogin,
  adDashboard,

  adUserList,
  adEditUserProfile,
  adEditAdminProfile,
  adChat,
  editPricingPlan,
  adSubscriptions,
  adUserManagement,
  adAdminTable,
  adHistory,
  createSubAdmin,

  adSubscriptionManagement,
  adAddPricing,
  adPrivacy,
  adTermConditions,
  getLeadReq,
  adUpdateProgram,
  adbillingHistory,
} = ROUTES;

export const adminRoutes = [
  {
    id: 1,
    type: "private",
    path: adDashboard,
    Component: Dashboard,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 2,
    type: "private",
    path: adUserList,
    Component: UserList,
    Auth: true,
    defaultComp: false,
  },

  {
    id: 3,
    type: "private",
    path: adEditUserProfile,
    Component: EditUserProfile,
    Auth: true,
    defaultComp: false,
  },

  {
    id: 4,
    type: "private",
    path: adSubscriptions,
    Component: Subscriptions,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 5,
    type: "private",
    path: adUserManagement,
    Component: UserManagement,
    Auth: true,
    defaultComp: false,
  },

  {
    id: 6,
    type: "private",
    path: adHistory,
    Component: History,
    Auth: true,
    defaultComp: false,
  },

  {
    id: 7,
    type: "private",
    path: adSubscriptionManagement,
    Component: SubscriptionManagement,
    Auth: true,
    defaultComp: false,
  },

  {
    id: 8,
    type: "private",
    path: adAddPricing,
    Component: AddPricing,
    Auth: true,
    defaultComp: false,
  },

  {
    id: 9,
    type: "public",
    path: "*",
    Component: NotFound,
    Auth: false,
    defaultComp: false,
  },

  {
    id: 10,
    type: "private",
    path: getLeadReq,
    Component: GetLeadRequest,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 11,
    type: "public",
    path: adLogin,
    Component: AdminLogin,
    Auth: false,
    defaultComp: true,
  },
  {
    id: 12,
    type: "private",
    path: editPricingPlan,
    Component: EditPricingPlan,
    Auth: false,
    defaultComp: true,
  },
  {
    id: 13,
    type: "private",
    path: adEditAdminProfile,
    Component: EditAdminProfile,
    Auth: false,
    defaultComp: true,
  },
  {
    id: 14,
    type: "private",
    path: adChat,
    Component: AdminChat,
    Auth: false,
    defaultComp: true,
  },

  {
    id: 15,
    type: "private",
    path: adbillingHistory,
    Component: BillingHistory,
    Auth: false,
    defaultComp: true,
  },
];
