import React, { useEffect, useState } from "react";
import "./index.css";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  hideRootLoader,
  showRootLoader,
} from "../../../redux/reducers/common/appSlice";
import axiosInstance from "../../../services/axiosInstance";
import { userApi } from "../../../services/apiEndpoints";
import { formatDateWithDayDateTime } from "../../../shared/helperFunction";

const PaymentHistory = () => {
  // state for hold api data of payment history
  const [paymentData, setPaymentData] = useState([]);
  // get userdata from store
  const userData = useSelector((state) => state.userList);
  // call dispatch
  const dispatch = useDispatch();
  // column set for payment history table
  const columns = [
    {
      name: "Event",
      selector: (row) => (
        <span>
          {" "}
          {row.eventType === "expired_card"
            ? "Card Expired"
            : row.eventType === "card_declined"
            ? "Card Declined"
            : row.eventType === "incorrect_cvc"
            ? "Incorrect CVC"
            : row.eventType === "processing_error"
            ? "Processing Error"
            : row.eventType}
        </span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Plan",
      selector: (row) => row.planName,
      sortable: true,
      width: "130px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount / 100 + " $",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          className={
            row.status_ === "canceled"
              ? "text-danger text-capitalize"
              : row.status_ === "active"
              ? "text-success text-capitalize"
              : "text-info text-capitalize"
          }
        >
          {" "}
          {row.status_}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate,
      sortable: true,
      width: "150px",
    },
    {
      name: "End Date",
      selector: (row) => row.endDate,
      sortable: true,
      width: "150px",
    },
    {
      name: "Updated ON",
      selector: (row) => formatDateWithDayDateTime(row.time_),
      sortable: true,
      width: "390px",
    },
  ];
  // get the api data
  const getPaymentData = async () => {
    const body = {
      customerID: userData?.userDataFromDB?.CustomerID,
    };
    try {
      dispatch(showRootLoader());
      const res = await axiosInstance.post(
        userApi.userSubscriptionHistory,
        body
      );
      if (res?.statusCode === 200) {
        setPaymentData(res?.data);
      }
      dispatch(hideRootLoader());
    } catch (error) {
      console.log(error);
      dispatch(hideRootLoader());
    }
  };
  // effect for load api data at initial time
  useEffect(() => {
    const customerID = userData?.userDataFromDB?.CustomerID;
    if (customerID) {
      getPaymentData();
    } else {
      dispatch(showRootLoader());
    }
  }, [userData?.userDataFromDB?.CustomerID]);

  return (
    <div className="paymentHistoryWrapper">
      <h3>Billing History</h3>
      <div className="tableData mt-3">
        <DataTable
          columns={columns}
          data={paymentData}
          pagination
          highlightOnHover
        />
      </div>
    </div>
  );
};

export default PaymentHistory;
