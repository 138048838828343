import { createAsyncThunk } from "@reduxjs/toolkit";
import httpsClient from "../../../services/httpsClient";
import { adminApi } from "../../../services/apiEndpoints";
import {
  hideBtnLoader,
  hideLoader,
  showBtnLoader,
  showLoader,
} from "../../reducers/common/appSlice";

const { getSubscriptionList, createSubscription } = adminApi;

export const getSubscriptionListApi = createAsyncThunk(
  "admin/getSubscriptionList",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    // const { adminAuthtoken, values } = data;
    try {
      const config = {
        method: "get",
        url: `${getSubscriptionList}`,
      };
      dispatch(showLoader());
      const response = await httpsClient(config);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addSubscriptionPlan = createAsyncThunk(
  "admin/createSubscription",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { adminAuthtoken, values } = data;
    try {
      const config = {
        method: "post",
        url: `${createSubscription}`,
        data: values,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, adminAuthtoken);

      if (response.message === "successfully created a plan") {
        dispatch(getSubscriptionListApi());
      }

      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSubscriptionByID = createAsyncThunk(
  "admin/getSubscriptionByID",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { adminAuthtoken, values } = data;

    try {
      const config = {
        method: "post",
        // url: "https://8ycjf4c9i4.execute-api.us-east-2.amazonaws.com/dev/getSubscriptionByID",
        url: "https://8ycjf4c9i4.execute-api.us-east-2.amazonaws.com/dev/getSubscriptionByID",
        data: values,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, adminAuthtoken);
      if (response) {
      }
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateNextMonthPlan = createAsyncThunk(
  "admin/updateSubscriptionByID",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { userAuthtoken, values } = data;

    try {
      const config = {
        method: "post",
        url: "https://f73vice6w1.execute-api.us-east-2.amazonaws.com/dev",
        data: values,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, userAuthtoken);
      if (response) {
      }
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// export const updateSubscriptionByID = createAsyncThunk(
//   "admin/updateSubscriptionByID",
//   async (data, thunkAPI) => {
//     const { dispatch } = thunkAPI;
//     const { adminAuthtoken, values } = data;

//     try {
//       const config = {
//         method: "post",
//         url: "",
//         data: values,
//       };
//       dispatch(showLoader());
//       const response = await httpsClient(config, adminAuthtoken);
//       if (response) {

//       }
//       dispatch(hideLoader());
//       return response;
//     } catch (error) {
//       dispatch(hideLoader());
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );
