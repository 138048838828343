import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CheckoutForm, CustomModal } from "../../../components/common";
import {
  getSubscriptionListApi,
  updateNextMonthPlan,
} from "../../../redux/thunk/admin/Subscription";
import "../../../styles/admin/categories.scss";
import { useModal } from "../../../utility/hooks";
import moment from "moment";
import { toast } from "react-toastify";
import { userSubscriptionServices } from "../../../services/user/userSubscription/userSubscription";
import { getUserDetail } from "../../../redux/thunk/user/usrUsers";
import { userMakePayment } from "../../../redux/thunk/user/usrPayment";
import { modifiedUserDataFromDB } from "../../../redux/reducers/userSlices/usrUserList";
import {
  globalCurrencyFormat,
  numberToCommaFormat,
} from "../../../shared/helperFunction";
import { useRefreshToken } from "../../../utility/hooks";
import {
  hideRootLoader,
  showRootLoader,
} from "../../../redux/reducers/common/appSlice";
import { Loader } from "../../../components/common";
import { useNavigate } from "react-router";
import { getUserCardService } from "../../../services/user/saveCardDetails/saveCardDetails";

function SaveUserSubscription() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refreshUserToken } = useRefreshToken();
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const userAuthtoken = useSelector(
    (state) => state?.userAuth?.userData?.signInUserSession?.idToken?.jwtToken
  );
  const [loading, setLoading] = useState(false);
  // const { adminSpeakers } = useSelector((state) => state.adSpeaker);
  const { userDataFromDB } = useSelector((state) => state.userList);

  const { loader } = useSelector((state) => state.app);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const adminSubscriptionList = useSelector(
    (state) => state.adSubscription.adminSubscriptionList.data
  );
  const { userData } = useSelector((state) => state.userAuth);

  const customButtonLoader = (
    <Spinner animation="border" role="status">
      <span className="sr-only"></span>
    </Spinner>
  );

  const [plan, setPlan] = useState("");
  useEffect(() => {
    refreshUserToken();
    if (cancelModalShow === false) {
      dispatch(getSubscriptionListApi());
    }
  }, [cancelModalShow]);
  const {
    show,
    handleClose,
    handleShow,
    shareShow,
    handleShareClose,
    handleShareShow,
  } = useModal();

  function planHandler(planId, buttonName) {
    if (buttonName === "join") {
      const values = {
        PlanID: planId,
        UserID: userData?.attributes?.sub,
      };
      dispatch(showRootLoader());
      // after hit this api the user will navigate to stripe checkout form
      dispatch(userMakePayment({ userAuthtoken, values }))
        .then((res) => {
          if (res?.payload?.success === true) {
            window.location.href = res?.payload?.checkout_url; // get the url from the backend of stripe chekcout
          }
          dispatch(hideRootLoader());
        })
        .catch((err) => {
          console.log("Err", err);
          toast.error("Error");
        });
    }
  }

  const updatePlanHandler = (updated_plan) => {
    const individualPlan = adminSubscriptionList.find(
      (plan) => plan.PlanName === "Individual"
    );

    if (individualPlan) {
      let user_id = userDataFromDB?.UserID;
      setError(null);

      try {
        dispatch(showRootLoader());

        // Removing the return statement to allow further execution
        // return;

        const onSuccess = (res) => {
          if (res?.data?.statusCode === 200) {
            window.location.href = res?.data?.checkout_url;
            dispatch(hideRootLoader());
          } else {
            const message = res?.data?.message || "An unknown error occurred";
            toast.error(message);
          }
          dispatch(hideRootLoader());
        };

        const onError = (err) => {
          dispatch(hideRootLoader());
        };

        const body = {
          UserID: user_id,
          priceId: individualPlan.SubscriptionID,
        };

        getUserCardService.saveCardDetails(
          userAuthtoken,
          body,
          onSuccess,
          onError,
          {}
        );
      } catch (error) {
        setError("An error occurred while saving the card.");
        dispatch(hideRootLoader());
      }
    } else {
      dispatch(hideRootLoader());
    }
  };

  const cancelModal = () => {
    setCancelModalShow(true);
  };

  const cancelCurrentPlan = () => {
    setLoading(true);
    const onSuccess = (res) => {
      if (res?.data?.success === false) {
        toast.error(res?.data?.message);
      } else if (res?.data?.success === true) {
        toast.success(res?.data?.message);
        setCancelModalShow(false);
        // change the key currentplan key value aftersuccessfully cancel plan api is hitted
        let dataSendToRedux = { ...userDataFromDB };
        dataSendToRedux = {
          ...dataSendToRedux,
          CurrentPlan: "",
        };
        dispatch(modifiedUserDataFromDB(dataSendToRedux));
      }
      setLoading(false);
    };
    const onError = (err) => {
      setLoading(false);
    };
    const body = {
      UserID: userDataFromDB?.UserID,
      // SubscriptionID: userDataFromDB?.SubscriptionID,
    };
    userSubscriptionServices.cancelPlan(
      userAuthtoken,
      body,
      onSuccess,
      onError,
      {}
    );
  };
  return (
    <>
      <CustomModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        modalHead="Payment Screen"
        className="user_modal"
      >
        <CheckoutForm planID={plan} onCancel={() => handleClose()} />
      </CustomModal>
      {/* cancel the plan modal */}
      <CustomModal
        show={cancelModalShow}
        handleClose={() => (loading ? "" : setCancelModalShow(false))}
        handleShow={() => setCancelModalShow(true)}
        modalHead="Cancel Plan"
        className="user_modal"
      >
        <h3>Are you sure you want to cancel this plan</h3>
        {loading ? (
          <Button className="btn btn-danger">Loading...</Button>
        ) : (
          <Button
            className="btn btn-danger"
            onClick={() => cancelCurrentPlan()}
          >
            Cancel Plan
          </Button>
        )}
      </CustomModal>
      {/* cancel the plan modal */}
      <div
        className="categories_section card-sec  p-3"
        style={{ backgroundColor: "#2780cd" }}
      >
        <h3
          className="title text-center pt-5 text-white text-uppercase"
          style={{ letterSpacing: "1px" }}
        >
          Choose Your Plan
        </h3>
        <div style={{ minHeight: "100vh" }}>
          <Row className="justify-content-center pt-4">
            {loader ? (
              <Loader />
            ) : adminSubscriptionList?.length > 0 ? (
              adminSubscriptionList?.map((data, index) => {
                let prrr = Number(data.PricePerMonth);
                function centsToDollars(cents) {
                  return cents / 100;
                }
                const dollarsValue = centsToDollars(prrr);
                return (
                  <Col key={index} md={6} lg={3}>
                    <div
                      className={`pricing-plans ${
                        userDataFromDB?.CurrentPlan === data?.PlanName
                          ? "active"
                          : data?.PlanName === "Trial"
                          ? "trial_plan_class"
                          : null
                      }
                    `}
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="plan">
                        {userDataFromDB?.freeTrailAttempt == 1 ? (
                          data.PlanName != "Trial" ? (
                            <div
                              className="plan-inner"
                              style={{ minHeight: "250px" }}
                            >
                              <div className="entry-title">
                                <h3>{data?.PlanName}</h3>

                                <div className="price">
                                  {data.PlanName !== "Trial" && (
                                    <>
                                      {globalCurrencyFormat()?.currencyFormatToUSD(
                                        Number(dollarsValue)
                                      )}
                                      <span>/PER Month</span>
                                    </>
                                  )}
                                </div>
                                <div style={{ minHeight: "22px" }}>
                                  {data?.PlanName === "Trial"
                                    ? "After 7 days Trial Plan will be converted into Individual Plan"
                                    : ""}
                                </div>
                              </div>
                              <div className="entry-content">
                                <ul>
                                  {data?.EmailsAllowedPerMonth && (
                                    <li>
                                      <strong>
                                        {numberToCommaFormat(
                                          Number(data?.EmailsAllowedPerMonth)
                                        )}
                                      </strong>{" "}
                                      emails
                                    </li>
                                  )}
                                </ul>
                              </div>
                              <div className="btn w-100 text-center">
                                {userDataFromDB?.CurrentPlan ===
                                data?.PlanName ? (
                                  <></>
                                ) : userDataFromDB?.CurrentPlan === "Trial" ||
                                  userDataFromDB?.CurrentPlan === "" ? (
                                  data?.PlanName === "Trial" ? (
                                    <a
                                      onClick={() => {
                                        updatePlanHandler(data.SubscriptionID);
                                      }}
                                    >
                                      Select Plan
                                    </a>
                                  ) : (
                                    <a
                                      onClick={() => {
                                        // set the id on click of the join button
                                        setPlan(data.SubscriptionID);
                                        planHandler(
                                          data.SubscriptionID,
                                          "join"
                                        );
                                      }}
                                    >
                                      Select Plan
                                    </a>
                                  )
                                ) : userDataFromDB?.NextPlan === undefined &&
                                  data?.PlanName != "Trial" ? (
                                  <a
                                    onClick={() =>
                                      updatePlanHandler(data.SubscriptionID)
                                    }
                                  >
                                    Select Plan
                                  </a>
                                ) : userDataFromDB?.NextPlan ===
                                  data?.PlanName ? (
                                  <div>
                                    <a className="upc-button"> Upcoming</a>
                                    <div className="date-upcomming-plan">
                                      <b>Start from</b>:
                                      <span>
                                        {moment
                                          .unix(userDataFromDB?.NextPlanStart)
                                          .format("DD-MM-YYYY HH:mm")}
                                      </span>
                                    </div>
                                  </div>
                                ) : data?.PlanName === "Trial" ? null : (
                                  <a className="dis-button"> Update </a>
                                )}
                              </div>
                            </div>
                          ) : null
                        ) : (
                          <div
                            className="plan-inner"
                            style={{ minHeight: "250px" }}
                          >
                            <div className="entry-title">
                              <h3>{data?.PlanName}</h3>

                              <div className="price">
                                {data.PlanName !== "Trial" && (
                                  <>
                                    {globalCurrencyFormat()?.currencyFormatToUSD(
                                      Number(dollarsValue)
                                    )}
                                    <span>/PER Month</span>
                                  </>
                                )}
                              </div>
                              <div style={{ minHeight: "22px" }}>
                                {data?.PlanName === "Trial"
                                  ? "After 7 days Trial Plan will be converted into Individual Plan"
                                  : ""}
                              </div>
                            </div>
                            <div className="entry-content">
                              <ul>
                                {data?.EmailsAllowedPerMonth && (
                                  <li>
                                    <strong>
                                      {numberToCommaFormat(
                                        Number(data?.EmailsAllowedPerMonth)
                                      )}
                                    </strong>{" "}
                                    emails
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className="btn w-100 text-center">
                              {userDataFromDB?.CurrentPlan ===
                              data?.PlanName ? (
                                <></>
                              ) : userDataFromDB?.CurrentPlan === "Trial" ||
                                userDataFromDB?.CurrentPlan === "" ? (
                                data?.PlanName === "Trial" ? (
                                  <a
                                    onClick={() => {
                                      // set the id on click of the join button
                                      updatePlanHandler(data.SubscriptionID);
                                    }}
                                  >
                                    Select Plan
                                  </a>
                                ) : (
                                  <a
                                    onClick={() => {
                                      // set the id on click of the join button
                                      setPlan(data.SubscriptionID);
                                      planHandler(data.SubscriptionID, "join");
                                    }}
                                  >
                                    Select Plan
                                  </a>
                                )
                              ) : userDataFromDB?.NextPlan === undefined &&
                                data?.PlanName != "Trial" ? (
                                <a
                                  onClick={() =>
                                    updatePlanHandler(data.SubscriptionID)
                                  }
                                >
                                  Select Plan
                                </a>
                              ) : userDataFromDB?.NextPlan ===
                                data?.PlanName ? (
                                <div>
                                  <a className="upc-button"> Upcoming</a>
                                  <div className="date-upcomming-plan">
                                    <b>Start from</b>:
                                    <span>
                                      {moment
                                        .unix(userDataFromDB?.NextPlanStart)
                                        .format("DD-MM-YYYY HH:mm")}
                                    </span>
                                  </div>
                                </div>
                              ) : data?.PlanName === "Trial" ? null : (
                                <a className="dis-button"> Update </a>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              ""
            )}
          </Row>
        </div>
      </div>
    </>
  );
}

export default SaveUserSubscription;
