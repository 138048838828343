import { createSlice } from "@reduxjs/toolkit";
// import {
//   deleteAdminUser,
//   filterAdminUserbyDate,
//   getAdminUserList,
//   searchAdminUserList,
// } from "../../thunk/admin/adUser";
import { getUserDetail, getUsersList } from "../../thunk/user/usrUsers";

const initialState = {
  users: [],
  userDataFromDB: null,
  userAsperPagination: {},
};
const userListSlice = createSlice({
  name: "userListSlice",
  initialState: initialState,
  reducers: {
    modifiedUserDataFromDB(state, action) {
      let data = { ...state };
      data = {
        ...data,
        userDataFromDB: action.payload,
      };
      return data;
    },
  },
  extraReducers: (builder) => {
    //get detail of user from db

    builder
      .addCase(getUserDetail.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(getUserDetail.fulfilled, (state, action) => {
        const { payload } = action;
        state.userDataFromDB = payload?.data;
        state.status = true;
      })
      .addCase(getUserDetail.rejected, (state, action) => {
        state.status = false;
      });

    //get admin user list
    builder
      .addCase(getUsersList.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        const { payload } = action;
        let unsortedArr = payload?.body?.items;
        state.userAsperPagination = payload;
        let sortedArr = unsortedArr?.map((data) => ({
          ...data,
          EmailsAvailable: Number(data.EmailsAvailable),
          EmailsRequested: Number(data.EmailsRequested),
        }));
        state.users = sortedArr;
        state.userDataFromDB = payload?.body?.items[0];
        state.status = true;
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.status = false;
      });
  },
});
export const { modifiedUserDataFromDB } = userListSlice.actions;
export default userListSlice.reducer;
