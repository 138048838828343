import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userChatDetail: {
    SUBJECT: "",
    USERNAME: "",
    CONVERSATION_ID: 0,
    TIMESTAMP: "",
  },
  chatDetail: [],
};

const adminChatSlice = createSlice({
  name: "adminChatSlice",
  initialState: initialState,
  reducers: {
    setTheChatConverstationDetail(state, action) {
      let data = { ...state };
      data = { ...data, userChatDetail: action.payload };
      return data;
    },
    setTheChatArray(state, action) {
      let data = { ...state };
      data = { ...data, chatDetail: action.payload };
      return data;
    },
  },
});

export const { setTheChatConverstationDetail,setTheChatArray } = adminChatSlice.actions;
export default adminChatSlice.reducer;
