import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { trash } from "../../assets/icons/admin";
import {
  BtnGroup,
  Button,
  ConfirmPopUp,
  CustomModal,
} from "../../components/common";
import {
  addAdminSpeaker,
  deleteAdminSpeaker,
  getAdminSpeakers,
} from "../../redux/thunk/admin/adSpeakers";
import "../../styles/admin/categories.scss";
import {
  useConfirmation,
  useFetch,
  useModal,
  usePagination,
} from "../../utility/hooks";
import { dateFormater, itemsPerPage } from "../../utility/methods";

import { getSubscriptionListApi } from "../../redux/thunk/admin/Subscription";

function Subscriptions() {
  //Redux state
  const { adminAuthtoken } = useSelector((state) => state.adAuth);
  // const { adminSpeakers } = useSelector((state) => state.adSpeaker);

  const { adminSubscriptionList } = useSelector(
    (state) => state.adSubscription
  );

  // const { data, count } = adminSpeakers;

  //Redux action dispatcher
  const dispatch = useDispatch();

  const [plan, setPlan] = useState("");

  //Custom hooks
  const {
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    goToPage,
    setItemsPerPage,
    onSelectPage,
  } = usePagination({ totalItems: "count", itemsPerPage });

  const {
    show,
    handleClose,
    handleShow,
    shareShow,
    handleShareClose,
    handleShareShow,
  } = useModal();

  const {
    setId,
    showConfirm,
    handleConfirmShow,
    handleConfirmClose,
    onConfirmHandler,
  } = useConfirmation({
    action: deleteAdminSpeaker,
  });

  useFetch({ action: getAdminSpeakers, currentPage, itemsPerPage });

  //Formik initial state
  const initValues = {
    name: "",
    category: "",
  };

  //Yup validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name field is required"),
    category: Yup.string().required("Category field is required"),
  });

  //Methods
  const onSubmitHandler = (values) => {
    const data = {
      adminAuthtoken,
      values: {
        ...values,
      },
      pagination: {
        pageNo: 1,
        pageSize: 4,
      },
    };
    dispatch(addAdminSpeaker(data));
  };

  const onCancelHandler = (resetForm) => {
    resetForm();
  };

  const options = [
    {
      value: "",
      label: "Select option",
    },
    {
      value: "pro",
      label: "pro",
    },
    {
      value: "free",
      label: "free",
    },
  ];

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Speaker Name",
      selector: (row) => <div>{row.name}</div>,
    },
    {
      name: "Categories",
      selector: (row) => <div>{row.category}</div>,
    },
    {
      name: "Created On",
      selector: (row) => (
        <div className="date">
          <span>{dateFormater(row.createdAt)}</span>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <BtnGroup className="delete_action">
          <Button
            title={<Image src={trash} />}
            type="button"
            className="action_btn delete_btn "
            onClick={() => {
              handleConfirmShow();
              setId(row._id);
            }}
          />
          <ConfirmPopUp
            showConfirm={showConfirm}
            handleConfirmClose={handleConfirmClose}
            onConfirmHandler={onConfirmHandler}
          />
        </BtnGroup>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getSubscriptionListApi());
  }, []);

  return (
    <>
      <CustomModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        modalHead="Our modal"
        className="user_modal"
      >
        {/* <CheckoutForm planID={plan} onCancel={() => handleClose()} /> */}
        we will show something here....
      </CustomModal>

      <div className="categories_section">
        <h3 className="title">Pricing Plans </h3>
        <div>
          <Row>
            {adminSubscriptionList?.length > 0 ? (
              adminSubscriptionList?.map((data, index) => {
                return (
                  <Col md={4} mb={5}>
                    <div className="pricing-plans">
                      <div className="plan">
                        <div className="plan-inner">
                          <div className="entry-title">
                            <h3>{data.PlanName}</h3>
                            <div className="price">
                              ${data.PricePerMonth}
                              <span>/PER Month</span>
                            </div>
                          </div>
                          <div className="entry-content">
                            <ul>
                              <li>
                                <strong>1x</strong> option 1
                              </li>
                              <li>
                                <strong>2x</strong> option 2
                              </li>
                              <li>
                                <strong>3x</strong> option 3
                              </li>
                              {/* <li><strong>Free</strong> option 4</li> */}
                              <li>
                                {data.EmailsAllowedPerMonth} emails per month
                              </li>
                            </ul>
                          </div>
                          <div className="btn w-100 text-center">
                            <a onClick={handleShow}>Join Now</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div>Record Not found</div>
            )}

            {/* <Col md={4}>
              <div className="pricing-plans">
                <div className="plan">
                  <div className="plan-inner">
                    <div className="entry-title">
                      <h3>Standard</h3>
                      <div className="price">$50<span>/PER Month</span>
                      </div>
                    </div>
                    <div className="entry-content">
                      <ul>
                        <li><strong>1x</strong> option 1</li>
                        <li><strong>2x</strong> option 2</li>
                        <li><strong>3x</strong> option 3</li>
                        <li><strong>Free</strong> option 4</li>
                        <li><strong>Unlimited</strong> option 5</li>
                      </ul>
                    </div>
                    <div className="btn w-100 text-center">
                      <a onClick={handleShow}>Join Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="pricing-plans">
                <div className="plan">
                  <div className="plan-inner">
                    <div className="entry-title">
                      <h3>Pro</h3>
                      <div className="price">$99<span>/PER Month</span>
                      </div>
                    </div>
                    <div className="entry-content">
                      <ul>
                        <li><strong>1x</strong> option 1</li>
                        <li><strong>2x</strong> option 2</li>
                        <li><strong>3x</strong> option 3</li>
                        <li><strong>Free</strong> option 4</li>
                        <li><strong>Unlimited</strong> option 5</li>
                      </ul>
                    </div>
                    <div className="btn w-100 text-center">
                      <a onClick={handleShow}>Join Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="pricing-plans">
                <div className="plan">
                  <div className="plan-inner">
                    <div className="entry-title">
                      <h3>Plus</h3>
                      <div className="price">$200<span>/PER Month</span>
                      </div>
                    </div>
                    <div className="entry-content">
                      <ul>
                        <li><strong>1x</strong> option 1</li>
                        <li><strong>2x</strong> option 2</li>
                        <li><strong>3x</strong> option 3</li>
                        <li><strong>Free</strong> option 4</li>
                        <li><strong>Unlimited</strong> option 5</li>
                      </ul>
                    </div>
                    <div className="btn w-100 text-center">
                      <a onClick={handleShow}>Join Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
    </>
  );
}

export default Subscriptions;
