import { createSlice } from "@reduxjs/toolkit";
import { getProgramCategoriesList } from "../../thunk/user/usrCategories";
import { getProgramSpeakersList } from "../../thunk/user/usrSpeakers";

import {
  getLeadsAPI,
  getAccountsByCategory,
} from "../../thunk/user/usrCategories";
import { toast } from "react-toastify";
import { getUserDetail } from "../../thunk/user/usrUsers";

const initialState = {
  programsCategories: {
    data: [],
  },
  programsSpeakers: {
    data: [],
  },
  programLeads: {
    data: [],
    msg: "",
  },
  accountsByCategory: {
    data: [],
  },
};

const programSpeakerCategoriesListSlice = createSlice({
  name: "programSpeakerCategoriesListSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get user program list
    builder
      .addCase(getProgramCategoriesList.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(getProgramCategoriesList.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload.errorMessage) {
          toast.error(`Failed to fetch Categories`);
        } else {
          state.programsCategories.data = payload?.body?.data;
          state.status = true;
        }
      })
      .addCase(getProgramCategoriesList.rejected, (state, action) => {
        state.status = false;
      });

    builder
      .addCase(getLeadsAPI.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(getLeadsAPI.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload.data === undefined) {
          toast.error(payload.message);
          state.programLeads.msg = payload.message;
          state.programLeads.data = [];
        } else {
          state.programLeads.data = payload.data.map((item) => [item]);
          toast.success(payload.message);
          state.programLeads.msg = "";
        }
        state.status = true;
      })
      .addCase(getLeadsAPI.rejected, (state, action) => {
        state.status = false;
      });

    builder
      .addCase(getProgramSpeakersList.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(getProgramSpeakersList.fulfilled, (state, action) => {
        const { payload } = action;
        state.programsSpeakers = payload;
        state.status = true;
      })
      .addCase(getProgramSpeakersList.rejected, (state, action) => {
        state.status = false;
      });

    builder
      .addCase(getAccountsByCategory.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(getAccountsByCategory.fulfilled, (state, action) => {
        const { payload } = action;

        // getUserDetail

        if (payload.success === false) {
          toast.error(payload.message);
          state.accountsByCategory.data = [];
          state.status = false;
        } else {
          toast.success(payload.message);
          let dd = payload?.data?.map((item) => [item]);
          state.accountsByCategory.data = dd;
          state.status = true;
        }
      })
      .addCase(getAccountsByCategory.rejected, (state, action) => {
        state.status = false;
      });
  },
});

// export const {} = userProgramsSlice.actions;
export default programSpeakerCategoriesListSlice.reducer;
