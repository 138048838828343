import { Auth } from "aws-amplify";
export const checkUserStatus = async () => {
  try {
    const currentUser = await Auth.currentUserInfo(); // get the detail of the user
    // Check if user is disabled or any other condition you need
    if (Object.keys(currentUser).length === 0) {
      await Auth.signOut(); // Log out the user session
      return true;
    } else {
      return { status: false, response: currentUser };
    }
  } catch (error) {
    if (error) {
      console.error("Error checking user status:", error);
      return true;
    }
  }
};
