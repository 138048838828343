import React, { useEffect } from "react";
import SupportModal from "./SupportModal";
import { useSelector } from "react-redux";
import SupportChat from "./SupportChat";
import { useRefreshToken } from "../../../utility/hooks";

function SupportChatIndex() {
  const {supportChatModalMessage} = useSelector(
    (state) => state.userSupportChat
  );
    // to refresh the token
    const { refreshUserToken } = useRefreshToken();
    useEffect(()=>{
      refreshUserToken();// refresh the jwt token on based of refresh token
    },[])
  
  
  return (
    // <>{supportChatModalMessage === "" ? <SupportModal /> : <SupportChat/>}</>
    <SupportChat/>
  );
}

export default SupportChatIndex;
