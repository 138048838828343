import { createAsyncThunk } from "@reduxjs/toolkit";
import httpsClient from "../../../services/httpsClient";
import { adminApi, userApi } from "../../../services/apiEndpoints";
import {
  hideBtnLoader,
  hideLoader,
  showBtnLoader,
  showLoader,
} from "../../reducers/common/appSlice";

const { adUserList, adUserDetail, adDelUser, adSearchUser, adFilterUser } =
  adminApi;

export const getUsersList = createAsyncThunk(
  "user/getUserList",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const { accessToken, page_no, page_size } = data;
    try {
      const config = {
        method: "post",
        url: `https://gu9w5obxoa.execute-api.us-east-2.amazonaws.com/dev`,
        data: {
          page_no: page_no,
          page_size: page_size,
        },
      };
      dispatch(showLoader());
      const response = await httpsClient(config, accessToken);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "user/getUserDetail",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const config = {
        method: "post",
        url: "https://d47icdn2ad.execute-api.us-east-2.amazonaws.com/dev/getUserDetail",
        data: data,
      };
      dispatch(showLoader());
      const response = await httpsClient(config);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUserHistory = createAsyncThunk(
  "user/userHistory",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { userAuthtoken, ...body } = data;

    try {
      const config = {
        method: "post",
        url: "https://a860uzlsh3.execute-api.us-east-2.amazonaws.com/dev",
        data: body,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, userAuthtoken);
      setTimeout(() => {
        dispatch(hideLoader());
      }, 2000);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getUserHistoryById = createAsyncThunk(
  "user/getUserHistoryById",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { userAuthtoken, values } = data;

    try {
      const config = {
        method: "post",
        url: "https://3789u665pc.execute-api.us-east-2.amazonaws.com/dev",
        data: values,
      };
      // dispatch(showLoader());
      const response = await httpsClient(config, userAuthtoken);
      // dispatch(hideLoader())
      return response;
    } catch (err) {
      console.log(err);
      // dispatch(hideLoader());
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

// // get admin user detail thunk
// export const getAdminUserDetail = createAsyncThunk(
//   "admin/getAdminUserDetail",
//   async (data, thunkAPI) => {
//     const { dispatch } = thunkAPI;
//     const { adminAuthtoken } = data;
//     try {
//       const config = {
//         method: "get",
//         url: `${adUserDetail}/id`,
//       };
//       dispatch(showLoader());
//       const response = await httpsClient(config, adminAuthtoken);
//       dispatch(hideLoader());
//       return response;
//     } catch (error) {
//       dispatch(hideLoader());
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

// // delete admin user detail thunk
// export const deleteAdminUser = createAsyncThunk(
//   "admin/deleteAdminUser",
//   async (data, thunkAPI) => {
//     const { dispatch } = thunkAPI;
//     const { adminAuthtoken, values } = data;
//     try {
//       const config = {
//         method: "delete",
//         url: `${adDelUser}/${values.id}`,
//       };
//       dispatch(showBtnLoader());
//       const response = await httpsClient(config, adminAuthtoken);
//       dispatch(hideBtnLoader());
//       return response;
//     } catch (error) {
//       dispatch(hideBtnLoader());
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

// // search admin users list thunk
// export const searchAdminUserList = createAsyncThunk(
//   "admin/searchAdminUserList",
//   async (data, thunkAPI) => {
//     const { dispatch } = thunkAPI;
//     const {
//       adminAuthtoken,
//       query: { search },
//     } = data;
//     try {
//       const config = {
//         method: "get",
//         url: `${adSearchUser}?query=${search}`,
//       };
//       dispatch(showLoader());
//       const response = await httpsClient(config, adminAuthtoken);
//       dispatch(hideLoader());
//       return response;
//     } catch (error) {
//       dispatch(hideLoader());
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

// // filter admin users list thunk
// export const filterAdminUserbyDate = createAsyncThunk(
//   "admin/filterAdminUserbyDate",
//   async (data, thunkAPI) => {
//     const { dispatch } = thunkAPI;
//     const {
//       adminAuthtoken,
//       query: { startDate, endDate },
//     } = data;
//     try {
//       const config = {
//         method: "get",
//         url: `${adFilterUser}?startDate=${startDate}&endDate=${endDate}`,
//       };
//       dispatch(showLoader());
//       const response = await httpsClient(config, adminAuthtoken);
//       dispatch(hideLoader());
//       return response;
//     } catch (error) {
//       dispatch(hideLoader());
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );
