import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdminUserDetail } from "../../redux/thunk/admin/adUser";
import { Loader } from "../../components/common";
const schema = yup.object().shape({
  username: yup.string().required("User Name is a required field"),
  email: yup.string().required("Email is a required field"),
  leads: yup.string().required("No. of leads is a required field"),
});
function EditUserProfile() {
  const dispatch = useDispatch();
  const adminUsername = useSelector((state) => state.adAuth.adminData.username);
  const { adminUserDetail } = useSelector((state) => state.adUserList);
  const { adminData } = useSelector((state) => state?.adAuth);
  let data = { accessToken: adminData?.signInUserSession?.idToken?.jwtToken };
  const { loader } = useSelector((state) => state.app);

  const { id } = useParams();
  const handleOnSubmit = (values) => {
    const formData = {
      username: values.username,
      email: values.email,
      leads: Number(values.leads),
    };

    // const data = {
    //   adminAuthtoken,
    //   values: {
    //     ...formData,
    //   }
    // };
    // dispatch(addSubscriptionPlan(data));
  };
  useEffect(() => {
    dispatch(getAdminUserDetail({ ...data, id: id }));
  }, [id]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: adminUserDetail?.data[0]?.Username,
      email: adminUserDetail?.data[0]?.Email,
      leads: adminUserDetail?.data[0]?.EmailsAvailable,
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });
  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="card-sec bg-white p-3">
          <h3>Edit User Profile</h3>
          <div className="form-section mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>User name</label>
                  <input
                    type="text"
                    placeholder=" Enter user name"
                    value={formik.values.username}
                    onChange={(e) => setInputValue("username", e.target.value)}
                  />
                  <small className="error-text">
                    {formik.touched.username && formik.errors.username}
                  </small>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter email"
                    value={formik.values.email}
                    onChange={(e) => setInputValue("email", e.target.value)}
                  />
                  <small className="error-text">
                    {formik.touched.email && formik.errors.email}
                  </small>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>No. of leads</label>
                  <input
                    type="number"
                    placeholder="Enter no. of leads"
                    value={formik.values.leads}
                    onChange={(e) => setInputValue("leads", e.target.value)}
                  />
                  <small className="error-text">
                    {formik.touched.leads && formik.errors.leads}
                  </small>
                </div>
              </div>
              {!!formik.errors.planPrice && <br />}
              <div className="common_btns">
                <button
                  title="Download"
                  type="submit"
                  // disabled={!formik.isValid}
                  className="primary_btn"
                >
                  Update profile
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
export default EditUserProfile;
