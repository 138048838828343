import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import { ROUTES } from "../../navigation/constants";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useSelector } from "react-redux";

const AdPubLayout = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { adminData } = useSelector((state) => state.adAuth);
  let adminSession = adminData?.signInUserSession;
  const navigate = useNavigate();
  const { adDashboard, adAdminTable, adUserManagement } = ROUTES;
  useEffect(() => {
    if (adminSession) {
      // navigate(adDashboard);
      navigate(adUserManagement);
    }
  }, [adminSession]);
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AdPubLayout;
