export const ROUTES = {
  adLogin: "/admin",
  adDashboard: "/admin/dashboard",
  adUserDetail: "/admin/user",
  adUserList: "/admin/get-leads",
  adEditUserProfile: "/admin/edit-user-profile/:id",
  adEditAdminProfile: "/admin/edit-admin-profile/:id",
  adAddprogram: "/admin/add-program",
  adSubscriptions: "/admin/subscriptions",
  adUserManagement: "/admin/user-management",
  adAdminTable: "/admin/admin-table",
  adHistory: "/admin/history",
  adFaq: "/admin/faq",
  adbillingHistory: "/admin/billinghistory/:id",
  adChat: "/admin/chat",
  adSubscriptionManagement: "/admin/subscriptions-management",
  adAddPricing: "/admin/add-pricing",
  adPrivacy: "/admin/privacy-policy",
  adTermConditions: "/admin/terms-and-conditions",
  adSupport: "/admin/support",
  adAddFaq: "/admin/add-faq",
  adUpdateProgram: "/admin/update-program",
  editPricingPlan: "/admin/edit-pricing-plan",
  createSubAdmin: "/admin/create-admin",
  getLeadReq: "/admin/get-lead-request",
  usrLead: "/user/lead",
  userLeadByInfluncer: "/user/influencer",
  cardInformation: "/card/information",
  usrLogin: "/",
  usrDashboard: "/user/dashboard",
  usrCategories: "/user/subscriptions",
  usrEditProfile: "/user/edit-profile",
  usrHistory: "/user/history",
  usrSupportChat: "/user/supoort-chat",
  saveCardDetails: "/user/save-card",
  saveSubscriptionDetails: "/user/save-subscriptions",
  userPaymentHistory: "/user/payment/history",
};
