import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigation/constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../redux/thunk/user/usrUsers";
import { useLocation } from "react-router-dom";
import { userDetailsCustomdata } from "../../redux/reducers/userSlices/userAuth";

const UserPubLayout = () => {
  const { userData } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { saveSubscriptionDetails, userLeadByInfluncer } = ROUTES;

  const userSession = userData?.signInUserSession;

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userSession) {
        try {
          // Fetch user details
          const res = await dispatch(
            getUserDetail({ sub: userData?.username })
          );
          const userDetails = res.payload?.data;
          dispatch(userDetailsCustomdata(userDetails));

          // Check and handle redirection
          if (userDetails?.CurrentPlan === "") {
            if (location.pathname !== "/user/save-card") {
              navigate(saveSubscriptionDetails, { replace: true });
            }
          } else {
            if (location.pathname !== "/user/save-card") {
              navigate(userLeadByInfluncer, { replace: true });
            }
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchUserDetails();
  }, [
    dispatch,
    navigate,
    userSession,
    userData?.username,
    location.pathname,
    saveSubscriptionDetails,
    userLeadByInfluncer,
  ]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default UserPubLayout;
