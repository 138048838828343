import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Header, SideBar } from "../../components/user";
import { ROUTES } from "../../navigation/constants";
import { userLogout } from "../../redux/reducers/userSlices/userAuth";
import { checkUserStatus } from "../../services/auth/userEnableDisableCheck";
import "../../styles/user/global.scss";
import { useRefreshToken } from "../../utility/hooks";

function UsrPvtLayout() {
  const [show, setShow] = useState(false);
  const userDataa = useSelector((state) => state.userAuth.userData);
  let userSession = userDataa?.signInUserSession;
  const { usrLogin } = ROUTES;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuthenticator((context) => [context?.user]);
  // to refresh the token
  const { refreshUserToken } = useRefreshToken();

  useEffect(() => {
    refreshUserToken(); // refresh the jwt token on based of refresh token
    userEnableDisableCheck();
  }, [location.pathname]);

  // to check on every route that user is enable from the aws cognito pool
  const userEnableDisableCheck = async () => {
    const userCheck = await checkUserStatus();
    if (userCheck === true) {
      dispatch(userLogout()); // logout the session detail from the redux store
      return navigate(usrLogin);
    }
  };

  if (userSession) {
    return (
      <div className="main_wrapper">
        <SideBar show={show} setShow={setShow} />
        <div className="right_section">
          <Header show={show} setShow={setShow} />
          <div className="admin-content-section">
            <Outlet />
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to={usrLogin} />;
  }
}

export default UsrPvtLayout;
