import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userData: null,
  userCustomRecord:null
};

const userAuthSlice = createSlice({
  name: "userAuthSlice",
  initialState: initialState,
  reducers: {
    userData: (state, action) => {
      state.userData = action.payload;
    },
    userLogout: (state, action) => {
      
    },
    userDetailsCustomdata:(state, action)=>{
      state.userDetailsCustomdata= action.payload
    }
  },
});

export const { userLogout,  userDetailsCustomdata,userData } = userAuthSlice.actions;
export default userAuthSlice.reducer;
