import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import logoImage from "../../../assets/images/admin/logo.png";
import { getUserCardService } from "../../../services/user/saveCardDetails/saveCardDetails";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTES } from "../../../navigation/constants";
import { Spinner } from "react-bootstrap";

// Replace with your own public key
const stripePromise = loadStripe(
  "pk_test_51PIOmESE07yEP428MtDW5KVCllaKOzuHgMHfIp5EFsz0DIY8ZLn8jJmPg7zlHqZavDGuTcovLU9zcGblrl8Kw2EW007xlPXVNY"
);

const UserSaveCard = () => {
  const pataname = useLocation();
  const [btnLoader, setBtnLoader] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const userAuthtoken = useSelector(
    (state) => state.userAuth.userData.signInUserSession.idToken.jwtToken
  );
  const userDataFromDB = useSelector(
    (state) => state?.userAuth?.userData?.username
  );
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    if (btnLoader) {
      // If btnLoader is true, don't proceed with another submission
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // Validate all elements are present
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      setError("Card details elements are not initialized.");
      return;
    }

    const { token, error } = await stripe.createToken(cardNumberElement);

    if (error) {
      setError(error.message);
      setSuccess(null);
    } else {
      setError(null);
      // Send the token to your backend
      try {
        setBtnLoader(true);
        const onSuccess = (res) => {
          if (res?.data?.statusCode === 200) {
            toast.success("Your Free Trial has been Activated.");
            //   setTimeout(() => {
            navigate(ROUTES.userLeadByInfluncer);
            setBtnLoader(false);
            // }, 2000);
          } else {
            const message = res?.data?.message || "An unknown error occurred";
            toast.error(message);
            setBtnLoader(false);
          }
        };
        const onError = (err) => {
          console.log("errr", err);
        };
        const body = {
          UserID: userDataFromDB,
          priceId: pataname?.state,
          token: token.id,
        };

        getUserCardService.saveCardDetails(
          userAuthtoken,
          body,
          onSuccess,
          onError,
          {}
        );
      } catch (error) {
        setError("An error occurred while saving the card.");
      }
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const customButtonLoader = (
    <Spinner animation="border" role="status">
      <span className="sr-only"></span>
    </Spinner>
  );

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div className="text-center border-bottom mb-2">
          <img
            src={logoImage}
            alt="logo"
            style={{ width: "130px", height: "", marginBottom: "15px" }}
          />
        </div>
        <h4
          className="py-3"
          style={{
            color: "rgb(39, 128, 205)",
            textAlign: "center",
            fontSize: "30px",
          }}
        >
          Add Card Details
        </h4>

        <div style={styles.cardSection}>
          <label>Card Number</label>
          <CardNumberElement
            className="border p-2"
            options={cardElementOptions}
          />
        </div>
        <div style={styles.cardSection}>
          <label>Expiry Date</label>
          <CardExpiryElement
            className="border p-2"
            options={cardElementOptions}
          />
        </div>
        <div style={styles.cardSection}>
          <label>CVC</label>
          <CardCvcElement className="border p-2" options={cardElementOptions} />
        </div>

        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        )}
        {success && <div style={{ color: "green" }}>{success}</div>}

        <div className="text-center customParentLoader">
          <button type="submit" disabled={!stripe} style={styles.button}>
            {btnLoader ? customButtonLoader : "Save Card"}
          </button>
        </div>
      </form>
    </div>
  );
};

// Styles to center the form and style it
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "rgb(39, 128, 205)",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "500px",
    padding: "30px",
    background: "white",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  cardSection: {
    marginBottom: "15px",
  },
  button: {
    marginTop: "20px",
    padding: "8px",
    backgroundColor: "#2780cd",
    color: "white",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    width: "100%",
    display: "block",
  },
};

const App = () => (
  <Elements stripe={stripePromise}>
    <UserSaveCard />
  </Elements>
);

export default App;
