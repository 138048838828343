import React, { useEffect, useState } from "react";
import "./adminChat.css";
import { IoIosSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { adminChatServices } from "../../../services/admin/adminChat/adminChatServices";
import { toast } from "react-toastify";
import { useRef } from "react";
import { setTheChatArray } from "../../../redux/reducers/adminSlices/adminChat";

export default function AdminChatScreen() {
  const { userChatDetail, chatDetail } = useSelector(
    (state) => state.adminChat
  );
  const adminAuthtoken = useSelector(
    (state) => state.adAuth.adminData.signInUserSession.idToken.jwtToken
  );
  const dispatch = useDispatch();
  const [messageData, setMessageData] = useState([]);
  const [message, setMessage] = useState("");
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageData]);
  // intial or window reload that useEffect is intialy called
  useEffect(() => {
    // setMessageData(chatDetail)
    getChat();
  }, [userChatDetail?.CONVERSATION_ID]);

  // this useeffect is called after the 15 second
  useEffect(() => {
    if (message === "") {
      const intervalId = setInterval(() => {
        getChat();
        if (ref.current) {
          ref.current?.scrollIntoView({ behavior: "smooth" });
        }
      }, 15000);
      return () => clearInterval(intervalId);
    }
  }, [userChatDetail?.CONVERSATION_ID, message]);

  // get the chat from the backend as per the particular user
  const getChat = () => {
    const body = { ChatID: userChatDetail?.CONVERSATION_ID, Admin: true };
    const onSuccess = (res) => {
      if (messageData.length === 0) {
        setMessageData(res?.data?.data);
        dispatch(setTheChatArray(res?.data?.data));
      } else {
        const data = res?.data?.data?.slice(
          messageData.length - res?.data?.data[res?.data?.data?.length - 1]
        );
        if (messageData.length < res?.data?.data?.length) {
          setMessageData((prev) => [...prev, ...data]);
        }
      }
    };
    const onError = (err) => {
      console.log("---errr", err);
    };
    adminChatServices.getAdminMessage(
      adminAuthtoken,
      body,
      onSuccess,
      onError,
      {}
    );
  };
  // onclick send message
  const sendMessage = (e) => {
    e.preventDefault();
    if (message != "") {
      //manipulate the data as per the backend json
      setMessageData((prev) => [
        ...prev,
        {
          ADMIN: true,
          ID: "",
          MESSAGE: message,
          READ_: false,
          TIMESTAMP_: new Date(),
          USERNAME: "",
        },
      ]);
      sendMessageApi();
    }
  };

  // send message api initragation
  // have the body and onsuccess and onerror callback function
  const sendMessageApi = () => {
    const body = {
      ChatID: userChatDetail?.CONVERSATION_ID,
      Msg: message,
      Username: userChatDetail?.USERNAME,
    };
    const onSuccess = (res) => {
      if (res.data.success) {
        getChat();
      }
    };
    const onError = (err) => {
      console.log("err", err);
    };
    adminChatServices.adminSendMessage(
      adminAuthtoken,
      body,
      onSuccess,
      onError,
      {}
    );
    setMessage("");
  };
  return (
    <div className="chat">
      <div className="chat-header clearfix">
        <div className="row">
          <div className="col-lg-12 d-flex align-items-center">
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#view_info"
            >
              <img
                src="https://i0.wp.com/digitalhealthskills.com/wp-content/uploads/2022/11/3da39-no-user-image-icon-27.png?fit=500%2C500&ssl=1"
                alt="avatar"
              />
            </a>
            <div className="chat-about">
              <h6 className="mb-0">{userChatDetail?.USERNAME}</h6>
              {/* <small>Last seen: 2 hours ago</small> */}
            </div>
          </div>
        </div>
      </div>
      <div className="chat-history">
        <ul className="m-b-0">
          {messageData &&
            messageData?.map((messageItem) => (
              <li
                className="clearfix"
                ref={messageData?.length - 1 ? ref : null}
              >
                <div
                  className={
                    messageItem.ADMIN === true
                      ? "message other-message float-right"
                      : "message other-message"
                  }
                >
                  {messageItem.MESSAGE}
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="chat-message clearfix">
        <form onSubmit={sendMessage}>
          <div className="input-group mb-0">
            <input
              type="text"
              className="form-control"
              placeholder="Enter text here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="input-group-prepend">
              <span className="input-group-text send_msg_btn">
                <button type="submit">
                  <IoIosSend style={{ cursor: "pointer" }} size={25} />
                </button>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
