import { useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import { Pagination } from "../admin";
import SearchBar from "./SearchBar";
import DateFilter from "./DateFilter";
import "../../styles/common/dataTable.scss";
import Button from "./Button";
import { Dropdown } from "react-bootstrap";

function ReactDataTable({
  setItemsPerPage,
  total_records,
  isHaveNextPage,
  itemsPerPage,
  setPaginationItem,
  paginationItem,
  page,
  columns,
  data,
  onFilter,
  pagination,
  showDateFilter,
  searchBar,
  header,
  subHeader,
  onSearch,
  search,
  onSearchChange,
  onDateChange,
  clearFilter,
  addButton,
  paginationFields,
  onChangeRowsPerPage,
  onChangePage,
  handlePageChange,
  handlePerRowsChange,
}) {
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsText: "All",
  };
  // @@ pagination next page function with the state changes to + 1
  const onNextPage = () => {
    setPaginationItem((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  // @@ pagination prev page function with the state changes to - 1
  const onPrevPage = () => {
    setPaginationItem((prev) => ({ ...prev, page: prev.page - 1 }));
  };

  return (
    <DataTable
      responsive
      columns={columns}
      data={data}
      pagination
      paginationPerPage={15}
      paginationRowsPerPageOptions={[10, 50, 100]} // Options for rows per page dropdown
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationComponent={() => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginTop: 100,
            }}
            className="responsive_pazination"
          >
            <div />

            <div
              className="common_btns"
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <Dropdown
                className="d-inline  mx-2 custom_dropdown"
                autoClose="inside"
                onSelect={(key) => {
                  setPaginationItem((prev) => ({
                    ...prev,
                    itemsPerPage: Number(key),
                  }));
                }}
              >
                <Dropdown.Toggle
                  size="sm"
                  className="px-2 w-auto"
                  id="dropdown-autoclose-inside"
                >
                  {paginationItem.itemsPerPage}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                  <Dropdown.Item eventKey={25}>25</Dropdown.Item>
                  <Dropdown.Item eventKey={50}>50</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                disable={paginationItem?.page === 1}
                title={"Prev"}
                className={"primary_btn px-3 w-auto"}
                onClick={onPrevPage}
              />
              <sapn>
                {paginationItem?.page} -{" "}
                {total_records < paginationItem?.itemsPerPage
                  ? 1
                  : Math?.round(
                      total_records / paginationItem?.itemsPerPage
                    ) === 0
                  ? Math?.round(total_records / paginationItem?.itemsPerPage)
                  : Math?.round(total_records / paginationItem?.itemsPerPage) +
                    1}
                {/* 1 - {paginationItem?.page} of {paginationItem?.itemsPerPage} */}
              </sapn>
              <Button
                className={"primary_btn px-3 w-auto"}
                title={"Next"}
                disable={isHaveNextPage ? false : true}
                onClick={onNextPage}
              />
            </div>
          </div>
        </>
      )}
    />
  );
}

export default ReactDataTable;
