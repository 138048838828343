import React from "react";
import { Button, Modal } from "react-bootstrap";

import {
  globalCurrencyFormat,
  numberToCommaFormat,
} from "../../../shared/helperFunction";

const ConfirmationModal = ({
  show,
  hide,
  title,
  confirm,
  message,
  subscriptionData,
  planeHandler,
}) => {
  return (
    <Modal show={show} onHide={hide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {title === "checkouturlMessage"
            ? "Update Card"
            : "Free Trial Limit Reached"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
        {title === "Free Trial Limit Reached" && (
          <div className="subcriptionData mt-3 d-flex">
            {subscriptionData &&
              subscriptionData.length > 0 &&
              subscriptionData.slice(1, 3).map((curElm, index) => {
                let prrr = Number(curElm.PricePerMonth);
                function centsToDollars(cents) {
                  return cents / 100;
                }
                const dollarsValue = centsToDollars(prrr);
                return (
                  <div
                    key={index}
                    className="w-50 p-3 me-4"
                    style={{
                      backgroundColor: "#d3d3d34f",
                      padding: "20px",
                      borderRadius: "20px",
                    }}
                  >
                    <h5 className="text-center border-bottom pb-3 border-gray text-uppercase">
                      {curElm.PlanName}{" "}
                    </h5>
                    <div className="price text-center">
                      {globalCurrencyFormat().currencyFormatToUSD(
                        Number(dollarsValue)
                      )}
                      <span>/PER Month</span>
                    </div>
                    <div className="text-center mt-4">
                      <strong>
                        {numberToCommaFormat(
                          Number(curElm.EmailsAllowedPerMonth) + " emails"
                        )}
                      </strong>
                    </div>
                    <div className="text-center mt-3">
                      {" "}
                      <button
                        style={{
                          background: "#2680cd",
                          padding: "7px 14px",
                          color: "#fff",
                          textTransform: "uppercase",
                          fontWeight: "500",
                          borderRadius: "5px",
                          fontSize: "14px",
                          border: "none",
                        }}
                        onClick={() => {
                          planeHandler(
                            curElm.SubscriptionID,
                            "Select",
                            curElm.PlanName
                          );
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </Modal.Body>
      {title === "checkouturlMessage" && (
        <Modal.Footer>
          <Button variant="danger" onClick={hide}>
            No
          </Button>
          <Button variant="primary" onClick={confirm}>
            Yes
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
