import { POSTAPI } from "../../globalServicesMethod";

const saveCardDetails = (token, body, onSuccess, onError, config) => {
  POSTAPI(
    token,
    "https://ukiqb1kg96.execute-api.us-east-2.amazonaws.com/dev/",
    body,
    onSuccess,
    onError,
    config
  );
};

const updateCheckCardUrl = (token, body, onSuccess, onError, config) => {
  POSTAPI(
    token,
    "https://mfq309kx8i.execute-api.us-east-2.amazonaws.com/dev/",
    body,
    onSuccess,
    onError,
    config
  );
};

export const getUserCardService = {
  saveCardDetails,
  updateCheckCardUrl,
};
