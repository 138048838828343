import { createAsyncThunk } from "@reduxjs/toolkit";
import httpsClient from "../../../services/httpsClient";
import { adminApi } from "../../../services/apiEndpoints";
import {
  hideBtnLoader,
  hideLoader,
  showBtnLoader,
  showLoader,
} from "../../reducers/common/appSlice";

const { adUserList, adUserDetail, adDelUser, adSearchUser, adFilterUser } =
  adminApi;

export const getAdminUserList = createAsyncThunk(
  "admin/getAdminUserList",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { authToken } = data;

    try {
      const config = {
        method: "post",
        url: "https://0dedfahpb6.execute-api.us-east-2.amazonaws.com/dev/",
        data: data,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, authToken);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// get admin user detail thunk
export const getAdminUserDetail = createAsyncThunk(
  "admin/getAdminUserDetail",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { accessToken, id } = data;
    try {
      const config = {
        method: "POST",
        url: `https://d47icdn2ad.execute-api.us-east-2.amazonaws.com/dev/getUserByID`,
        data: { User_ID: id },
      };
      dispatch(showLoader());
      const response = await httpsClient(config, accessToken);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// delete admin user detail thunk
export const deleteUser = createAsyncThunk(
  "admin/deleteUser",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { accessToken, UserID } = data;

    try {
      const config = {
        method: "delete",
        url: `https://d47icdn2ad.execute-api.us-east-2.amazonaws.com/dev`,
        data: { UserID: UserID },
      };
      dispatch(showBtnLoader());
      const response = await httpsClient(config, accessToken);
      dispatch(hideBtnLoader());
      return response;
    } catch (error) {
      dispatch(hideBtnLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// search admin users list thunk
export const searchAdminUserList = createAsyncThunk(
  "admin/searchAdminUserList",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      adminAuthtoken,
      query: { search },
    } = data;
    try {
      const config = {
        method: "get",
        url: `${adSearchUser}?query=${search}`,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, adminAuthtoken);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// filter admin users list thunk
export const filterAdminUserbyDate = createAsyncThunk(
  "admin/filterAdminUserbyDate",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      adminAuthtoken,
      query: { startDate, endDate },
    } = data;
    try {
      const config = {
        method: "get",
        url: `${adFilterUser}?startDate=${startDate}&endDate=${endDate}`,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, adminAuthtoken);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
