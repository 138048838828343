import { createAsyncThunk } from "@reduxjs/toolkit";
import httpsClient from "../../../services/httpsClient";
import { userApi } from "../../../services/apiEndpoints";
import { hideLoader, showLoader } from "../../reducers/common/appSlice";

const { usrCategories, getLeads } = userApi;

// get USER categories list thunk
export const getProgramCategoriesList = createAsyncThunk(
  "user/getProgramCategoriesList",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const config = {
        method: "get",
        url: `${usrCategories}`,
      };
      dispatch(showLoader());
      const response = await httpsClient(config);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getLeadsAPI = createAsyncThunk(
  "user/getLeadsApi",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const { userAuthtoken, values } = data;
    try {
      const config = {
        method: "post",
        url: getLeads,
        data: values,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, userAuthtoken);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAccountsByCategory = createAsyncThunk(
  "user/getAccountsByCategory",
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { userAuthtoken, values } = data;
    try {
      const config = {
        method: "post",
        // url: "https://qqtmnawu79.execute-api.us-east-2.amazonaws.com/dev",
        url: "https://qqtmnawu79.execute-api.us-east-2.amazonaws.com/dev",
        data: values,
      };
      dispatch(showLoader());
      const response = await httpsClient(config, userAuthtoken);
      dispatch(hideLoader());
      return response;
    } catch (error) {
      dispatch(hideLoader());
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
