import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth, Amplify } from "aws-amplify";
import { awsExports } from "../aws-exports";
import { userData } from "../../redux/reducers/userSlices/userAuth";
import { adminData } from "../../redux/reducers/adminSlices/adAuth";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const userDataa = useSelector((state) => state.userAuth.userData);
  const adminDataa = useSelector((state) => state.adAuth.adminData);

  const refreshUserToken = async () => {
    Amplify.configure({
      Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.NEW_USER_POOL_FOR_EMAIL,
        userPoolWebClientId: awsExports.NEW_USER_POOL_FOR_EMAIL_CLIENT_ID,
      },
    });
    const session = await Auth.currentSession();
    let gg = userDataa;
    let newObbj = { ...gg, signInUserSession: session };
  };

  const refreshAdminToken = async () => {
    Amplify.configure({
      Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.ADMIN_POOL_ID,
        userPoolWebClientId: awsExports.ADMIN_POOL_APP_CLIENT_ID,
      },
    });
    const session = await Auth.currentSession();
    let gg = adminDataa;
    let newObbj = { ...gg, signInUserSession: session };
    dispatch(adminData(newObbj));
  };

  return {
    refreshUserToken,
    refreshAdminToken,
  };
};
export const refreshUserToken = async () => {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.NEW_USER_POOL_FOR_EMAIL,
      userPoolWebClientId: awsExports.NEW_USER_POOL_FOR_EMAIL_CLIENT_ID,
    },
  });
  const session = await Auth.currentSession();
  let newObbj = { signInUserSession: session };
  return newObbj;
};

export const refreshAdminToken = async () => {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.ADMIN_POOL_ID,
      userPoolWebClientId: awsExports.ADMIN_POOL_APP_CLIENT_ID,
    },
  });
  const session = await Auth.currentSession();

  let newObbj = { signInUserSession: session };
  // dispatch(adminData(newObbj));
  return newObbj;
};
export default useRefreshToken;
