import React from "react";
import "./adminChat.css";
import { IoIosSend } from "react-icons/io";
import AdminChatSideBar from "./adminChatSideBar";
import AdminChatScreen from "./adminChatScreen";
import { useSelector } from "react-redux";
export default function AdminChat() {
  const { userChatDetail } = useSelector((state) => state.adminChat);
  return (
    <div>
      {userChatDetail?.CONVERSATION_ID === undefined ||
      userChatDetail?.CONVERSATION_ID === "" ? (
        "No messsges"
      ) : (
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card chat-app">
                {/* admin chat side bar component */}
                <AdminChatSideBar />
                {/* admin chat side bar component */}
                {/* admin chat screen component */}
                <AdminChatScreen />
                {/* admin chat screen component */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
