import React, { useEffect, useRef, useState } from "react";
import { FaCheck, FaCheckDouble } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/common/Input";
import { setMessageToStore } from "../../../redux/reducers/userSlices/userSupportChat";
import { getUserDetail } from "../../../redux/thunk/user/usrUsers";
import { userChatServices } from "../../../services/user/userChat/userChatServices";
import { momentTimeFormat } from "../../../shared/helperFunction";
export default function SupportChat() {
  const userAuthtoken = useSelector(
    (state) => state.userAuth.userData.signInUserSession.idToken.jwtToken
  );

  const dispatch = useDispatch();
  const { userDataFromDB } = useSelector((state) => state.userList);
  const [sendMessage, setSendMessage] = useState({
    messageValue: "",
    messages: [],
  });
  const ref = useRef(null);
  const userDataa = useSelector((state) => state.userAuth.userData);
  const getuserDetail = {
    sub: userDataa?.attributes?.sub,
  };

  useEffect(() => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [sendMessage.messages]);

  // intial or window reload that useEffect is intialy called
  useEffect(() => {
    getUserChat();
  }, [userDataFromDB?.ChatID]);

  // this useeffect is called after the 15 second
  useEffect(() => {
    // Setting up the interval
    if (sendMessage.messageValue === "") {
      const intervalId = setInterval(() => {
        getUserChat();
      }, 15000);
      return () => clearInterval(intervalId);
    }
  }, [userDataFromDB?.ChatID, sendMessage.messageValue]);

  //** get the user chat  **/
  const getUserChat = () => {
    const body = {
      ChatID: userDataFromDB?.ChatID,
      Admin: false,
    };
    const onSuccess = (res) => {
      if (res?.data?.success) {
        setSendMessage((prev) => ({
          ...prev,
          messages: res?.data?.data,
        }));
        dispatch(setMessageToStore(res?.data?.data));
      }
    };
    const onError = (err) => {};
    userChatServices.getUserMessage(
      userAuthtoken,
      body,
      onSuccess,
      onError,
      {}
    );
  };

  //@ onchange function to set the event in our component state
  const onChange = (e) => {
    setSendMessage((prev) => ({
      ...prev,
      messageValue: e.target.value,
    }));
  };

  //@@ function that is perform onclick of the button and with this add the message to the message array
  const onClickSendMessage = (e) => {
    e.preventDefault();
    if (sendMessage.messageValue.trimStart() === "") {
      return;
    } else {
      setSendMessage((prev) => ({
        ...prev,
        messageValue: "",
        messages: [
          ...prev.messages,
          {
            ADMIN: false,
            ID: "",
            MESSAGE: sendMessage.messageValue,
            READ_: false,
            TIMESTAMP_: new Date(),
            USERNAME: "",
          },
        ],
      }));
      chatMessageSend();
    }
  };

  // send user chat message to backend
  const chatMessageSend = () => {
    const body = {
      ChatID: userDataFromDB?.ChatID,
      Msg: sendMessage.messageValue,
      UserID: userDataFromDB?.UserID,
    };
    const onSuccess = (res) => {
      if (res.data.success) {
        getUserChat(); // get the user chat after user send the message to backend
        if (!userDataFromDB?.ChatID) {
          // this dispatch is run when we have not chat id in the redux store basically it is for new user
          dispatch(getUserDetail(getuserDetail));
        }
      }
    };
    const onError = (err) => {
      console.log("Errr", err);
    };
    userChatServices.userSendMessage(
      userAuthtoken,
      body,
      onSuccess,
      onError,
      {}
    );
    setSendMessage((prev) => ({ ...prev, messageValue: "" }));
  };

  return (
    <div className="chat_wrapper">
      <div className="modal_title_chat">
        <h2>Support Chat</h2>

        {/* <h4>{supportChatModalMessage}</h4> */}
      </div>
      <div className="message_wrapper">
        <div className="message_block">
          {/* <div className="chat_height_vh"> */}
          <div className="all_msgs">
            {sendMessage?.messages?.map((messageItem, index) => (
              <p
                className={
                  messageItem.ADMIN === true ? "sender_msg_left" : "sender_msg"
                }
                ref={sendMessage?.messages?.length - 1 ? ref : null}
                key={index}
              >
                {messageItem.MESSAGE}
                <span
                  style={{
                    display: "block",
                    color: "#2780cd",
                    fontSize: "14px",
                  }}
                >
                  {momentTimeFormat(messageItem.TIMESTAMP_)}
                  {messageItem.ADMIN === false &&
                  messageItem.READ_ === false ? (
                    <FaCheck className="ms-2" />
                  ) : messageItem.ADMIN === false ? (
                    <FaCheckDouble className="ms-2" />
                  ) : null}
                </span>
              </p>
            ))}
          </div>
        </div>
        {/* </div> */}
        <div className="chat-message support_chat clearfix">
          <form onSubmit={onClickSendMessage}>
            <div className="sender_input_btn input-group mb-0">
              <Input
                className="form-control"
                value={sendMessage.messageValue.trimStart()}
                onChange={onChange}
                placeholder={"Enter Message"}
              />
              <div className="input-group-prepend">
                <span className="input-group-text send_msg_btn">
                  <button type="submit">
                    <IoIosSend style={{ cursor: "pointer" }} size={25} />
                  </button>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
