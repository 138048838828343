import { createSlice } from "@reduxjs/toolkit";
// import {
//   deleteAdminUser,
//   filterAdminUserbyDate,
//   getAdminUserList,
//   searchAdminUserList,
// } from "../../thunk/admin/adUser";
import {
  getSubscriptionListApi,
  addSubscriptionPlan,
  getSubscriptionByID,
  updateSubscriptionByID,
  updateNextMonthPlan,
} from "../../thunk/admin/Subscription";
import { toast } from "react-toastify";

const initialState = {
  adminSubscriptionList: {
    data: [],
    count: 0,
    dataWithId: [],
  },
};

const adminSubscriptionListSlice = createSlice({
  name: "getSubscriptionListApiSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get subscription list
    builder
      .addCase(getSubscriptionListApi.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(getSubscriptionListApi.fulfilled, (state, action) => {
        const { payload } = action;
        state.adminSubscriptionList.data = payload.body.data;
        state.status = true;
      })
      .addCase(getSubscriptionListApi.rejected, (state, action) => {
        state.status = false;
      });

    // create subscription
    builder
      .addCase(addSubscriptionPlan.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(addSubscriptionPlan.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload.success === true) {
          toast.success(payload.message);
        } else if (payload.success === false) {
          toast.error(payload.message);
        }
        // state.adminSubscriptionList = payload;
        state.status = true;
      })
      .addCase(addSubscriptionPlan.rejected, (state, action) => {
        // if (action.error.message === "Rejected") {
        //   toast.error("Token is expired")
        // }
        state.status = false;
      });

    //----------------- get subscription by id -----------------

    builder
      .addCase(getSubscriptionByID.pending, (state) => {
        state.error = null;
        state.status = false;
      })
      .addCase(getSubscriptionByID.fulfilled, (state, action) => {
        const { payload } = action;
        state.adminSubscriptionList.dataWithId = payload.data[0];
        state.status = true;
      })
      .addCase(getSubscriptionByID.rejected, (state, action) => {
        state.status = false;
      });

    // -------------------- record of updated plan  --------------------
    builder
      .addCase(updateNextMonthPlan.pending, (state) => {
        state.error = null;
        state.success = false;
      })
      .addCase(updateNextMonthPlan.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload.success === true) {
          toast.success(payload.message);
        } else {
          toast.error(payload.message);
        }
        state.status = true;
      })
      .addCase(updateNextMonthPlan.rejected, (state, action) => {
        state.success = false;
      });

    //----------------- update subscription by id -----------------

    // builder
    // .addCase(updateSubscriptionByID.pending, (state) => {
    //   state.error = null;
    //   state.status = false;
    // })
    // .addCase(updateSubscriptionByID.fulfilled, (state, action) => {
    //   const { payload } = action;
    //   // state.adminSubscriptionList.dataWithId = payload.data;
    //   state.status = true;
    // })
    // .addCase(updateSubscriptionByID.rejected, (state, action) => {
    //   state.status = false;
    // });
  },
});

// export const {} = adminUserSlice.actions;
export default adminSubscriptionListSlice.reducer;
