const userApi = {
  usrUserList: "https://gu9w5obxoa.execute-api.us-east-2.amazonaws.com/dev",
  userSignup: "user/signup",
  usrLogin: "user/login",
  usrPrograms: "web/user/programs",
  usrVerify: "/user/verify_mobile_number",
  usrGoogleLogin: "user/web_login_google",
  usrFacebookLogin: "user/login_facebook",
  usrMyAccount: "user/my_account",
  usrEditProfile: "user/update",
  usrViewCount: "user/program_view_count",
  usrRecentProgram: "user/recent_program",
  usrMakePayment: "/user/payment_intilization",
  usrPaymentConfirm:
    "https://put6k6g6rd.execute-api.us-east-2.amazonaws.com/dev/confirmPayment",
  usrMyPrograms: "/user/my_programs",
  // usrCategories: "/categories?all=true",
  usrCategories: "https://sva5oep6rd.execute-api.us-east-2.amazonaws.com/dev",

  usrSpeakers: "/speakers?all=true",
  usrUpdateLanguage: "/user/language",
  usrDownloadProgram: "/user/download_video_link",
  usrProgramStatus: "/user/my_program_payment_status",
  usrResendOtp: "/user/resend_otp",
  userProgramWithID: "/user/videoplayer/",
  //pending
  usrRefreshToken: "/user/refresh_token",
  usrFilterPrograms: "user/filter",
  userDownloadVideo: "/user/generate_Video_link/",
  userRecomendedProgram: "/user/recommended_programs",

  getLeads: "https://j0y4cokkuj.execute-api.us-east-2.amazonaws.com/dev",

  // add some api endpoints

  userSubscriptionHistory:
    "https://ufvc235bx5.execute-api.us-east-2.amazonaws.com/dev",
};

const commonApi = {
  forgetPass: "/forget_password",
  adFaqList: "/faq_list",
  adGetSocialMedia: "/social_media_links",
  adGetAboutUs: "/about_us",
  adGetPrivacyPolicy: "/privacy_policy",
  adGetTermAndConditions: "/terms_and_conditions",
  adGetSupport: "/support",
};

const adminApi = {
  adLogin: "/admin/login",
  // adUserList: "https://4cultacn6klpe54afqlra5iqxy0gturd.lambda-url.us-east-2.on.aws/getAdminsList",
  adUserList: "https://0dedfahpb6.execute-api.us-east-2.amazonaws.com/dev",

  adUserDetail: "/admin/userdetail",
  adSpeakersList: "/admin/speakers",
  adCategories: "/admin/categories",
  adTagsList: "/admin/tags",
  adPrograms: "/admin/programs",
  adDelUser: "https://gu9w5obxoa.execute-api.us-east-2.amazonaws.com/dev",
  adAddSpeaker: "/admin/speakers",
  adAddCategory: "/admin/categories",
  adAddTags: "/admin/tags",
  adDashboard: "/admin/dashboard_stats",
  adSearchUser: "/admin/search_user",
  adFilterUser: "/admin/filtered_users",
  adDelSpeaker: "/admin/delete_speaker",
  adDelCategory: "/admin/delete_category",
  adDelTag: "/admin/delete_tag",
  adDelFaq: "/admin/delete_faq",
  adAddFaq: "/admin/add_faq",
  adSearchFaq: "admin/search_faq",
  adSearchProgram: "/admin/search_program",
  adAddProgram: "/admin/program",
  adAddSocialMedia: "/admin/add_social_media_links",
  adAddAboutUs: "/admin/add_about_us",
  adAddPrivacyPolicy: "/admin/add_privacy_policy",
  adAddTermAndConditions: "/admin/add_terms_and_conditions",
  adAddSupport: "/admin/add_support",
  adDelProgram: "/admin/delete_program",
  adGetProgram: "/admin/program",
  adGetPaymentList: "/admin/payments",
  adSearchPayment: "/admin/search_payments",
  adFilterPayment: "admin/filter_payments",
  adGetPaymentDetail: "",
  adUpdateProgram: "/admin/program",
  adNotification: "/admin/notification",
  adDeleteNotification: "/admin/notification",
  adDeleteNotifications: "/admin/notifications",
  adLatestPrograms: "/admin/latest_programs",
  getSubscriptionList:
    "https://8ycjf4c9i4.execute-api.us-east-2.amazonaws.com/dev",
  createSubscription:
    "https://8ycjf4c9i4.execute-api.us-east-2.amazonaws.com/dev",
  billingHistory: "https://kn2tsh6hxj.execute-api.us-east-2.amazonaws.com/dev",
  //pending
};

export { userApi, adminApi, commonApi };
