import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { logo } from "../../assets/images/admin";
import { Link, useLocation } from "react-router-dom";
import { adminSidebarList } from "../../utility/sidebarList";
import { useDispatch, useSelector } from "react-redux";
import {
  adminLogout,
  superAdminData,
} from "../../redux/reducers/adminSlices/adAuth";
import "../../styles/admin/sidebar.scss";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CreateSubAdmin from "../../pages/admin/CreateSubAdmin";
import { ROUTES } from "../../navigation/constants";
import { payment, tags } from "../../assets/icons/admin";
import { filter } from "../../assets/icons/user";
import { adminChatServices } from "../../services/admin/adminChat/adminChatServices";

function SideBar({ show, setShow }) {
  const { createSubAdmin, adAdminTable } = ROUTES;
  const [sum, setSum] = useState(0);
  const adminAuthtoken = useSelector(
    (state) => state.adAuth.adminData.signInUserSession.idToken.jwtToken
  );
  const superAdmin = useSelector((state) => state.adAuth.superAdminData);
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [adminListSidebar, setAdminListSidebar] = useState(adminSidebarList);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  useEffect(() => {
    setsidebar();
  }, [superAdmin?.SuperAdmin]);

  // get notification from backend
  useEffect(() => {
    adminChatUserListing();
  }, [location.pathname]);
  const setsidebar = () => {
    let data = [...adminListSidebar];
    if (data?.length === 7) {
      return;
    } else if (superAdmin?.SuperAdmin === true) {
      data.splice(1, 0, {
        id: 2,
        path: adAdminTable,
        title: "Admin Table",
        icon: payment,
        type: "link",
        activeFor: [adAdminTable],
      });
      data.splice(4, 0, {
        id: 5,
        type: "link",
        path: createSubAdmin,
        Component: CreateSubAdmin,
        title: "Create Admin",
        icon: tags,
        activeFor: [createSubAdmin],
      });
    }
    setAdminListSidebar(data);
  };

  // get notification
  const adminChatUserListing = () => {
    const onSuccess = (res) => {
      if (res.data.success === true) {
        let sum = 0;
        res?.data?.data?.forEach((el) => (sum += Number(el?.UnReadCount)));
        setSum(sum);
      }
    };
    const onError = (err) => {
      console.log("getlistintgerr", err);
    };
    adminChatServices.adminChatSideBarUserListing(
      adminAuthtoken,
      onSuccess,
      onError
    );
  };
  const logoutHandler = () => {
    signOut();
    dispatch(adminLogout());
    dispatch(superAdminData(null));
  };

  return (
    <div className={show ? "width_show_sidebar sidebar" : "sidebar"}>
      <div className="brand-log  text-center">
        <Link to="#" className="logo_link">
          <Image src={logo} />
        </Link>
      </div>

      <div className="sidebar_links">
        <ul>
          {adminListSidebar.map(
            ({ id, path, title, icon, type, activeFor }, index) => {
              if (type === "link") {
                return (
                  <li
                    key={id}
                    className={activeFor.includes(pathname) && "active"}
                    onClick={() => setShow(false)}
                  >
                    <Link
                      to={path}
                      style={
                        title === "Chat Support" ? { position: "relative" } : {}
                      }
                    >
                      <Image src={icon} />
                      {title === "Chat Support" && sum !== 0 ? (
                        <>
                          {title} <span className="uncount_msg">{sum}</span>
                        </>
                      ) : (
                        title
                      )}
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li key={id} onClick={logoutHandler}>
                    <Link to={path}>
                      <Image src={icon} />
                      {title}
                    </Link>
                  </li>
                );
              }
            }
          )}
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
