import { AxiosInstance } from "./axiosInstanceV2";

// ** global method for the get api to get the response ** //
//@@ callback function onSuccess and onError which give response
export const GETAPI = (url,token, onSuccess, onError, config) => {
  const axios = AxiosInstance(token);
  axios
    .get(url)
    .then((res) => onSuccess(res))
    .catch((err) => onError(err));
};


// ** global method for post request  ** //
//@@ Body 
//@@ callback function onSuccess and onError which give response
export const POSTAPI = (token,url,body,onSuccess,onError,config)=>{
    const axios = AxiosInstance(token)
    axios.post(url,body).then((res)=>onSuccess(res)).catch((err) => onError(err));
}

// ** global method for put request  ** //
//@@ Body 
//@@ callback function onSuccess and onError which give response
export const PUTAPI = (token,url,body,onSuccess,onError,config)=>{
  const axios = AxiosInstance(token)
  axios.put(url,body).then((res)=>onSuccess(res)).catch((err) => onError(err));
}