import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logo } from "../../assets/images/admin";
import { userLogout } from "../../redux/reducers/userSlices/userAuth";
import { getUserDetail } from "../../redux/thunk/user/usrUsers";
import "../../styles/admin/sidebar.scss";
import { userSidebarList } from "../../utility/sidebarList";

function SideBar({ show, setShow }) {
  const { user, signOut } = useAuthenticator((context) => [context?.user]);
  const { userDataFromDB } = useSelector((state) => state.userList);
  // const { userData } = useSelector((state) => state.userAuth);

  const userDataa = useSelector((state) => state.userAuth.userData);

  const location = useLocation();
  const { pathname } = location;

  //Redux action dispatcher
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(userLogout());
    signOut();
  };

  let data = {
    sub: userDataa?.attributes?.sub,
  };

  useEffect(() => {
    dispatch(getUserDetail(data));
  }, []);
  return (
    <div className={show ? "width_show_sidebar sidebar" : "sidebar"}>
      <div className="brand-log  text-center">
        <Link to="#" className="logo_link">
          <Image src={logo} />
        </Link>
      </div>

      <div className="sidebar_links">
        <ul>
          {userSidebarList.map(
            ({ id, path, title, icon, type, activeFor, paid }, index) => {
              if (type === "link") {
                return (
                  <li
                    key={id}
                    className={activeFor.includes(pathname) && "active"}
                    onClick={() => setShow(false)}
                  >
                    <Link to={path}>
                      <Image
                        style={{
                          maxWidth: `${title === "Downloads" && "11%"} `,
                        }}
                        src={icon}
                      />
                      {title}
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li key={id} onClick={logoutHandler}>
                    <Link to={path}>
                      <Image src={icon} />
                      {title}
                    </Link>
                  </li>
                );
              }
            }

            // Return null for routes that should not be shown
            // return null;
          )}
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
