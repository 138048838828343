import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CheckoutForm, CustomModal } from "../../components/common";
import { getSubscriptionListApi } from "../../redux/thunk/admin/Subscription";
import "../../styles/admin/categories.scss";
import { useModal } from "../../utility/hooks";
import moment from "moment";
import { toast } from "react-toastify";
import { Loader } from "../../components/common";
import {
  hideRootLoader,
  showRootLoader,
} from "../../redux/reducers/common/appSlice";
import { modifiedUserDataFromDB } from "../../redux/reducers/userSlices/usrUserList";
import { userMakePayment } from "../../redux/thunk/user/usrPayment";
import { userSubscriptionServices } from "../../services/user/userSubscription/userSubscription";
import { useRefreshToken } from "../../utility/hooks";
import {
  globalCurrencyFormat,
  numberToCommaFormat,
} from "../../shared/helperFunction";
import { useStripe } from "@stripe/react-stripe-js";

function UserSubscription() {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { refreshUserToken } = useRefreshToken();
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const userAuthtoken = useSelector(
    (state) => state.userAuth.userData.signInUserSession.idToken.jwtToken
  );
  const [loading, setLoading] = useState(false);
  // const { adminSpeakers } = useSelector((state) => state.adSpeaker);
  const { userDataFromDB } = useSelector((state) => state.userList) || {};
  const { loader } = useSelector((state) => state.app);
  const adminSubscriptionList = useSelector(
    (state) => state.adSubscription.adminSubscriptionList.data
  );
  const { userData } = useSelector((state) => state.userAuth);
  const [plan, setPlan] = useState("");
  useEffect(() => {
    refreshUserToken();
    if (cancelModalShow === false) {
      dispatch(getSubscriptionListApi());
    }
  }, [cancelModalShow, userDataFromDB?.CurrentPlan]);

  const {
    show,
    handleClose,
    handleShow,
    shareShow,
    handleShareClose,
    handleShareShow,
  } = useModal();
  // handling the plan
  function planHandler(planId, buttonName, clickedCurrentPlan) {
    const values = {
      PlanID: planId,
      UserID: userData?.attributes?.sub,
    };
    dispatch(showRootLoader());
    // Hit API to initiate Stripe payment
    dispatch(userMakePayment({ userAuthtoken, values }))
      .then((res) => {
        if (res?.payload?.success === true) {
          // Initiate Stripe payment confirmation
          stripe
            .confirmCardPayment(res?.payload?.client_secret)
            .then(({ error, paymentIntent }) => {
              if (error) {
                console.log("Error during payment confirmation", error);
              } else {
                if (paymentIntent.status === "succeeded") {
                  if (buttonName === "Upgrade Plan") {
                    let dataSendToRedux = { ...userDataFromDB };
                    dataSendToRedux = {
                      ...dataSendToRedux,
                      CurrentPlan:
                        clickedCurrentPlan?.PlanName === "Individual"
                          ? "Individual"
                          : "Professional",
                    };
                    dispatch(modifiedUserDataFromDB(dataSendToRedux));
                  } else if (buttonName === "join") {
                    let dataSendToRedux = { ...userDataFromDB };
                    dataSendToRedux = {
                      ...dataSendToRedux,
                      CurrentPlan:
                        clickedCurrentPlan?.PlanName === "Individual"
                          ? "Individual"
                          : "Professional",
                    };
                    dispatch(modifiedUserDataFromDB(dataSendToRedux));
                  }
                  toast.success("Payment successful");
                  dispatch(hideRootLoader());
                }
              }
            })
            .catch((err) => {
              console.log("Err during confirmCardPayment", err);
              dispatch(hideRootLoader());
            });
        }
      })
      .catch((err) => {
        console.log("Err", err);
        dispatch(hideRootLoader());
        toast.error("Error initiating payment");
      });
  }
  // show cancel modal open
  const cancelModal = () => {
    setCancelModalShow(true);
  };
  //  handling the date
  const FormatDate = ({ isoDateString }) => {
    // Convert ISO string to Date object
    const date = new Date(isoDateString);

    // Format the date
    const formattedDate = date.toLocaleDateString(); // Default format (e.g., MM/DD/YYYY)

    return <>{formattedDate}</>;
  };
  const cancelCurrentPlan = () => {
    setLoading(true);
    const onSuccess = (res) => {
      if (res?.data?.success === false) {
        toast.error(res?.data?.message);
      } else if (res?.data?.success === true) {
        toast.success(res?.data?.message);
        setCancelModalShow(false);
        // change the key currentplan key value aftersuccessfully cancel plan api is hitted
        let dataSendToRedux = { ...userDataFromDB };
        dataSendToRedux = {
          ...dataSendToRedux,
          CurrentPlan: "",
        };
        dispatch(modifiedUserDataFromDB(dataSendToRedux));
      }
      setLoading(false);
    };
    const onError = (err) => {
      console.log("errrrr", err);
      setLoading(false);
    };
    const body = {
      UserID: userDataFromDB?.UserID,
      // SubscriptionID: userDataFromDB?.SubscriptionID,
    };
    userSubscriptionServices.cancelPlan(
      userAuthtoken,
      body,
      onSuccess,
      onError,
      {}
    );
  };
  return (
    <>
      <CustomModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        modalHead="Payment Screen"
        className="user_modal"
      >
        <CheckoutForm planID={plan} onCancel={() => handleClose()} />
      </CustomModal>
      {/* cancel the plan modal */}
      <CustomModal
        show={cancelModalShow}
        handleClose={() => (loading ? "" : setCancelModalShow(false))}
        handleShow={() => setCancelModalShow(true)}
        modalHead="Cancel Plan"
        className="user_modal"
      >
        <h3>Are you sure you want to cancel this plan</h3>
        {loading ? (
          <Button className="btn btn-danger">Loading...</Button>
        ) : (
          <Button
            className="btn btn-danger"
            onClick={() => cancelCurrentPlan()}
          >
            Cancel Plan
          </Button>
        )}
      </CustomModal>
      {/* cancel the plan modal */}
      <div className="categories_section card-sec bg-white p-3">
        <h3 className="title">Choose Your Plan</h3>

        <div>
          <Row className="subscription_row">
            {loader ? (
              <Loader />
            ) : adminSubscriptionList?.length > 0 ? (
              adminSubscriptionList?.map((data, index) => {
                let prrr = Number(data.PricePerMonth);
                function centsToDollars(cents) {
                  return cents / 100;
                }
                const dollarsValue = centsToDollars(prrr);

                return (
                  <Col key={index} md={6} lg={4} xl={3} xxl={3}>
                    {userDataFromDB?.CurrentPlan === "Trial" && (
                      <div
                        className="sub_message"
                        style={{ minHeight: "10px" }}
                      >
                        You're currently on a 7-day trial period. At the end of
                        this trial period, your plan will automatically convert
                        to the Individual Plan unless you choose to cancel or
                        modify it.
                      </div>
                    )}

                    <div
                      className={`pricing-plans ${
                        userDataFromDB?.CurrentPlan === data.PlanName
                          ? "active"
                          : null
                      }
                    `}
                    >
                      <div className="plan">
                        {userDataFromDB?.CurrentPlan !== "Trial" ? (
                          data.PlanName != "Trial" ? (
                            <div className="plan-inner">
                              <div className="entry-title">
                                <h3>{data.PlanName}</h3>
                                <div className="price">
                                  {globalCurrencyFormat().currencyFormatToUSD(
                                    Number(dollarsValue)
                                  )}
                                  <span>/PER Month</span>
                                </div>
                              </div>
                              <div className="entry-content">
                                <ul>
                                  <li>
                                    <strong>
                                      {numberToCommaFormat(
                                        Number(data.EmailsAllowedPerMonth)
                                      )}
                                    </strong>{" "}
                                    emails
                                  </li>
                                  {data?.PlanName === "Trial"
                                    ? (data.Features?.[0]?.L ?? []).map(
                                        (featureItem, index) => (
                                          <li key={index}>{featureItem?.S}</li>
                                        )
                                      )
                                    : null}
                                </ul>
                              </div>
                              <div className="btn w-100 text-center">
                                {userDataFromDB?.CurrentPlan ===
                                data.PlanName ? (
                                  <>
                                    {/* <a
                                  className="cur-button"
                                  onClick={() => setPlan(data.SubscriptionID)}
                                >
                                  Current
                                </a> */}
                                    <span>
                                      {"Valid Till: "}
                                      <FormatDate
                                        isoDateString={userDataFromDB?.endDate}
                                      />
                                    </span>
                                    <button
                                      onClick={() => {
                                        cancelModal();
                                      }}
                                      className="btn btn-danger d-block mt-3"
                                      style={{
                                        padding: "3px",
                                        width: "133px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                      }}
                                    >
                                      Unsubscribe
                                    </button>
                                  </>
                                ) : userDataFromDB?.CurrentPlan === "Trial" ||
                                  userDataFromDB?.CurrentPlan === "" ? (
                                  data.PlanName === "Trial" ? null : (
                                    <a
                                      onClick={() => {
                                        // set the id on click of the join button
                                        setPlan(data.SubscriptionID);
                                        planHandler(
                                          data.SubscriptionID,
                                          "join",
                                          data
                                        );
                                      }}
                                    >
                                      Join Plan
                                    </a>
                                  )
                                ) : userDataFromDB?.NextPlan === undefined &&
                                  data.PlanName != "Trial" ? (
                                  <a
                                    onClick={() =>
                                      planHandler(
                                        data.SubscriptionID,
                                        "Upgrade Plan",
                                        data
                                      )
                                    }
                                  >
                                    Upgrade Plan
                                  </a>
                                ) : userDataFromDB?.NextPlan ===
                                  data.PlanName ? (
                                  <div>
                                    <a className="upc-button"> Upcoming</a>
                                    <div className="date-upcomming-plan">
                                      <b>Start from</b>:
                                      <span>
                                        {moment
                                          .unix(userDataFromDB?.NextPlanStart)
                                          .format("DD-MM-YYYY HH:mm")}
                                      </span>
                                    </div>
                                  </div>
                                ) : data.PlanName === "Trial" ? null : (
                                  <a className="dis-button"> Update </a>
                                )}
                              </div>
                            </div>
                          ) : null
                        ) : (
                          <div className="plan-inner">
                            <div className="entry-title">
                              <h3>{data.PlanName}</h3>
                              <div className="price">
                                {globalCurrencyFormat().currencyFormatToUSD(
                                  Number(dollarsValue)
                                )}
                                <span>/PER Month</span>
                              </div>
                              {/* <div style={{ minHeight: '10px' }}>
                                {data?.PlanName === "Trial" ? 'After 7 days Trial Plan will be converted into Individual Plan Automatically' : ''}
                              </div> */}
                            </div>
                            <div className="entry-content">
                              <ul>
                                <li>
                                  <strong>
                                    {numberToCommaFormat(
                                      Number(data.EmailsAllowedPerMonth)
                                    )}
                                  </strong>{" "}
                                  emails
                                </li>
                                {data?.PlanName === "Trial"
                                  ? (data.Features?.[0]?.L ?? []).map(
                                      (featureItem, index) => (
                                        <li key={index}>{featureItem?.S}</li>
                                      )
                                    )
                                  : null}
                              </ul>
                            </div>
                            <div className="btn w-100 text-center">
                              {userDataFromDB?.CurrentPlan === data.PlanName ? (
                                <>
                                  {/* <a
                                  className="cur-button"
                                  onClick={() => setPlan(data.SubscriptionID)}
                                >
                                  Current
                                </a> */}
                                  <span>
                                    {"Valid Till: "}
                                    <FormatDate
                                      isoDateString={userDataFromDB?.endDate}
                                    />
                                  </span>
                                  <button
                                    onClick={() => {
                                      cancelModal();
                                    }}
                                    className="btn btn-danger d-block mt-3"
                                    style={{
                                      padding: "3px",
                                      width: "133px",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  >
                                    Unsubscribe
                                  </button>
                                </>
                              ) : userDataFromDB?.CurrentPlan === "Trial" ||
                                userDataFromDB?.CurrentPlan === "" ? (
                                data.PlanName === "Trial" ? null : (
                                  <a
                                    onClick={() => {
                                      // set the id on click of the join button
                                      setPlan(data.SubscriptionID);
                                      planHandler(
                                        data.SubscriptionID,
                                        "join",
                                        data
                                      );
                                    }}
                                  >
                                    Join Plan
                                  </a>
                                )
                              ) : userDataFromDB?.NextPlan === undefined &&
                                data.PlanName != "Trial" ? (
                                <a
                                  onClick={() =>
                                    planHandler(
                                      data.SubscriptionID,
                                      "Upgrade Plan",
                                      data
                                    )
                                  }
                                >
                                  Upgrade Plan
                                </a>
                              ) : userDataFromDB?.NextPlan === data.PlanName ? (
                                <div>
                                  <a className="upc-button"> Upcoming</a>
                                  <div className="date-upcomming-plan">
                                    <b>Start from</b>:
                                    <span>
                                      {moment
                                        .unix(userDataFromDB?.NextPlanStart)
                                        .format("DD-MM-YYYY HH:mm")}
                                    </span>
                                  </div>
                                </div>
                              ) : data.PlanName === "Trial" ? null : (
                                <a className="dis-button"> Update </a>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div>Record not found</div>
            )}
          </Row>
        </div>
      </div>
    </>
  );
}

export default UserSubscription;
