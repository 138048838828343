import * as yup from "yup";
export const adminEditPlanSchema = yup.object().shape({
  EmailsAllowedPerMonth: yup
    .string()
    .required("Emails allowed per Month is a required field"),
  Attempt: yup
    .number()
    .max(10)
    .min(1, "Enter valid value")
    .required("Attempt Field is Required"),
})

