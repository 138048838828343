import { Authenticator } from "@aws-amplify/ui-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RootLoader from "./components/common/RootLoader";
import Navigation from "./navigation";
import "./utility/Translate/i18n";

//@@@ set the stripe key as per the env enivroment
//@@ REACT_APP_DEVELOPMENT_TYPE this is used for set the enviroment of the app
const stripePromise = loadStripe(
  process.env.REACT_APP_DEVELOPMENT_TYPE === "dev"
    ? process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY
    : process.env.REACT_APP_DEVELOPMENT_TYPE === "prod"
    ? process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY
    : ""
);
function App() {
  return (
    <>
      <Authenticator.Provider>
        <Elements stripe={stripePromise}>
          <Navigation />
          <RootLoader />
          <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Elements>
      </Authenticator.Provider>
    </>
  );
}

export default App;
