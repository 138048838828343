import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { adminRoutes } from "./routes/AdminRoutes";
import { userRoutes } from "./routes/userRoutes";
import AdPvtLayout from "../layouts/adminLayout/AdPvtLayout";
import UsrPvtLayout from "../layouts/userLayout/usrPvtLayout";
import AdPubLayout from "../layouts/adminLayout/AdPubLayout";
import { ROUTES } from "./constants";
import UserPubLayout from "../layouts/userLayout/UserPubLayout";
import CreateSubAdmin from "../pages/admin/CreateSubAdmin";
import { useSelector } from "react-redux";
import { AdminMangement, AdminTable } from "../pages/admin";
import RedirectToSubscription from "./renavigate";

const Navigation = () => {
  const { usrHome, createSubAdmin, adAdminTable } = ROUTES;
  const superAdmin = useSelector((state) => state.adAuth.superAdminData);
  const [adminRouteData, setAdminRouteData] = useState(adminRoutes);
  const customgetuserdata = useSelector(
    (state) => state?.userAuth?.userDetailsCustomdata
  );

  useEffect(() => {
    setAdminRoute();
  }, [superAdmin?.SuperAdmin]);
  // set admin route as per the super admin role
  const setAdminRoute = () => {
    if (adminRouteData.length === 15) {
      return;
    } else if (superAdmin?.SuperAdmin === true) {
      let data = [...adminRouteData];
      data.push(
        {
          id: 9,
          type: "private",
          path: createSubAdmin,
          Component: CreateSubAdmin,
          Auth: true,
          defaultComp: false,
        },
        {
          id: 8,
          type: "private",
          path: adAdminTable,
          Component: AdminMangement,
          Auth: true,
          defaultComp: false,
        }
      );
      // this filter array we have used is to remove the duplicate value from the array
      const filterValue = [...new Set(data)];
      setAdminRouteData(filterValue);
    }
  };

  return (
    <Routes>
      <Route path={usrHome} element={<UserPubLayout />}>
        {userRoutes.map(({ path, type, Auth, Component, defaultComp, id }) => {
          if (type === "public") {
            return (
              <Route
                key={id}
                path={path}
                index={defaultComp}
                element={<Component />}
              />
            );
          }
          return null;
        })}
      </Route>
      {/* user routes */}
      <Route path={usrHome} element={<UsrPvtLayout />}>
        {userRoutes.map(({ path, type, Auth, Component, defaultComp, id }) => {
          if (type === "private") {
            return customgetuserdata?.CurrentPlan == "" ? (
              <Route
                key={id}
                path={path}
                index={defaultComp}
                element={
                  <RedirectToSubscription>
                    <Component />
                  </RedirectToSubscription>
                }
              />
            ) : (
              <Route
                key={id}
                path={path}
                index={defaultComp}
                element={<Component />}
              />
            );
          }
          return null;
        })}
      </Route>

      {/*Admin Routes */}
      <Route path={usrHome} element={<AdPubLayout />}>
        {adminRoutes.map(({ path, type, Auth, Component, defaultComp, id }) => {
          if (type === "public") {
            return (
              <Route
                key={id}
                path={path}
                index={defaultComp}
                element={<Component />}
              />
            );
          }
          return null;
        })}
      </Route>

      <Route path={usrHome} element={<AdPvtLayout />}>
        {adminRouteData.map(
          ({ path, type, Auth, Component, defaultComp, id }) => {
            if (type === "private") {
              return (
                <Route
                  key={id}
                  path={path}
                  index={defaultComp}
                  element={<Component />}
                />
              );
            }
            return null;
          }
        )}
      </Route>
    </Routes>
  );
};

export default Navigation;
