import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RedirectToSubscription = ({ children }) => {
  const navigate = useNavigate();
  const currentPlan = useSelector(
    (state) => state?.userAuth?.userDetailsCustomdata?.CurrentPlan
  );

  React.useEffect(() => {
    // Redirect if no current plan
    if (!currentPlan) {
      navigate("/user/save-subscriptions", { replace: true }); // Absolute path
    }
  }, [currentPlan, navigate]);

  // Render children if there's a current plan, otherwise nothing
  return currentPlan ? children : null;
};

export default RedirectToSubscription;
