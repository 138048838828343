import NotFound from "../../pages/common/NotFound";
import Lead from "../../pages/user/Lead";
import LeadByInfluncer from "../../pages/user/LeadByInfluncer";
import Login from "../../pages/user/Login";
import SupportChatIndex from "../../pages/user/SupportChat/SupportChatIndex";
import UserDashboard from "../../pages/user/userDashboard";
import UserSubscription from "../../pages/user/userSubscription";
import History from "../../pages/user/History";
import UserProfile from "../../pages/user/UserProfile";
import { ROUTES } from "../constants";
import { withAuthenticator } from "@aws-amplify/ui-react";
import UserSaveCard from "../../pages/user/UserSaveCard";
import SaveUserSubscription from "../../pages/user/SaveUserSubscription";
import PaymentHistory from "../../pages/user/PaymentHistory";

const {
  usrLogin,
  usrCreatePassword,
  usrOtp,
  usrVideoPlayer,

  usrSignUp,
  usrEditProfile,
  usrAboutUs,
  usrContactUs,
  usrFaq,
  usrDashboard,
  usrSpeaker,
  usrCategories,
  usrLead,
  saveCardDetails,
  saveSubscriptionDetails,
  usrHistory,
  userLeadByInfluncer,
  usrSupportChat,
  usermanagecard,
  userPaymentHistory,
} = ROUTES;
export const userRoutes = [
  {
    id: 2,
    type: "public",
    path: usrLogin,
    Component: Login,
    Auth: false,
    defaultComp: false,
  },

  {
    id: 3,
    type: "public",
    path: "*",
    Component: NotFound,
    Auth: false,
    defaultComp: false,
  },
  {
    id: 4,
    type: "private",
    path: usrDashboard,
    Component: UserDashboard,
    Auth: true,
    defaultComp: false,
  },

  {
    id: 6,
    type: "private",
    path: usrCategories,
    Component: UserSubscription,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 7,
    type: "private",
    path: usrLead,
    Component: Lead,
    Auth: true,
    defaultComp: false,
  },
  ,
  {
    id: 8,
    type: "private",
    path: userLeadByInfluncer,
    Component: LeadByInfluncer,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 9,
    type: "private",
    path: usrHistory,
    Component: History,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 10,
    type: "private",
    path: usrSupportChat,
    Component: SupportChatIndex,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 11,
    type: "private",
    path: usrEditProfile,
    Component: UserProfile,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 14,
    type: "private",
    path: userPaymentHistory,
    Component: PaymentHistory,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 12,
    type: "public",
    path: saveCardDetails,
    Component: UserSaveCard,
    Auth: true,
    defaultComp: false,
  },
  {
    id: 13,
    type: "public",
    path: saveSubscriptionDetails,
    Component: SaveUserSubscription,
    Auth: true,
    defaultComp: false,
  },
];
