import React, { Component, useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  addSubscriptionPlan,
  getSubscriptionByID,
} from "../../redux/thunk/admin/Subscription";
import { Loader } from "../../components/common";
import { useRefreshToken } from "../../utility/hooks";
import { adminEditPlanSchema } from "../../shared/schema/admin/adminEditPlanSchema";
import { toast } from "react-toastify";
import DropDownField from "../../components/common/Dropdown";
import { pricingPlanServices } from "../../services/admin/pricingPlan/pricingPlanServices";
import { Button } from "react-bootstrap";
import { ROUTES } from "../../navigation/constants";
import { hideLoader, showLoader } from "../../redux/reducers/common/appSlice";

const EditPricingPlan = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dropDownArrayAttempt = [];
  for (let i = 0; i <= 10; i++) {
    dropDownArrayAttempt.push({ label: `${i}`, value: `${i}` });
  }
  let featureArrayWarning = true;
  const location = useLocation();
  const dispatch = useDispatch();
  const { refreshAdminToken } = useRefreshToken();
  const adminAuthtoken = useSelector(
    (state) => state.adAuth.adminData.signInUserSession.idToken.jwtToken
  );
  const { loader } = useSelector((state) => state.app);

  useEffect(() => {
    refreshAdminToken();
    getData();
  }, []);

  const handleOnSubmit = (values) => {
    /// **** check the feature array field required checked
    values?.Features[0]?.forEach((element) => {
      element.length === 0
        ? (featureArrayWarning = true)
        : (featureArrayWarning = false);
    });
    if (featureArrayWarning === true || values?.Features[0].length === 0) {
      return toast.error("Feature is required");
    }
    /// **** check the feature array field required checked
    setLoading(true);
    const formData = {
      EmailsAllowedPerMonth: Number(values.EmailsAllowedPerMonth),
      //   Subscription_ID: getId,
      Features: values.Features[0],
      TrialAttempts: Number(values.Attempt),
    };
    const onSuccess = (res) => {
      if (res?.data?.success === true) {
        toast.success("Plan update SuccessFully");
        navigate(ROUTES.adSubscriptionManagement);
      }
      setLoading(false);
    };
    const onError = (err) => {
      console.log("errrrr...", err);
      setLoading(false);
    };
    pricingPlanServices.editPricingPlan(
      adminAuthtoken,
      formData,
      onSuccess,
      onError,
      {}
    );
  };

  const getData = () => {
    const urlParams = new URLSearchParams(location.search);
    const getId = urlParams.get("id");
    const formDATA = {
      Subscription_ID: getId,
    };
    const data = {
      adminAuthtoken,
      values: {
        ...formDATA,
      },
    };
    dispatch(getSubscriptionByID(data)).then((res) => {
      if (res.payload.statusCode === 200) {
        formik.setValues({
          ...formik.values,
          PlanName: res.payload.data[0].PlanName,
          EmailsAllowedPerMonth: res.payload.data[0].EmailsAllowedPerMonth,
          Features: res.payload.data[0].Features,
          Attempt: res.payload.data[0].TrialAttempts,
        });
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      PlanName: "",
      EmailsAllowedPerMonth: "",
      Features: [],
      Attempt: "",
    },
    validationSchema: adminEditPlanSchema,
    onSubmit: handleOnSubmit,
  });

  const setInputValue = useCallback(
    (key, value) => {
      if (key[0] === "Features") {
        let data = [...formik.values.Features[0]];
        data[key[1]] = value.trimStart();
        formik.setValues({ ...formik.values, Features: [data] });
      } else {
        formik.setValues({
          ...formik.values,
          [key]: value,
        });
      }
    },
    [formik]
  );
  return (
    <div>
      <div className="card-sec bg-white p-3">
        <h4>Edit Pricing Plan</h4>
        <div className="form-section mt-3">
          {loader ? (
            <Loader />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>Plan Name</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder="Enter Plan Name"
                    value={formik?.values?.PlanName}
                    onChange={(e) => setInputValue("PlanName", e.target.value)}
                  />
                  <small className="error-text">
                    {formik.touched.PlanName && formik.errors.PlanName}
                  </small>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>Attempt's</label>
                  <DropDownField
                    name=""
                    attr=""
                    onChange={(attr, value) => setInputValue("Attempt", value)}
                    data={dropDownArrayAttempt}
                    value={formik?.values?.Attempt}
                  />
                  <small className="error-text">
                    {formik.touched.Attempt && formik.errors.Attempt}
                  </small>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>Email Per Month</label>
                  <input
                    type="text"
                    placeholder="Enter email limit per month"
                    value={formik?.values?.EmailsAllowedPerMonth}
                    onChange={(e) =>
                      setInputValue(
                        "EmailsAllowedPerMonth",
                        e.target.value.replace(/\D/g, "")
                      )
                    }
                  />
                  <small className="error-text">
                    {formik.touched.EmailsAllowedPerMonth &&
                      formik.errors.EmailsAllowedPerMonth}
                  </small>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-between py-3 border-bottom mb-3 mobileresponsive">
                <label>Plan Features</label>
                <Button
                  type="button"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    if (
                      formik?.values?.Features &&
                      formik.values.Features.length > 0
                    ) {
                      if (formik.values.Features[0].length >= 5) {
                        return toast.error("You can add only five Features");
                      }
                      setInputValue(
                        ["Features", formik.values.Features[0].length],
                        ""
                      );
                    }
                  }}
                >
                  +
                </Button>
              </div>
              {formik?.values?.Features?.[0]?.map((featureItem, index) => (
                <div className="col-lg-6 col-12">
                  <div className="input_label_wrap">
                    <input
                      type="text"
                      className="col-11"
                      style={{ width: "90%" }}
                      placeholder="Enter email limit per month"
                      value={featureItem}
                      onChange={(e) => {
                        setInputValue(["Features", index], e.target.value);
                      }}
                    />
                    <Button
                      className="col-1"
                      type="button"
                      style={{
                        background: "red",
                        borderColor: "red",
                        float: "right",
                      }}
                      onClick={() => {
                        let data = [...formik?.values?.Features[0]];
                        data.splice(index, 1);
                        setInputValue("Features", [data]);
                      }}
                    >
                      -
                    </Button>
                    <small className="error-text">
                      {/* this is custom validation not using the validation schema of the formik  there is featureArrayWarning is a global variable which is maipulated onsubmit function*/}
                      {featureArrayWarning === true && featureItem === ""
                        ? "Feature is required"
                        : null}
                    </small>
                  </div>
                </div>
              ))}
              <div className="common_btns">
                {loading ? (
                  <button title="" type="button" className="primary_btn">
                    ..........
                  </button>
                ) : (
                  <button
                    title="Download"
                    type="submit"
                    className="primary_btn"
                  >
                    Update Plan
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPricingPlan;
