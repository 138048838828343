import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Header, SideBar } from "../../components/admin";
import "../../styles/admin/App.scss";

import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../navigation/constants";
import { superAdminData } from "../../redux/reducers/adminSlices/adAuth";
import { userLogout } from "../../redux/reducers/userSlices/userAuth";
import { checkSuperAdminServices } from "../../services/auth/checkSuperAdminAuth";
import { checkUserStatus } from "../../services/auth/userEnableDisableCheck";
import { useRefreshToken } from "../../utility/hooks";
function AdPvtLayout() {
  const [show, setShow] = useState(false);

  const { adminData } = useSelector((state) => state.adAuth);
  const token = useSelector(
    (state) => state?.adAuth?.adminData?.signInUserSession?.idToken?.jwtToken
  );
  let adminSession = adminData?.signInUserSession;
  const { adLogin } = ROUTES;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refreshAdminToken } = useRefreshToken();
  useEffect(() => {
    userEnableDisableCheck();
    refreshAdminToken(); // refresh the jwt token on based of refresh token
  }, [location.pathname]);
  useEffect(() => {
    checkSuperAdminFromBackend();
  }, [location.pathname]);
  // to check the admin is super admin or not from the backend server
  const checkSuperAdminFromBackend = async () => {
    if (
      adminData?.attributes?.sub === null ||
      adminData?.attributes?.sub === undefined
    ) {
      return;
    }
    const userCheck = await checkUserStatus();
    const onSuccess = (res) => {
      dispatch(superAdminData(res.data.data));
    };
    const onError = (err) => {
      console.log("err", err);
    };
    const body = {
      Id: userCheck?.response.attributes?.sub,
      // Id: "e19e21d3-27b5-4c1e-8d5d-3a06471dedc5"
    };
    checkSuperAdminServices.checkSuperAdmin(
      token,
      body,
      onSuccess,
      onError,
      {}
    );
  };
  // to check on every route that user is enable from the aws cognito pool
  const userEnableDisableCheck = async () => {
    const userCheck = await checkUserStatus();
    if (userCheck === true) {
      dispatch(userLogout()); // logout the session from the redux store
      return navigate("/admin");
    }
  };

  if (adminSession) {
    return (
      <div className="main_wrapper">
        <SideBar show={show} setShow={setShow} />
        <div className="right_section">
          <Header show={show} setShow={setShow} />
          <div className="admin-content-section">
            <Outlet />
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to={adLogin} />;
  }
}

export default AdPvtLayout;
