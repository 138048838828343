import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { view } from "../../assets/icons/admin";
import { CustomModal, ReactDataTable } from "../../components/common";
import DropDownField from "../../components/common/Dropdown";
import Loader from "../../components/common/Loader";
import { getUsersList } from "../../redux/thunk/user/usrUsers";
import { userMangeMentServices } from "../../services/admin/userMangement/userMangementService";
import { useModal, useRefreshToken } from "../../utility/hooks";
import { getLeadRequestAllServices } from "../../services/admin/GetLeadReq/getLeadReqService";
import { hideLoader, showLoader } from "../../redux/reducers/common/appSlice";
import { toast } from "react-toastify";
import { FaMoneyBillTransfer } from "react-icons/fa6";

function UserManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    show,
    handleClose,
    handleShow,
    shareShow,
    handleShareClose,
    handleShareShow,
  } = useModal();

  const { refreshAdminToken } = useRefreshToken();

  const { userAsperPagination } = useSelector((state) => state?.userList);
  const { adminData } = useSelector((state) => state?.adAuth);
  const { loader } = useSelector((state) => state.app);
  const [detailState, setDetailState] = useState({});
  const [filteredData, setFilteredData] = useState(userAsperPagination?.data);
  let accessToken = adminData?.signInUserSession?.idToken?.jwtToken;
  const [paginationItem, setPaginationItem] = useState({
    page: 1,
    itemsPerPage: 10,
  });
  const [userData, setUserData] = useState(userAsperPagination?.data);
  // @@ useeffect to get the list of the user and the refreshtoken for the admin
  useEffect(() => {
    const body = {
      accessToken: accessToken,
      page_no: paginationItem.page,
      page_size: paginationItem.itemsPerPage,
    };
    refreshAdminToken();
    dispatch(getUsersList(body));
  }, [paginationItem]);

  useEffect(() => {
    setUserData(userAsperPagination?.data);
  }, [userAsperPagination?.data]);

  const viewDetailModal = (data) => {
    setDetailState(data);

    handleShow();
  };

  // handleBilling history function

  const viewBillingHistory = (selectedUserHistory) => {
    navigate(`/admin/billinghistory/${selectedUserHistory?.CustomerID}`);
  };
  function filterFxn(column, onFilter, filterValue) {
    return (
      <input
        type="text"
        onChange={(e) =>
          onFilter(e.target.value, column.selector, e.target.name)
        }
        style={{ width: "100%", padding: "5px" }}
        value={filterValue || ""}
      />
    );
  }

  const columns = [
    {
      name: <div className="showFilter">User Name</div>,
      selector: (row, index) => row.Username,
      sortable: true,
      customFilter: ({ column, onFilter, filterValue }) =>
        filterFxn(column, onFilter, filterValue),
      width: "400px",
    },
    {
      name: <div className="showFilter">Email</div>,
      selector: (row) => row.Email,
      sortable: true,
      customFilter: ({ column, onFilter, filterValue }) =>
        filterFxn(column, onFilter, filterValue),
      width: "300px",
    },
    {
      name: <div className="showFilter">Email Requests</div>,
      selector: (row) => row.EmailsRequested,
      sortable: true,
      customFilter: ({ column, onFilter, filterValue }) =>
        filterFxn(column, onFilter, filterValue),
      width: "200px",
    },
    {
      name: <div className="showFilter">Email Available</div>,
      selector: (row) => row.EmailsAvailable,
      sortable: true,
      customFilter: ({ column, onFilter, filterValue }) =>
        filterFxn(column, onFilter, filterValue),
      width: "200px",
    },
    {
      name: "Subscriptions Plan",
      selector: (row) => (
        <div className="number">
          {row.CurrentPlan === "" ? (
            <span className="no-plan w-75 p-2">No Plan</span>
          ) : (
            <span className="pro-plan w-75 p-2"> {row.CurrentPlan}</span>
          )}
        </div>
      ),
      width: "200px",
    },
    {
      name: "Action",
      selector: (row, index) => (
        <div className="actions_btns">
          <div className="action_btns m-0 w-100">
            <button
              className="action_btn"
              onClick={() => viewBillingHistory(row)}
            >
              <FaMoneyBillTransfer />
            </button>{" "}
            <button
              className="action_btn me-3"
              onClick={() => viewDetailModal(row)}
            >
              <Image src={view} />
            </button>{" "}
            <DropDownField
              value={row.Deleted === true ? "disable" : "enable"}
              data={[
                { label: "Enable", value: "enable" },
                { label: "Disable", value: "disable" },
              ]}
              name={""}
              attr={["dataUpdate", index, row]}
              onChange={setEnableDisableUser}
            />
          </div>
        </div>
      ),
      width: "300px",
    },
  ];
  //** set the function  to disable enable user   **/
  // ** params attr
  // ** params value
  const setEnableDisableUser = (attr, value) => {
    let row = attr[2];
    let data = [...userData];
    data.splice(attr[1], 1, {
      ...data[attr[1]],
      Deleted: value === "disable" ? true : false,
    });
    setUserData([...data]);
    userService(
      row.Deleted === true ? "enable" : "disable",
      row.UserID,
      row.Username
    ); //  api service call
  };
  //** set the function  to disable enable user   **/

  // ** api intigration for the enable and disable the user from the user table
  const userService = (action, userId, userName) => {
    dispatch(showLoader());
    const onSuccess = (res) => {
      if (res?.data.success === true) {
        toast.success(res?.data?.message);
      } else if (res?.data?.success === false) {
        toast.success(res?.data?.message);
      }
      dispatch(hideLoader());
    };
    const onError = (err) => {
      console.log("erropopopo", err);
      dispatch(hideLoader());
    };
    let param = {
      UserID: userId,
      action: action,
      username: userName,
    };
    userMangeMentServices.userMangementEnableDisable(
      accessToken,
      param,
      onSuccess,
      onError,
      {}
    );
  };
  const handleFilter = (text, column, columnIndex) => {
    const filtered = filteredData.filter((item) => {
      if (columnIndex === 2) {
        return new Date(item[column]).toLocaleDateString().includes(text);
      } else {
        return item[column].toLowerCase().includes(text.toLowerCase());
      }
    });
    setFilteredData(filtered);
  };
  const handlePerRowsChange = async (newPerPage, page) => {};
  const handleChangePage = (page, totalRows) => {};
  return (
    <div>
      <CustomModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        modalHead="User Profile"
        className="user_modal"
      >
        <div className="user_details">
          <p>
            <h6 className="mb-0">Username </h6>{" "}
            <span className="bg-primary text-white p-1">
              {detailState.Username}
            </span>{" "}
          </p>
          <p>
            <h6 className="mb-0">Email </h6>{" "}
            <span className="fw-bold bg-success text-white p-1">
              {" "}
              {detailState.Email}
            </span>{" "}
          </p>
          <p>
            <h6 className="mb-0">Emails Requested </h6>{" "}
            <span> {detailState.EmailsRequested}</span>{" "}
          </p>
          <p>
            <h6 className="mb-0">Emails Available </h6>{" "}
            <span className="fw-bold"> {detailState.EmailsAvailable}</span>{" "}
          </p>
        </div>
      </CustomModal>

      <div className="card-sec bg-white">
        <h3>User Management</h3>
        <div className="user-manage-table mt-4">
          {loader ? (
            <Loader />
          ) : userData && userData?.length > 0 ? (
            <ReactDataTable
              data={[...userData]}
              total_records={userAsperPagination?.total_data_length_}
              isHaveNextPage={userAsperPagination?.next_page}
              columns={columns}
              onFilter={handleFilter}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handleChangePage}
              setPaginationItem={setPaginationItem}
              paginationItem={paginationItem}
            />
          ) : (
            <span>no record found</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
