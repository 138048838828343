import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CSVLink } from "react-csv";
import { Loader } from "../../components/common";
import {
  getAccountsByCategory,
  getProgramCategoriesList,
} from "../../redux/thunk/user/usrCategories";
import { getUserDetail } from "../../redux/thunk/user/usrUsers";
import "../../styles/admin/categories.scss";
import { useRefreshToken } from "../../utility/hooks";
import "./autocomplete.css";
import Joyride, { ACTIONS, EVENTS, BeaconRenderProps } from "react-joyride";
import { userLeadsteps } from "../../shared/joyRideArray";
import { leadSchema } from "../../shared/schema/user/userLeadFormSchema";
import { localStorageConstant } from "../../shared/constants";
import { useNavigate } from "react-router";

function Lead() {
  //Redux state
  const userAuthtoken = useSelector(
    (state) => state?.userAuth?.userData?.signInUserSession?.idToken?.jwtToken
  );
  const [hide, setHide] = useState(false);
  const { programsCategories, accountsByCategory } = useSelector(
    (state) => state.userCategoriesSpeakers
  );
  const userID = useSelector(
    (state) => state?.userAuth?.userData?.attributes?.sub
  );
  const navigate = useNavigate();
  const { userDataFromDB } = useSelector((state) => state?.userList);
  let { loader } = useSelector((state) => state?.app);
  const { userData } = useSelector((state) => state?.userAuth);
  const [dwnldFilename, setDwnldFilename] = useState("");
  const dispatch = useDispatch();
  const { refreshUserToken } = useRefreshToken();
  const skipTour = localStorage.getItem(localStorageConstant.skipTour);
  const handleSkip = (data) => {
    const { action, type } = data;
    if (type === EVENTS.TOUR_END && action === ACTIONS.SKIP) {
      // Handle skip event
      localStorage.setItem(localStorageConstant.skipTour, "true");
    }
  };
  useEffect(() => {
    refreshUserToken();
    dispatch(getProgramCategoriesList()).then((res) => {
      // formik.setValues({
      //            ...formik.values,
      //            Categories: programsCategories?.data[0],
      //        });
      // formik.setFieldValue("Categories", programsCategories?.data[0]);
    });
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    let sortedCategory = values.Categories.replace("_", "");
    const categoryData = {
      category: sortedCategory,
      LeadRequested: values.Leads,
      userID: userID,
    };
    const data = {
      userAuthtoken,
      values: {
        ...categoryData,
      },
    };
    setDwnldFilename(`${values.Categories}-${values.Leads}`);
    let data_ = {
      sub: userData.attributes.sub,
    };
    dispatch(getAccountsByCategory(data))
      .then((res) => {
        if (res?.payload?.data?.length > 0) {
          setHide(true);
          resetForm(); // inbuild function to reset the form of formik
        }

        if (res.payload === "Request failed with status code 504") {
          return toast.error(
            "We are working on your lead request. check your download center"
          );
        }
        dispatch(getUserDetail(data_));
        navigate("/user/history");
      })
      .catch((err) => console.log(err));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Leads: "",
      Categories: "",
      // InfulencerName: "",
    },
    validationSchema: leadSchema,
    onSubmit: handleSubmit,
  });

  const hideDownloadLink = (value) => {
    setHide(false);
  };

  return (
    <>
      {/* this reactjoyride it is thrid party library which is intigrated and give the tour of the page */}
      {userDataFromDB != undefined &&
      userDataFromDB?.EmailsRequested === 0 &&
      skipTour != "true" &&
      loader === false ? (
        <Joyride
          callback={handleSkip}
          steps={userLeadsteps}
          continuous={true}
          showSkipButton
          locale={{ skip: "Don't show again", last: "Done" }}
        />
      ) : null}
      <div className="categories_section">
        <h3 className="title">Leads by Category</h3>
        {loader ? (
          <Loader />
        ) : (
          <>
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              {programsCategories?.data?.length > 0 && (
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="input_label_wrap">
                      <label>Categories</label>
                      <select
                        value={formik.values.Categories}
                        id="categories"
                        onChange={(e) => {
                          formik.setFieldValue("Categories", e.target.value);
                          hideDownloadLink(e.target.value);
                        }}
                        className=" form-control"
                        label="date"
                      >
                        <option value="">Category</option>
                        {programsCategories?.data?.map((data, index) => {
                          return (
                            <option key={index} value={data}>
                              {data}
                            </option>
                          );
                        })}
                      </select>

                      <small className="text-danger">
                        {formik.touched.Categories && formik.errors.Categories
                          ? formik.errors.Categories
                          : null}
                      </small>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="input_label_wrap">
                    <label>Lead Count</label>
                    <input
                      type="text"
                      id="enterLead"
                      placeholder="1-5000"
                      value={formik.values.Leads}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "Leads",
                          e.target.value.replace(/\D/g, "")
                        );
                        hideDownloadLink(e.target.value);
                      }}
                    />
                    <small className="text-danger">
                      {formik.touched.Leads && formik.errors.Leads
                        ? formik.errors.Leads
                        : null}
                    </small>
                  </div>
                </div>
              </div>

              <div className="common_btns lead_mobile_res_button">
                {!hide ? (
                  <button
                    style={{ marginRight: "50px" }}
                    type="submit"
                    disabled={loader}
                    className="primary_btn"
                  >
                    {"Submit"}
                  </button>
                ) : null}
              </div>
            </form>
            {/* {hide && accountsByCategory?.data?.length > 0 && ( */}
            {/* // <CSVLink
              //   style={{
              //     backgroundColor: "#3789d1",
              //     padding: "14px",
              //     borderRadius: "10px",
              //     border: "none",
              //     textDecoration: "none",
              //     fontWeight: "600",
              //     color: "white",
              //   }}
              //   type="button"
              //   data={accountsByCategory?.data}
              //   filename={dwnldFilename}
              //   onClick={() => navigate("/user/history")}
              // >
              //   Check Your Csv file in Download Center
              // </CSVLink>
            // )} */}
          </>
        )}
      </div>
    </>
  );
}

export default Lead;
