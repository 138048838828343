import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAdminUser,
  filterAdminUserbyDate,
  getAdminUserList,
  searchAdminUserList,
} from "../../redux/thunk/admin/adUser";
// import { ConfirmPopUp, ReactDataTable } from "../../components/common";
import { Image } from "react-bootstrap";
import { demopic } from "../../assets/images/admin";
import { dateFormater } from "../../utility/methods";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigation/constants";
import { trash, view } from "../../assets/icons/admin";
import {
  useSearch,
  useDateFilter,
  useConfirmation,
  usePagination,
  useFetch,
  useRefreshToken,
} from "../../utility/hooks";
import { totalItems, itemsPerPage } from "../../utility/methods";
import "../../styles/admin/user.scss";
import {
  getUserHistory,
  getUserHistoryById,
} from "../../redux/thunk/user/usrUsers";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Loader, ReactDataTable } from "../../components/common";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

function History() {
  const [paginationItem, setPaginationItem] = useState({
    page: 1,
    itemsPerPage: 10,
  });
  const excelRef = useRef();
  const dispatch = useDispatch();
  const userAuthtoken = useSelector(
    (state) => state.userAuth.userData.signInUserSession.idToken.jwtToken
  );
  const userID = useSelector((state) => state.userAuth.userData.attributes.sub);
  const { loader } = useSelector((state) => state.app);
  const dataHistory = useSelector((state) => state.userHistory.userHistory);
  // //Datatable columns
  const [userHistoryData, setUserHistoryData] = useState(dataHistory);
  const { refreshUserToken } = useRefreshToken();
  const [dataPerUser, setDataPerUser] = useState({
    id: "",
    data: [],
    status: "",
    filename: "",
  });

  function filterFxn(column, onFilter, filterValue) {
    return (
      <input
        className="newFil"
        type="date"
        onChange={(e) =>
          onFilter(e.target.value, column.selector, e.target.name)
        }
        style={{ width: "100%", padding: "5px" }}
        value={filterValue || ""}
      />
    );
  }

  const columns = [
    {
      name: "Category",
      selector: (row, index) => (
        <div className="user_name">
          <span className="name">
            {row.Category ? row.Category : "No Category"}
          </span>
        </div>
      ),
    },
    {
      name: <div className="showFilter">Leads</div>,
      selector: (row) => row.Leads,
      sortable: true,
      format: (row) => row.Leads,
      customFilter: ({ column, onFilter, filterValue }) =>
        filterFxn(column, onFilter, filterValue),
    },
    {
      name: <div className="showFilter">Date</div>,
      selector: (row) => row.Date,
      sortable: true,
      format: (row) => moment.utc(row.Date).format("MM/DD/YYYY hh:mm:ss A"),
      customFilter: ({ column, onFilter, filterValue }) =>
        filterFxn(column, onFilter, filterValue),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="actions_btns">
          {row && row?.Status === true ? (
            <>
              <button
                style={{
                  backgroundColor: "#3789d1",
                  padding: "10px 13px",
                  borderRadius: "10px",
                  width: "124px",
                  border: "none",
                  textDecoration: "none",
                  fontWeight: "600",
                  color: "white",
                  display: "block",
                  textAlign: "center",
                }}
                onClick={() => downloadCsvFile(row)}
              >
                {dataPerUser.id === row.ID ? "Downloading" : "Download"}
              </button>

              <CSVLink
                data={dataPerUser?.data}
                filename={dataPerUser.filename}
                ref={excelRef}
              ></CSVLink>
            </>
          ) : (
            <div
              style={{
                backgroundColor: "rgb(249 16 16 / 81%)",
                padding: "10px 10px",
                borderRadius: "10px",
                border: "none",
                width: "124px",
                textDecoration: "none",
                fontWeight: "600",
                color: "white",
                display: "block",
                textAlign: "center",
              }}
            >
              Request Failed
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    refreshUserToken();
    const data = {
      userAuthtoken,
      user_id: userID,
      page_no: paginationItem.page,
      page_size: paginationItem.itemsPerPage,
    };
    dispatch(getUserHistory(data)).then((res) => {
      if (res.payload.success === true) {
        setUserHistoryData(res.payload);
      }
    });
  }, [paginationItem]);

  // this useEffect we have call because we have to set the data onclick of a function
  useEffect(() => {
    if (dataPerUser?.data?.length > 0 && excelRef.current) {
      setTimeout(() => {
        excelRef?.current?.link?.click();
        setDataPerUser({
          id: "",
          data: [],
          status: "",
          filename: "",
        });
      }, 300);
    }
  }, [dataPerUser?.data]);
  const handleFilter = (text, column, columnIndex) => {
    const filtered = userHistoryData.filter((item) => {
      if (columnIndex === 2) {
        return new Date(item[column]).toLocaleDateString().includes(text);
      } else {
        return item[column].toLowerCase().includes(text.toLowerCase());
      }
    });
    setUserHistoryData(filtered);
  };

  const downloadCsvFile = (row) => {
    setDataPerUser({
      ...dataPerUser,
      id: row.ID,
    });
    let key = `${row.ID}.csv`;
    const Data = {
      KEY: key,
    };
    const data = {
      userAuthtoken,
      values: {
        ...Data,
      },
    };
    dispatch(getUserHistoryById(data)).then((res) => {
      if (res.payload.success === true) {
        let ourArr = res?.payload?.data?.map((data) => [data]);
        setDataPerUser({
          id: row.Category,
          data: ourArr,
          status: "Download",
          filename: `${row.Category}_${ourArr.length}`,
        });
      } else {
        setDataPerUser({
          id: "",
          data: [],
          status: "",
          filename: "",
        });
        if (res.payload.message === undefined) {
          toast.error("Download Error...");
        } else {
          toast.error(res.payload.message);
        }
      }
    });
  };

  return (
    <div>
      <div className="card-sec bg-white p-3">
        <h3>History </h3>
        <div className="user-manage-table admin_table mt-4">
          {loader ? (
            <Loader />
          ) : (
            <ReactDataTable
              data={userHistoryData?.data}
              columns={columns}
              header="History"
              onFilter={handleFilter}
              paginationItem={paginationItem}
              setPaginationItem={setPaginationItem}
              total_records={userHistoryData?.total_data_length_}
              isHaveNextPage={userHistoryData?.next_page}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default History;
