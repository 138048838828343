import moment from "moment";

// *** global function for the validation   ***///
export function globalValidationFunction() {
  // ***  function for the validation of the user email   ***///
  function validateEmail(value) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  }
  return {
    validateEmail: validateEmail,
  };
}

// global regex function

export function globalRegex() {
  // onchange only type number
  function onChangeToNumber(value) {
    return value.replace(/[^\d.]/g, "");
  }

  return {
    onChangeToNumber: onChangeToNumber,
  };
}

// time format function only
// export function momentTimeFormat(value) {
//   const formatValue = moment.unix(value).format("hh:mm A");
//   return formatValue;
// }

export function momentTimeFormat(timestamp) {
  timestamp = Number(timestamp);
  // Validate timestamp
  if (isNaN(timestamp) || timestamp < 0) {
    return ""; // Return empty string or handle invalid timestamp case
  }
  const date = new Date(timestamp);
  // Check if date is valid
  if (isNaN(date.getTime())) {
    return ""; // Handle invalid date gracefully
  }
  // Format time according to the specified timezone (GMT)
  const formattedTime = date.toLocaleString("en-US", {
    timeZone: "GMT",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return formattedTime;
}

// number change to comma format
export function numberToCommaFormat(value) {
  const formatValue = value?.toLocaleString();
  return formatValue;
}

// curreency change to usd dollar's
export function globalCurrencyFormat() {
  const currencyFormatToUSD = (value) => {
    let UsdDollar = value?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return UsdDollar;
  };
  return {
    currencyFormatToUSD: currencyFormatToUSD,
  };
}
// function for get day with time
export function formatDateWithDayDateTime(dateString) {
  const date = new Date(dateString);

  // Convert to a readable format
  return date.toLocaleString("en-US", {
    weekday: "long", // Day of the week
    year: "numeric", // Year
    month: "long", // Month (full name)
    day: "numeric", // Day of the month
    hour: "2-digit", // Hours
    minute: "2-digit", // Minutes
    second: "2-digit", // Seconds
    hour12: false, // 24-hour format
  });
}
