import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  supportChatModalMessage: "",
  supoortChatMessages: [],
};
const userSupportChat = createSlice({
  name: "userSupportChat",
  initialState: initialState,
  reducers: {
    setMessageToStore(state, action) {
      let data = { ...state };
      data = {
        ...data,
        supportChatModalMessage: action.payload,
      };
      return data;
    },
  },
});
export const { manageModalStateMessage, setMessageToStore } =
  userSupportChat.actions;
export default userSupportChat.reducer;
