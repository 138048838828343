import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { burger } from "../../assets/images/admin";
import "../../styles/admin/header.scss";
const Header = ({ show, setShow }) => {
  // const { adminNotification: { data: notification_data }, } = useSelector((state) => state.adNotification);

  const adminData = useSelector((state) => state.adAuth.adminData);

  return (
    <div className="header_admin">
      <div className="title_block">
        <h3 className="text-uppercase" style={{ color: "#757575" }}>
          Welcome, {adminData?.username}{" "}
        </h3>
        {/* <p>Ready to jump back in?</p> */}
      </div>
      <div className="right_notifications">
        {/* <div className="notification_message">
          <Image src={notification} />
          <span className="messages">{notification_data?.length}</span>
        </div> */}

        {/* <div className="my_account_drop_down">
          <DropdownButton id="dropdown-basic-button" title="My Account">
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </div> */}

        {/* <div style={{ marginLeft: "100px", cursor: "pointer" }} onClick={() => { navigate("/admin/edit-user-profile") }}>
            <Image src={user} /> <b> Edit Profile</b>
        </div> */}

        <div
          className="mobile_burger d-block d-md-none"
          onClick={() => setShow(!show)}
        >
          <Image src={burger} className="toggle_burger " />
        </div>
      </div>
    </div>
  );
};

export default Header;
