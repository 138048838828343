import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";

import {
  dashboard_payment,
  dashboarduser,
  videos,
} from "../../assets/icons/admin";
import "../../styles/admin/dashboard.scss";
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { useRefreshToken } from "../../utility/hooks";
import { getUserDetail } from "../../redux/thunk/user/usrUsers";
import { numberToCommaFormat } from "../../shared/helperFunction";

function UserDashboard() {
  const dispatch = useDispatch();
  //   const { adminAuthtoken } = useSelector((state) => state.adAuth);
  const userDataa = useSelector((state) => state.userAuth.userData);
  const gg = useSelector((state) => state.userList.userDataFromDB);

  const { refreshUserToken } = useRefreshToken();
  let data = {
    sub: userDataa?.attributes?.sub,
  };

  useEffect(() => {
    dispatch(getUserDetail(data));
  }, []);
  useEffect(() => {
    refreshUserToken();
  }, []);

  return (
    <>
      <h3 className="title ms-3">This Month</h3>
      <div className="top_cards container">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <div className="cards_dashboard videos">
              <span className="icons">
                <Image src={videos} />
              </span>
              <div className="right_text">
                <span className="number">
                  {numberToCommaFormat(gg?.EmailsRequested)}
                </span>
                <p className="text_card">Leads Pulled </p>
              </div>
            </div>
          </Col>
          <Col md={12} lg={12} xl={4}>
            <div className="cards_dashboard users">
              <span className="icons">
                <Image src={dashboarduser} />
              </span>
              <div className="right_text">
                <span className="number">
                  {numberToCommaFormat(gg?.EmailsAvailable)}
                </span>
                <p className="text_card">Remaining Leads</p>
              </div>
            </div>
          </Col>
          <Col md={12} lg={12} xl={4}>
            <div className="cards_dashboard payments">
              <span className="icons">
                <Image src={dashboard_payment} />
              </span>
              <div className="right_text">
                <span className="number">
                  {numberToCommaFormat(gg?.InfluencerSearched?.length)}
                </span>
                <p className="text_card">Influencers Searched</p>
              </div>
            </div>
          </Col>
          {/* <Col md={3}>
            <div className="cards_dashboard categories">
              <span className="icons">
                <Image src={dashboard_categories} />
              </span>
              <div className="right_text">
                <span className="number">{data?.categories_count}</span>
                <p className="text_card">Categories</p>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>

      {/* <div className="latest_uploads_section">
        <Row>
          <Col md={7}>
            <div className="latest_uploads_block">
              <h3 className="title">Latest Uploads</h3>
              <div className="card_uploads">
                <div className="left_video_block">
                  <div className="img_block">
                    <Image src={video_card} />
                    <span className="available">Available</span>
                  </div>
                  <div className="text_block">
                    <h3>Basic: how to ride your skateboard comfortly</h3>
                    <p>
                      Andy William <b>Free</b>
                    </p>
                  </div>
                </div>
                <div className="right_action_icons_block">
                  <button className="action_btn">
                    <Image src={view} />
                  </button>
                  <button className="action_btn">
                    <Image src={trash} />
                  </button>
                </div>
              </div>
              <div className="card_uploads">
                <div className="left_video_block">
                  <div className="img_block">
                    <Image src={video_card} />
                    <span className="available">Available</span>
                  </div>
                  <div className="text_block">
                    <h3>Basic: how to ride your skateboard comfortly</h3>
                    <p>
                      Andy William <b>Free</b>
                    </p>
                  </div>
                </div>
                <div className="right_action_icons_block">
                  <button className="action_btn">
                    <Image src={view} />
                  </button>
                  <button className="action_btn">
                    <Image src={trash} />
                  </button>
                </div>
              </div>
              <div className="card_uploads">
                <div className="left_video_block">
                  <div className="img_block">
                    <Image src={video_card} />
                    <span className="available">Available</span>
                  </div>
                  <div className="text_block">
                    <h3>Basic: how to ride your skateboard comfortly</h3>
                    <p>
                      Andy William <b>Free</b>
                    </p>
                  </div>
                </div>
                <div className="right_action_icons_block">
                  <button className="action_btn">
                    <Image src={view} />
                  </button>
                  <button className="action_btn">
                    <Image src={trash} />
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="notification_block">
              <h3 className="title">Notifications</h3>
              <ul className="notification_list">
                <li>
                  <b>Wade Warren</b> created a new account.
                </li>
                <li>
                  <b>Wade Warren</b> created a new account.
                </li>
                <li>
                  <b>Wade Warren</b> created a new account.
                </li>
                <li>
                  <b>Wade Warren</b> created a new account.
                </li>
                <li>
                  <b>Wade Warren</b> created a new account.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      <div className="user_section">
        <h3 className="title">Recent Users</h3>
        <TableUser />
      </div> */}
    </>
  );
}

export default UserDashboard;
