import { GETAPI, POSTAPI, PUTAPI } from "../../globalServicesMethod";

const adminChatSideBarUserListing = (token, onSuccess, onError, config) => {
  GETAPI(
    "https://ld111087yg.execute-api.us-east-2.amazonaws.com/dev",
    token,
    onSuccess,
    onError,
    config
  );
};

const adminSendMessage = (token, body, onSuccess, onError, config) => {
  POSTAPI(
    token,
    "https://ld111087yg.execute-api.us-east-2.amazonaws.com/dev/sendMsgAdmin",
    body,
    onSuccess,
    onError,
    config
  );
};
const getAdminMessage = (token, body, onSuccess, onError, config) => {
  POSTAPI(
    token,
    "https://ld111087yg.execute-api.us-east-2.amazonaws.com/dev/ChatByID",
    body,
    onSuccess,
    onError,
    config
  );
};

export const adminChatServices = {
  adminChatSideBarUserListing,
  adminSendMessage,
  getAdminMessage
};
