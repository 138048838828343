import { createSlice } from "@reduxjs/toolkit";
import { adminLogin } from "../../thunk/admin/adAuth";

const initialState = {
  adminData: null,
  superAdminData: {}
};

const adminAuthSlice = createSlice({
  name: "adminAuthSlice",
  initialState: initialState,
  reducers: {
    adminLogout: (state, action) => {
      state.adminData = null;
    },
    adminData: (state, action) => {
      state.adminData = action.payload;
    },
    superAdminData: (state,action)=>{
      let data = {...state}
      data = {
        ...data,
        superAdminData: action.payload
      }
      return data
    }
  },
});

export const { saveAdminData } = adminAuthSlice.actions;

export const { adminLogout, adminData ,superAdminData} = adminAuthSlice.actions;
export default adminAuthSlice.reducer;
