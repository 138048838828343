const useCropper = () => {
  const updateCroppedImg = (croppedImage) => {
    // setFormData((prev) => {
    //   return {
    //     ...prev,
    //     croppedImage: croppedImage,
    //   };
    // });
    // setShowCropper(false);
  };

  const cancelCrop = () => {
    // setShowCropper(false);
    // setFormData((prev) => {
    //   return {
    //     ...prev,
    //     file: null,
    //   };
    // });
  };
  return {
    updateCroppedImg,
    cancelCrop,
  };
};

export default useCropper;
