import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Button } from "../../components/user";
import {
  UserIcon,
  CameraIcon,
  EyeLock,
  DownArrow,
} from "../../assets/icons/user";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  getMyAccount,
  userEditProfile,
} from "../../redux/thunk/user/usrProfile";
import { FlagIcon } from "react-flag-kit";
import Select from "react-select";
import CountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import { useLocation, useNavigate, useParams } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { CustomModal, ImageCropper, Input } from "../../components/common";
import { nameRefExp, passwordRefExp, phoneRegExp } from "../../utility/regax";
import { useCropper, useModal, useRefreshToken } from "../../utility/hooks";

import { ROUTES } from "../../navigation/constants";
import Swal from "sweetalert2"; // Import SweetAlert
import { userLogin } from "../../redux/thunk/user/usrMain";

import { CountryOptions } from "../../utility/CountryList";
import { FaAddressCard } from "react-icons/fa";
import {
  hideRootLoader,
  showRootLoader,
} from "../../redux/reducers/common/appSlice";
import axios from "axios";
import axiosInstance from "../../services/axiosInstance";
import { getUserCardService } from "../../services/user/saveCardDetails/saveCardDetails";
import ConfirmationModal from "../../components/user/ConfirmationModal";

const UserProfile = () => {
  // state for confirmation of update card number

  const [confirmUpdateCardNumber, setConfirmUpdateCardNumber] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [imageFile, setImageFile] = useState(null);

  //Redux state
  const [Initialvalues, setInitialvalues] = useState(false);
  const userAuthcardtoken = useSelector(
    (state) => state?.userAuth?.userData?.signInUserSession?.idToken?.jwtToken
  );
  const { userAuthtoken } = useSelector((state) => state.userAuth);
  const { userDetails } = useSelector((state) => state.userProfile);
  //Redux action dispatcher
  const dispatch = useDispatch();

  //Custom hooks
  const { show, handleClose, handleShow } = useModal();
  const { updateCroppedImg, cancelCrop } = useCropper();
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const countries = countryList().getData();
  const fullNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
  // to refresh the token
  const { refreshUserToken } = useRefreshToken();
  useEffect(() => {
    refreshUserToken(); // refresh the jwt token on based of refresh token
  }, []);
  const handleCrop = () => {
    if (cropperRef.current) {
      // Get the cropped image data
      const croppedDataUrl = cropperRef.current.getCroppedCanvas().toDataURL();

      // Update the state with the cropped image data
      setCroppedImage(croppedDataUrl);

      // Call the parent component's function to handle the cropped image
      updateCroppedImg(croppedDataUrl);
    }
  };

  // country selebox options
  // const CountryOptions = [
  //   { value: "India", label: "India" },
  //   { value: "Italy", label: "Italy" },
  //   { value: "USA", label: "USA" },
  //   { value: "China", label: "China" },
  //   { value: "Canada", label: "Canada" },
  //   { value: "UK", label: "UK" },
  // ];

  const userData = useSelector((state) => state.userList);

  //Formik initial values
  const initValues = {
    full_name: userData?.userDataFromDB?.Username ?? "",
    email: userData?.userDataFromDB?.Email ?? "",
    // phone: userDetails?.phone ?? "",
    // //password: userDetails?.password ?? "",
    // country_code: userDetails?.country_code ?? "",

    // address:userDetails?.address ?? "",
    // profile_pic_ : userDetails?.profile_pic,

    // profile_pic: null,
  };

  //Formin validation schema
  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .matches(nameRefExp, "*Name can only contain Latin letters.")
      .test("full_name", "Please enter both first and last names", (value) => {
        // Check if both first and last names are present
        const names = value.split(" ");
        return names.length === 2 && names.every((name) => name.trim() !== "");
      })
      .max(50)
      .required("*Enter Your Full Name"),
    email: Yup.string().email().required("*Enter your E-mail"),
    phone: Yup.string()
      .matches(phoneRegExp, "*Enter a valid Phone Number")
      .max(13)

      .required("*Enter a valid Phone Number"),

    // password: Yup.string()
    //   .required("required field")
    //   .matches(
    //     passwordRefExp,
    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    //   ),
    // address: Yup.string().required("must select country"),
  });
  const { usrEditProfile, usrOtp } = ROUTES;
  const navigate = useNavigate();
  //Methods
  const showNumberVerificationAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Updation successful",
      icon: "success",
      confirmButtonText: "Done",
    }).then(() => {
      // Navigate to the desired page after the alert is closed
      navigate(usrEditProfile);
    });
  };
  const showUnsuccessfulAlert = () => {
    Swal.fire({
      title: "Error",
      text: "Number verification unsuccessful. Please try again.",
      icon: "error",
      confirmButtonText: "redirect to otp page ",
    }).then(() => {
      navigate(usrOtp);
    });
  };

  const handleCountryChange = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
  };

  const onSubmitHandler = (values) => {
    // const { full_name , email , phone } = values;
    // if(values.phone === userDetails.phone){
    //   delete values.phone
    // }
    // const data = {
    //   userAuthtoken,
    //   values: {
    //     profile_pic: imageFile,
    //     full_name: userDetails?.full_name,
    //     otp: +values.otp,
    //   },
    //   // values: {
    //   //   profile_pic : imageFile,
    //   //   full_name: userDetails?.full_name,
    //   // },
    //   values : values
    //   // values: {
    //   //   profile_pic : imageFile
    //   // },
    // };
    // dispatch(userEditProfile(data)).then(({ payload }) => {
    //   if (payload.status) {
    //     if (payload.verified) {
    //       // Show a success alert
    //       showNumberVerificationAlert();
    //     } else {
    //       // Show an error alert for unsuccessful verification
    //       showUnsuccessfulAlert();
    //     }
    //   }
    // });
  };

  // const handleImageChange = (e, setFieldValue) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     handleShow();
  //   }
  //   setFieldValue("file", file);
  // };
  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Update the Formik values with the selected image
        setFieldValue("profile_pic", reader.result);
      };

      reader.readAsDataURL(file);
    }

    setImageFile(file);
    setFieldValue("profile_pic", file);
    // setFieldValue("profile_pic_", URL.createObjectURL(file));
  };

  // fucntion for hit checkout url

  const updateCheckOutUrl = async () => {
    setConfirmUpdateCardNumber(false);
    try {
      dispatch(showRootLoader());

      // Removing the return statement to allow further execution
      // return;

      const onSuccess = (res) => {
        if (res.status === 200) {
          window.location.href = res?.data?.checkout_url;
        }
        dispatch(hideRootLoader());
      };

      const onError = (err) => {
        console.log("Error:", err);
        dispatch(hideRootLoader());
      };

      const body = {
        customerId: userData?.userDataFromDB?.CustomerID,
      };

      getUserCardService.updateCheckCardUrl(
        userAuthcardtoken,
        body,
        onSuccess,
        onError,
        {}
      );
    } catch (error) {
      console.log(error);
      dispatch(hideRootLoader());
    }
  };

  const confirmupdatemodal = (data) => {
    setConfirmUpdateCardNumber(true);
  };

  return (
    <>
      <section className="auth categories_section card-sec bg-white">
        <div className="auth__inner ">
          <h3 className="title">User Profile</h3>
          <Formik
            enableReinitialize={true}
            initialValues={initValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmitHandler}
          >
            {({
              values,
              isSubmitting,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <div className="inputRow">
                      {/* <div className="edirofileUser">
                        <input
                          id="editUser"
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageChange(e, setFieldValue)}
                        />
                        <label for="editUser">
                          <div className="editUser__figure">
                            {values.profile_pic_ ? (
                              <Image
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "100%",
                                }}
                                src={values.profile_pic_}
                                alt="user_pro"
                              />
                            ) : (
                              <UserIcon role="img" />
                            )}
                          </div>
                          <div className="editUser__icon">
                            <CameraIcon onClick={handleShow} />
                          </div>
                        </label>
                      </div> */}
                    </div>
                    <CustomModal
                      show={show}
                      handleClose={handleClose}
                      modalHead="Image cropper"
                    >
                      <ImageCropper
                        updateCroppedImg={updateCroppedImg}
                        image={values.profile_pic}
                        file={values.file}
                        cancelCrop={cancelCrop}
                      />
                    </CustomModal>
                  </Col>

                  {/* <Col md={12}>
                    <div className="inputRow">
                      <input
                        name="full_name"
                        placeholder="Name"
                        type="text"
                        value={values.full_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {errors.full_name &&
                          touched.full_name &&
                          errors.full_name}
                      </span>
                    </div>
                  </Col> */}
                  <Col md={12}>
                    <div className="inputRow">
                      {/* <label>Name: </label> */}

                      {/* <b className="m-3">{values.full_name}</b> */}

                      {/* <input
                        disabled
                        name="full_name"
                        placeholder="Name"
                        type="text"
                        value={values.full_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={
                          touched.full_name &&
                          (!fullNameRegex.test(values.full_name) ||
                            !/^[a-zA-Z]+(?: [a-zA-Z]+)*\s+[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(
                              values.full_name
                            )) &&
                          "invalid-input"
                        }
                      /> */}
                      {/* <span style={{ color: "red" }}>
                        {touched.full_name &&
                          (!fullNameRegex.test(values.full_name) ||
                            !/^[a-zA-Z]+(?: [a-zA-Z]+)*\s+[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(
                              values.full_name
                            )) &&
                          "Invalid full name"}
                      </span> */}
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="inputRow">
                      <label className="w-50">Email </label>
                      <b className="m-3">{values.email}</b>
                      {/* <input
                        disabled
                        name="email"
                        placeholder="E-mail"
                        type="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {errors.email && touched.email && errors.email}
                      </span> */}
                    </div>
                  </Col>

                  <Col md={12} className="d-flex">
                    {/* <div className="inputRow">
                      <input
                        name="phone"
                        placeholder="Phone Number"
                        type="text"
                        value={values.phone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {errors.phone && touched.phone && errors.phone}
                      </span>
                    </div> */}

                    <div className="inputRow ">
                      <label className="w-50">Emails Available </label>
                      <b className="m-3">
                        {userData?.userDataFromDB?.EmailsAvailable}
                      </b>
                    </div>
                  </Col>
                  <Col className="d-flex">
                    <div className="inputRow ">
                      <label className="w-50">Emails Requested </label>
                      <b className="m-3">
                        {userData?.userDataFromDB?.EmailsRequested}
                      </b>
                    </div>
                  </Col>
                  {/* <Col md={12}>
                    <div className="inputRow">
                      <input
                        name="address"
                        placeholder="Address"
                        type="text"
                        value={values.address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>
                        {errors.address && touched.address && errors.address}
                      </span>
                    </div>
                  </Col> */}
                  <Col md={12}>
                    {userData?.userDataFromDB?.CurrentPlan && (
                      <div className="inputRow">
                        <label className="w-50">Current Plan </label>
                        <b className="m-3">
                          {userData?.userDataFromDB?.CurrentPlan}
                        </b>
                      </div>
                    )}
                    <hr />
                    {userData?.userDataFromDB?.NextPlan && (
                      <div className="inputRow w-50">
                        <label className="w-50">Next Plan </label>
                        <b className="m-3">
                          {userData?.userDataFromDB?.NextPlan}
                        </b>
                      </div>
                    )}
                    <div className="linked_plan">
                      <div className="d-flex align-items-center">
                        <div className="mt-2 w-50">Linked Credit Card</div>
                        <div className="d-flex w-50 align-items-center justify-content-between ps-3">
                          {userData?.userDataFromDB?.card_brand && (
                            <span className="fw-bold text-capitalize">
                              {userData?.userDataFromDB?.card_brand}
                            </span>
                          )}
                          {userData?.userDataFromDB && (
                            <p className="mb-0 mt-1 ms-3">
                              **** **** **** {userData?.userDataFromDB?.last4}
                            </p>
                          )}
                          {userData?.userDataFromDB && (
                            <button
                              style={{ backgroundColor: "#2680cd" }}
                              className="btn text-white ms-2"
                              // onClick={() => setModalForCardUpdate(true)}
                              onClick={() =>
                                // updateCheckOutUrl(userData?.userDataFromDB)
                                confirmupdatemodal(userData?.userDataFromDB)
                              }
                            >
                              Update Card
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="inputRow">
                      <div className="inputRow__icon">
                        <select
                          name="address"
                          onBlur={handleBlur}
                          value={values.address} // Set the selected value
                          onChange={(e) => {
                            handleCountryChange(e.target.value);
                            handleChange("address")(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select a country
                          </option>
                          {CountryOptions.map((country) => (
                            <option key={country.value} value={country.value}>
                              <FlagIcon countryCode={country.value} />
                              {country.label}
                            </option>
                          ))}
                        </select>

                        <span className="inputRow__iconGroup">
                          <DownArrow />
                        </span>

                        Error message for country code
                        <span style={{ color: "red" }}>
                          {errors.address && touched.address && errors.address}
                        </span>
                      </div>
                    </div> */}
                  </Col>

                  {/* <Col md={12}>
      <div className="inputRow">
        <div className="inputRow__icon">
          <Select
            id="countrySelect"
            name="address"
            value={values.address}  // Set the selected value

            onBlur={handleBlur}
            options={countries}
            onChange={(selectedOption) => {
              // handleCountryChange and handleChange should be updating the state appropriately
              handleCountryChange(selectedOption.value);
              handleChange('address')(selectedOption.value);

              // Log the selected country code to check if it's correct
              const countryCode = selectedOption.value;
            }}
            components={{
              Option: ({ innerProps, label, data }) => (
                <div {...innerProps}>
                  <CountryFlag countryCode={data.value} style={{ marginRight: '8px' }} svg />
                  {label}
                </div>
              ),
            }}
          />

          <span style={{ color: 'red' }}>
            {errors.address && touched.address && errors.address}
          </span>
        </div>
      </div>
    </Col> */}

                  {/* <Col md={12}>
                    <Input
                      className="inputRow"
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        errors.address &&
                        touched.address &&
                        errors.address
                      }
                    />
                  </Col> */}
                  {/* <Col md="12" className="common_btns">
                    <button
                      type="submit"
                      disabled
                      title={"Save"}
                      className="primary_btn"
                      // submit={submit}
                    >
                      Save
                    </button>
                  </Col> */}
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </section>
      {confirmUpdateCardNumber && (
        <ConfirmationModal
          show={confirmUpdateCardNumber}
          title="checkouturlMessage"
          confirm={updateCheckOutUrl}
          hide={() => setConfirmUpdateCardNumber(false)}
          message="Are you sure you want to update this card number ?"
        />
      )}
    </>
  );
};

export default UserProfile;
