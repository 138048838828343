import { POSTAPI, PUTAPI } from "../../globalServicesMethod";

const cancelPlan = (token, body, onSuccess, onError, config) => {
    POSTAPI(
    token,
    "https://8ycjf4c9i4.execute-api.us-east-2.amazonaws.com/dev/cancelSubscription",
    body,
    onSuccess,
    onError,
    config
  );
};

export const userSubscriptionServices = {
  cancelPlan,
};
