import React, { useState } from "react";
import { Input } from "../../components/common";
import { globalValidationFunction } from "../../shared/helperFunction";
import { createSubAdminService } from "../../services/admin/createSubAdmin/createSubAdminService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function CreateSubAdmin() {
  const token = useSelector(
    (state) => state?.adAuth.adminData.signInUserSession.idToken.jwtToken
  );
  const [formData, setFormData] = useState({
    email: { value: "", warning: false },
    userName: { value: "", warning: false },
  });
  const [loader, setLoader] = useState(false);
  const onChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: { value: e.target.value, warning: false },
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let data = { ...formData };
    data = {
      email: {
        value: data.email.value,
        warning: globalValidationFunction().validateEmail(data.email.value),
      },
      userName: {
        value: data.userName.value,
        warning: data.userName.value === "" ? true : false,
      },
    };
    setFormData({ ...data });
    if (!data.email.warning && !data.userName.warning) {
      createSubAdminFun();
    }
  };
  const createSubAdminFun = () => {
    setLoader(true);
    const onSuccess = (res) => {
      setFormData({
        email: { value: "", warning: false },
        userName: { value: "", warning: false },
      }); // empty the state after success fully create the user
      if (res.data.success) {
        toast.success(res.data.message);
      } else if (!res.data.success) {
        toast.error(res.data.message);
      }
      setLoader(false);
    };
    const onError = (err) => {
      console.log("err", err);
    };
    let body = {
      email: formData.email.value,
      username: formData.userName.value,
    };
    createSubAdminService.createSubAdmin(token, body, onSuccess, onError, {});
  };
  return (
    <div>
      <div className="card-sec bg-white p-3">
        <div className="row ">
          <div className="col-md-6">
            <h3 className="title ">Add New Admin</h3>
            <form onSubmit={onSubmit}>
              <div className="form-group mb-3">
                <Input
                  label={"Email"}
                  type={"email"}
                  value={formData.email.value}
                  name={"email"}
                  onChange={onChange}
                  error={
                    formData.email.warning === true ? "Enter a valid email" : ""
                  }
                />
              </div>
              <div className="form-group">
                <Input
                  label={"Username"}
                  value={formData.userName.value}
                  name={"userName"}
                  onChange={onChange}
                  error={
                    formData.userName.warning === true
                      ? "Enter a user name"
                      : ""
                  }
                />
              </div>
              <div className="">
                {loader ? null : (
                  <button className="btn btn-primary mt-3 px-5 py-2">
                    {"Submit"}
                  </button>
                )}
              </div>
            </form>
            <div className="">
              {loader ? (
                <button className="btn btn-primary mt-3 px-5 py-2">
                  {"Loading...."}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
