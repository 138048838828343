import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { view } from "../../assets/icons/admin";
import { CustomModal, Loader, ReactDataTable } from "../../components/common";
import DropDownField from "../../components/common/Dropdown";
import { hideLoader, showLoader } from "../../redux/reducers/common/appSlice";
import { getAdminUserList } from "../../redux/thunk/admin/adUser";
import { adminMangementServices } from "../../services/admin/adminMangement/adminMangement";
import { useModal, useRefreshToken } from "../../utility/hooks";

function AdminMangement() {
  const { adminAsperPaginationData } = useSelector(
    (state) => state?.adUserList
  );
  const { loader } = useSelector((state) => state.app);

  const token = useSelector(
    (state) => state?.adAuth.adminData.signInUserSession.idToken.jwtToken
  );
  const [onChangeRownValue, setOnChangeRownValue] = useState(0);
  const [detailState, setDetailState] = useState({});
  const [paginationItem, setPaginationItem] = useState({
    page: 1,
    itemsPerPage: 10,
  });
  const [adminData, setAdminData] = useState([]);

  const { refreshAdminToken } = useRefreshToken();

  const dispatch = useDispatch();

  const { show, handleClose, handleShow } = useModal();

  useEffect(() => {
    refreshAdminToken();
    const data = {
      authToken: token,
      page_no: paginationItem.page,
      page_size: paginationItem.itemsPerPage,
    };
    dispatch(getAdminUserList(data));
  }, [paginationItem]);

  useEffect(() => {
    filterSuperAdmin();
  }, [adminAsperPaginationData?.data]);

  // set admin table data from the redux
  const filterSuperAdmin = () => {
    if (adminAsperPaginationData?.data?.length > 0) {
      const data = adminAsperPaginationData?.data?.filter(
        (item) => item.SuperAdmin === false
      );
      setAdminData(data);
    }
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setOnChangeRownValue(newPerPage);
  };
  const viewDetailModal = (data) => {
    setDetailState(data);
    handleShow();
  };

  function filterFxn(column, onFilter, filterValue) {
    return (
      <input
        type="text"
        onChange={(e) =>
          onFilter(e.target.value, column.selector, e.target.name)
        }
        style={{ width: "100%", padding: "5px" }}
        value={filterValue || ""}
      />
    );
  }

  const columns = [
    {
      name: <div className="showFilter">User Name</div>,
      selector: (row, index) => row.Username,
      sortable: true,
      customFilter: ({ column, onFilter, filterValue }) =>
        filterFxn(column, onFilter, filterValue),
    },
    {
      name: <div className="showFilter">Email</div>,
      selector: (row) => row.Email,
      sortable: true,
      customFilter: ({ column, onFilter, filterValue }) =>
        filterFxn(column, onFilter, filterValue),
    },
    {
      name: "Action",
      selector: (row, index) => (
        <div className="actions_btns">
          <div className="action_btns">
            <button className="action_btn" onClick={() => viewDetailModal(row)}>
              <Image src={view} />
            </button>{" "}
            <DropDownField
              value={row.Deleted === true ? "disable" : "enable"}
              data={[
                { label: "enable", value: "enable" },
                { label: "disable", value: "disable" },
              ]}
              name={""}
              attr={["dataUpdate", index, row]}
              onChange={setEnableDisableAdmin}
            />
          </div>
        </div>
      ),
    },
  ];

  //** set the function  to disable enable admin   **/
  // ** params attr
  // ** params value
  const setEnableDisableAdmin = (attr, value) => {
    let row = attr[2];
    let data = [...adminData];
    data.splice(attr[1], 1, {
      ...data[attr[1]],
      Deleted: value === "disable" ? true : false,
    });
    setAdminData([...data]);
    adminEnableDisableService(
      row.Deleted === true ? "enable" : "disable",
      row.AdminID,
      row.Username
    ); //  api service call
  };
  //** set the function  to disable enable admin   **/
  // ** api intigration for the enable and disable the admin from the admin table
  const adminEnableDisableService = (action, adminID, userName) => {
    dispatch(showLoader());
    const onSuccess = (res) => {
      if (res?.data?.success === true) {
        toast.success(res?.data?.message);
        const data = { authToken: token };
        dispatch(getAdminUserList(data));
      }
      dispatch(hideLoader());
    };
    const onError = (err) => {
      console.log("erropopopo", err);
      dispatch(hideLoader());
    };
    let param = {
      AdminID: adminID,
      action: action,
      username: userName,
    };
    adminMangementServices.adminDisableEnable(
      token,
      param,
      onSuccess,
      onError,
      {}
    );
  };

  return (
    <div>
      <CustomModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        modalHead=" Admin Profile "
        className="user_modal"
      >
        <div className="user_details">
          <p>
            <b>Username </b> <span>: {detailState.Username} </span>
          </p>
          <p>
            <b>Email </b> <span>: {detailState.Email} </span>
          </p>
        </div>
      </CustomModal>

      <div className="card-sec bg-white p-3">
        <h3>Admin Table </h3>
        <div className="user-manage-table admin_table mt-4">
          {loader ? (
            <Loader />
          ) : adminData?.length > 0 ? (
            <ReactDataTable
              data={adminData}
              columns={columns}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationItem={paginationItem}
              setPaginationItem={setPaginationItem}
              total_records={adminAsperPaginationData?.total_data_length_}
              isHaveNextPage={adminAsperPaginationData?.next_page}
            />
          ) : (
            <span>no record found</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminMangement;
