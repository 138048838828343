import {  POSTAPI,  } from "../../globalServicesMethod";



const userSendMessage = (token, body, onSuccess, onError, config) => {
  POSTAPI(
    token,
    "https://ld111087yg.execute-api.us-east-2.amazonaws.com/dev",
    body,
    onSuccess,
    onError,
    config
  );
};

const getUserMessage = (token, body, onSuccess, onError, config) => {
  POSTAPI(
    token,
    "https://ld111087yg.execute-api.us-east-2.amazonaws.com/dev/ChatByID",
    body,
    onSuccess,
    onError,
    config
  );
};

export const userChatServices = {
    userSendMessage,
    getUserMessage
};
