import React, { Component, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { addSubscriptionPlan } from "../../redux/thunk/admin/Subscription";

import { useNavigate } from "react-router";
import { Loader } from "../../components/common";
import { toast } from "react-toastify";
import { useRefreshToken } from "../../utility/hooks";
import { globalRegex } from "../../shared/helperFunction";

const schema = yup.object().shape({
  planName: yup.string().required("Plan Name is a required field"),
  planPrice: yup.string().required("Plan Price is a required field"),
  currency: yup.string().required("Currency is a required field"),
  emailLimit: yup.string().required("Email Limit is a required field"),
});

function AddPricing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { refreshAdminToken } = useRefreshToken();

  const adminAuthtoken = useSelector(
    (state) => state.adAuth.adminData.signInUserSession.idToken.jwtToken
  );
  const adminSubscriptionList = useSelector(
    (state) => state.adSubscription.adminSubscriptionList.data
  );

  const { loader } = useSelector((state) => state.app);

  useEffect(() => {
    refreshAdminToken();
  }, []);

  const handleOnSubmit = (values) => {
    let checkItem = adminSubscriptionList.find(
      (item) => item.PlanName.toLowerCase() === values.planName.toLowerCase()
    );
    if (checkItem) {
      toast.error("This plan name is already exist");
    } else {
      let val = "";
      let gg = values.planPrice;
      let ff = gg.split("");
      if (ff.includes(".")) {
        val = values.planPrice.replace(".", "");
      } else {
        val = values.planPrice * 100;
      }

      const formData = {
        PlanName: values.planName,
        Price: Number(val),
        Currency: "usd",
        EmailsAllowedPerMonth: Number(values.emailLimit),
      };
      const data = {
        adminAuthtoken,
        values: {
          ...formData,
        },
      };
      dispatch(addSubscriptionPlan(data)).then((res) => {
        if (
          res.payload.success === true &&
          res.payload.message === "successfully created a plan"
        ) {
          navigate("/admin/subscriptions-management");
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      planName: "",
      planPrice: "",
      currency: "",
      emailLimit: "",
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });

  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  return (
    <div>
      <div className="card-sec bg-white p-3">
        <h4>Add New Pricing Plan</h4>
        <div className="form-section mt-3">
          {loader ? (
            <Loader />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>Plan Name</label>
                  <input
                    type="text"
                    placeholder=" Enter Plan Name"
                    value={formik.values.planName}
                    onChange={(e) => setInputValue("planName", e.target.value)}
                  />
                  <small className="error-text">
                    {" "}
                    {formik.touched.planName && formik.errors.planName}
                  </small>
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>Plan Price</label>
                  <input
                    type="text"
                    placeholder="Enter Plan Price"
                    value={formik.values.planPrice}
                    onChange={(e) =>
                      setInputValue(
                        "planPrice",
                        globalRegex().onChangeToNumber(e.target.value)
                      )
                    }
                  />
                  <small className="error-text">
                    {formik.touched.planPrice && formik.errors.planPrice}
                  </small>
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>Currency</label>
                  <input
                    type="text"
                    placeholder="Enter Currency"
                    value={formik.values.currency}
                    onChange={(e) => setInputValue("currency", e.target.value)}
                  />
                  <small className="error-text">
                    {formik.touched.currency && formik.errors.currency}
                  </small>
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="input_label_wrap">
                  <label>Email limit per month</label>
                  <input
                    type="number"
                    placeholder="Enter email limit per month"
                    value={formik.values.emailLimit}
                    onChange={(e) =>
                      setInputValue("emailLimit", e.target.value)
                    }
                  />
                  <small className="error-text">
                    {formik.touched.emailLimit && formik.errors.emailLimit}
                  </small>
                </div>
              </div>

              {!!formik.errors.planPrice && <br />}

              <div className="common_btns">
                <button
                  title="Download"
                  type="submit"
                  // disabled={!formik.isValid}
                  className="primary_btn"
                >
                  {loader ? "Adding Plan..." : "Add Plan"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddPricing;
