import { localStorageConstant } from "../shared/constants";
import axiosInstance from "./axiosInstance";
const httpsClient = async (config, authToken) => {
  const getToken = localStorage.getItem(localStorageConstant.accessToken); // this token get after the api get 401 error
  const tokenSet = getToken ? getToken : authToken;
  const configration = {
    ...config,
    baseURL: "",
    timeout: 1000 * 60 * 10,
    headers: {
      "Content-Type": "application/json",
      ...config.headers,
    },
  };
  if (authToken) {
    configration.headers = {
      ...configration.headers,
      Authorization: `Bearer ${tokenSet}`,
    };
  }
  const result = await axiosInstance(configration);
  return result;
};

export default httpsClient;
