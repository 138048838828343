import axios from "axios";
import {
  refreshAdminToken,
  refreshUserToken,
} from "../utility/hooks/useRefreshToken";
import { localStorageConstant } from "../shared/constants";

export const AxiosInstance = (token) => {
  // @@@ below code get the app url contain admin or not
  const url = window.location.href;
  const getToken = localStorage.getItem(localStorageConstant.accessToken); // this token get after the api get 401 error
  const tokenSet = getToken ? getToken : token;
  const match = url.includes("admin");
  const Axios = axios.create({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSet}`,
    },
  });
  Axios.interceptors.request.use(
    function (config) {
      const controller = new AbortController();
      if (!token) {
        controller.abort();
      }
      //   config.headers["Authorization"] = "";
      return {
        ...config,
        signal: controller.signal,
      };
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const token =
          match === true ? await refreshAdminToken() : await refreshUserToken();
        originalRequest.headers["Authorization"] =
          "Bearer" + " " + token.signInUserSession.idToken.jwtToken;
        localStorage.setItem(
          localStorageConstant.accessToken,
          token.signInUserSession.idToken.jwtToken
        );
        return Axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
  return Axios;
};
