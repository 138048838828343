import React from "react";

export default function DropDownField({
  attr,
  value,
  name,
  onChange,
  data,
  errorText,
  error
}) {
  return (
    <>
      <label htmlFor="cars">{name}</label>
      <select
        class="form-select"
        value={value}
        name={name}
        onChange={(e) => {
          onChange(attr, e.target.value);
        }}
      >
        {data &&
          data.length > 0 &&
          data?.map((data, index) => (
            <>
              <option key={index} value={data.value}>
                {data.label}
              </option>
            </>
          ))}
      </select>
      {errorText && error === true ? <small className="error-text">{errorText}</small> : null}
    </>
  );
}
