import { ROUTES } from "../navigation/constants";
import {
  categories,
  dashboard,
  faqs,
  logout,
  payment,
  programs,
  sociallinks,
  speakers,
  user,
  tags,
  support,
  download,
} from "../assets/icons/admin";

const {
  adUserManagement,
  adAdminTable,
  adHistory,
  adSubscriptionManagement,
  adAddPricing,
  adAddFaq,
  usrSpeaker,
  usrLead,
  userLeadByInfluncer,
  usrCategories,
  usrDashboard,
  usrHistory,
  getLeadReq,
  usrSupportChat,
  adChat,
} = ROUTES;

//admin sidebar tab list
const adminSidebarList = [
  {
    id: 1,
    path: adUserManagement,
    title: "User Management",
    icon: tags,
    type: "link",
    activeFor: [adUserManagement],
  },

  {
    id: 3,
    path: adSubscriptionManagement,
    title: "Subscription Management",
    icon: categories,
    type: "link",
    activeFor: [adSubscriptionManagement, adAddFaq],
  },
  {
    id: 4,
    path: getLeadReq,
    title: "Lead Requests",
    icon: faqs,
    type: "link",
    activeFor: [getLeadReq],
  },

  {
    id: 6,
    path: adChat,
    title: "Chat Support",
    icon: programs,
    type: "link",
    activeFor: [adChat],
  },

  {
    id: 11,
    path: "#",
    title: "Logout",
    icon: logout,
    type: "button",
    activeFor: [],
  },
];

const userSidebarList = [
  {
    id: 1,
    path: userLeadByInfluncer,
    title: "Leads By Influencer",
    icon: sociallinks,
    type: "link",
    activeFor: [userLeadByInfluncer],
  },
  {
    id: 2,
    path: usrLead,
    title: "Leads by Category",
    icon: user,
    type: "link",
    activeFor: [usrLead],
  },
  {
    id: 4,
    path: usrHistory,
    title: "Downloads",
    icon: download,
    type: "link",
    activeFor: [usrHistory],
    paid: true,
  },
  {
    id: 3,
    path: usrDashboard,
    title: "Usage Summary",
    icon: dashboard,
    type: "link",
    activeFor: [usrDashboard],
    paid: true,
  },

  {
    id: 6,
    path: usrSupportChat,
    title: "Support",
    icon: support,
    type: "link",
    activeFor: [usrSupportChat],
    paid: false,
  },
];

export { adminSidebarList, userSidebarList };
