import React, { useEffect } from "react";
import { Col, Row, Image } from "react-bootstrap";

import {
  videos,
  dashboarduser,
  dashboard_payment,
} from "../../assets/icons/admin";

import "../../styles/admin/dashboard.scss";
import { useSelector } from "react-redux";
import { checkSuperAdminServices } from "../../services/auth/checkSuperAdminAuth";

function Dashboard() {
  
  return (
    <>
      <h3 className="title">This Month </h3>
      <div className="top_cards">
        <Row>
          <Col md={4}>
            <div className="cards_dashboard videos">
              <span className="icons">
                <Image src={videos} />
              </span>
              <div className="right_text">
                <span className="number">6488</span>
                <p className="text_card">Leads Pulled </p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="cards_dashboard users">
              <span className="icons">
                <Image src={dashboarduser} />
              </span>
              <div className="right_text">
                <span className="number">3216</span>
                <p className="text_card">Remaining Manage Subscription</p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="cards_dashboard payments">
              <span className="icons">
                <Image src={dashboard_payment} />
              </span>
              <div className="right_text">
                <span className="number">104</span>
                <p className="text_card">Influencer Sorted </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
