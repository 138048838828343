import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./index.css";
import { formatDateWithDayDateTime } from "../../../shared/helperFunction";
import {
  hideRootLoader,
  showRootLoader,
} from "../../../redux/reducers/common/appSlice";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../services/axiosInstance";
import { useParams } from "react-router";
import { adminApi } from "../../../services/apiEndpoints";
const BillingHistory = () => {
  // state for hold api data of payment history
  const [paymentData, setPaymentData] = useState([]);
  // get useparams id
  const { id } = useParams();
  // call dispatch
  const dispatch = useDispatch();
  // column set for payment history table
  const columns = [
    {
      name: "Event",
      selector: (row) => row.eventType,
      sortable: true,
      width: "200px",
    },
    {
      name: "Plan",
      selector: (row) => row.planName,
      sortable: true,
      width: "130px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount / 100 + " $",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          className={
            row.status_ === "canceled"
              ? "text-danger text-capitalize"
              : row.status_ === "active"
              ? "text-success text-capitalize"
              : "text-info text-capitalize"
          }
        >
          {" "}
          {row.status_}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate,
      sortable: true,
      width: "150px",
    },
    {
      name: "End Date",
      selector: (row) => row.endDate,
      sortable: true,
      width: "150px",
    },
    {
      name: "Updated ON",
      selector: (row) => formatDateWithDayDateTime(row.time_),
      sortable: true,
      width: "390px",
    },
  ];
  // get the api data
  const getPaymentData = async () => {
    const body = {
      customerID: id,
    };
    try {
      dispatch(showRootLoader());
      const res = await axiosInstance.post(adminApi.billingHistory, body);
      if (res?.statusCode === 200) {
        setPaymentData(res?.data);
      }
      dispatch(hideRootLoader());
    } catch (error) {
      console.log(error);
      dispatch(hideRootLoader());
    }
  };

  // effect for load api data at initial time
  useEffect(() => {
    if (id) {
      getPaymentData();
    } else {
      dispatch(showRootLoader());
    }
  }, [id]);

  return (
    <div className="billingHistyoryWrapper">
      <div className="container-fluid">
        <h3>Billing History</h3>
        {paymentData?.userDetails?.Username && (
          <div className="user_details mt-5">
            <div className="row">
              <div className="col-md-6">
                <div className="Email d-flex justify-content-between align-items-center">
                  <h6 className="mb-0">Email </h6>
                  <div style={{ color: "#696969" }}>
                    {paymentData?.userDetails?.Email}{" "}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="CurrentPlan d-flex justify-content-between align-items-center">
                  <h6 className="mb-0">CurrentPlan </h6>
                  <div style={{ color: "#696969" }}>
                    {paymentData?.userDetails?.CurrentPlan
                      ? paymentData?.userDetails?.CurrentPlan
                      : "No Plan Yet"}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="tableData mt-5">
          <DataTable
            columns={columns}
            data={paymentData?.userHistory}
            pagination
            highlightOnHover
          />
        </div>
      </div>
    </div>
  );
};

export default BillingHistory;
