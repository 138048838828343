import { combineReducers } from "redux";
import appSlice from "./common/appSlice";
import adminMainSlice from "./adminSlices/adAuth";
import adminUserSlice from "./adminSlices/adUser";
// import adminSpeakerSlice from "./adminSlices/adSpeakers";
// import adminCategoriesSlice from "./adminSlices/adCategories";
// import adminTagsSlice from "./adminSlices/adTags";
// import adminProgramsSlice from "./adminSlices/adPrograms";
import adminDashboardSlice from "./adminSlices/adDashboard";
// import adminFaqsSlice from "./adminSlices/adFaq";
// import adminCmsSlice from "./adminSlices/adCms";
// import adminPaymentSlice from "./adminSlices/adPayment";
import adminSubscriptionListSlice from "./adminSlices/adSubscription";
import adminChatSlice from "./adminSlices/adminChat";
import userAuth from "./userSlices/userAuth";
import userProfileSlice from "./userSlices/userProfile";
import userPaymentSlice from "./userSlices/userPayment";
import userProgramCategoriesSpeakersList from "./userSlices/userProgramCategories&SpeakersList";

import usrUserList from "./userSlices/usrUserList";
import userHistory from "./userSlices/userHistory";
import userSupportChat from "./userSlices/userSupportChat";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  //common slices
  app: appSlice,

  adAuth: adminMainSlice,
  adUserList: adminUserSlice,
  adminChat: adminChatSlice,
  // adSpeaker: adminSpeakerSlice,
  // adCategories: adminCategoriesSlice,
  // adTags: adminTagsSlice,
  // adPrograms: adminProgramsSlice,
  adDashboard: adminDashboardSlice,
  admin: adminMainSlice,
  // adFaqs: adminFaqsSlice,
  // adCms: adminCmsSlice,
  // adPayment: adminPaymentSlice,
  // adNotification: adminNotificationSlice,
  // adLatestProgram: adminLatestProgramsSlice,
  adSubscription: adminSubscriptionListSlice,
  //User slices
  userList: usrUserList,
  userAuth: userAuth,
  // userPrograms: userProgramsSlice,
  userProfile: userProfileSlice,
  userPayment: userPaymentSlice,
  userCategoriesSpeakers: userProgramCategoriesSpeakersList,
  // userCredentials: userCredentials,
  userHistory: userHistory,
  userSupportChat: userSupportChat,
});

// this function is used for globally logout through the redux
const rootReducer = (state, action) => {
  if (action.type === "userAuthSlice/userLogout") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
