import axios from "axios";
import {
  refreshAdminToken,
  refreshUserToken,
} from "../utility/hooks/useRefreshToken";
import { localStorageConstant } from "../shared/constants";

const axiosInstance = axios.create();
// @@@ below code get the url contain admin or not
const url = window.location.href;
const match = url.includes("admin");

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle the response data
    return response.data;
  },
  async (error) => {
    // Handle response 401 and call the refresh token
    // @@ refresh token is also called as per the condition of the url we get
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const token =
          match === true ? await refreshAdminToken() : await refreshUserToken();
        localStorage.setItem(
          localStorageConstant.accessToken,
          token.signInUserSession.idToken.jwtToken
        );
        originalRequest.headers["Authorization"] =
          "Bearer" + " " + token.signInUserSession.idToken.jwtToken;
        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
