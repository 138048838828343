import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  userMakePayment,
  userPaymentConfirm,
} from "../../redux/thunk/user/usrPayment";
import { toast } from "react-toastify";
import BtnGroup from "./BtnGroup";
import Button from "./Button";
import { useModal } from "../../utility/hooks";
import { getUserDetail } from "../../redux/thunk/user/usrUsers";

const CheckoutForm = ({ planID, onCancel }) => {
  const { userData: user_data } = useSelector((state) => state.userAuth);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [disBtn, setDisBtn] = useState(false);

  const makePaymentHandler = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    try {
      const stripeToken = await stripe.createToken(cardElement);
      if (stripeToken?.error) {
        setErrorMessage(stripeToken?.error?.message);
        return toast.error(stripeToken?.error?.message);
      }
      const values = {
        PlanID: planID,
        UserID: user_data?.attributes?.sub,
      };
      const token = user_data?.signInUserSession?.accessToken?.jwtToken;
      dispatch(userMakePayment({ token, values })).then(async ({ payload }) => {
        setDisBtn(true);
        if (payload?.data?.status === "requires_confirmation") {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            payload?.data?.client_secret
          );
          const data = {
            values: {
              //name: user_data?.username,
              //email: user_data?.attributes?.email,
              payment_intent_id: paymentIntent?.id,
              SubscriptionID: planID,
              User_ID: user_data?.attributes?.sub,
            },
            cb: onCancel,
          };

          if (!error) {
            switch (paymentIntent.status) {
              case "succeeded":
                // dispatch(userPaymentConfirm(data)).then(({ payload }) => {
                //   if (payload.success === true) {
                //     dispatch(getUserDetail(data_));
                //     onCancel();
                //     toast.success("Payment done successfully.");
                //   } else {
                //     onCancel();
                //     toast.error("There is a payment error.");
                //   }
                // });
                break;
              case "processing":
                toast.info("Payment is under process.");
                break;
              case "requires_payment_method":
                toast.error("Your payment is rejected.");
                break;
              default:
                toast.error("Something went wrong.");
                break;
            }
          } else {
            toast.error(stripeToken?.error?.message);
            setErrorMessage("An error occured please try again.");
          }
        } else {
          const token = user_data?.siginInUserSession?.accessToken?.jwtToken;
          const data = {
            token,
            values: {
              payment_intent_id: payload?.responseData?.paymentIntent?.id,
              planID: planID,
            },
          };
          //dispatch(userPaymentConfirm(data));
        }
      });
    } catch (error) {
      console.log("errrr", error);
      alert(error);
      setErrorMessage("An error occured please try again.");
    }
  };

  const options = {
    errorMessage: errorMessage,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
      empty: {
        color: "red",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true, // You can customize this option to show or hide the postal code field
    errorMessage: "enter value",
  };

  const onSubmitHandler = (e) => {
    setDisBtn(true);
    e.preventDefault();
    makePaymentHandler();
  };

  const onCancelHandler = () => {
    onCancel();
  };

  /// test the stripe checkout page
  const optionss = {
    paymentMethods: ["card", "apple_pay"], // Specify the payment methods you want to accept
  };
  return (
    <>
      <div
        style={{
          textAlign: "center",
          "text-transform": "uppercase",
        }}
      >
        <span className="text-center">Enter your card details</span>
      </div>
      <form onSubmit={onSubmitHandler}>
        <label>Card Number</label>
        <CardNumberElement id="card-number-element" options={options} />
        <label>Cvc Number</label>
        <CardCvcElement id="card-cvc-element" options={options} />
        <label>Card Expiry</label>
        <CardExpiryElement id="card-expiry-element" options={options} />
        <BtnGroup className="common_btns">
          <Button
            disable={!stripe || !elements}
            loading={true}
            loadMsg={"pay..."}
            title={"Pay"}
            type={"submit"}
            // className="primary_btn"
            className={disBtn ? "dis-button primary_btn" : "primary_btn"}
          />
          <Button
            title="cancel"
            type="button"
            className="secondry_btn"
            onClick={onCancelHandler}
          />
        </BtnGroup>
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
      </form>
    </>
  );
};
export default CheckoutForm;
