import { POSTAPI } from "../../globalServicesMethod";

const getLeadRequest = (token, dataToSend, onSuccess, onError, config) => {
  POSTAPI(
    token,
    "https://gztgnxuu5d.execute-api.us-east-2.amazonaws.com/dev",
    dataToSend,
    onSuccess,
    onError,
    config
  );
};

const changeLeadStatus = (token, body, onSuccess, onError, config) => {
  POSTAPI(
    token,
    "https://4907dt3x3k.execute-api.us-east-2.amazonaws.com/dev/",
    body,
    onSuccess,
    onError,
    config
  );
};

const sendINfluencerDataThroughEmail = (
  token,
  body,
  onSuccess,
  onError,
  config
) => {
  POSTAPI(
    token,
    "https://9e1zygp3k6.execute-api.us-east-2.amazonaws.com/dev/",
    body,
    onSuccess,
    onError,
    config
  );
};

export const getLeadRequestAllServices = {
  getLeadRequest,
  changeLeadStatus,
  sendINfluencerDataThroughEmail,
};
