// with firstname and lastname fields
// import { Authenticator, View, useTheme } from "@aws-amplify/ui-react";
// import "@aws-amplify/ui-react/styles.css";
// import { Amplify, Auth, Hub } from "aws-amplify";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { logo } from "../../assets/images/admin";
// import { ROUTES } from "../../navigation/constants";
// import { userData } from "../../redux/reducers/userSlices/userAuth";
// import "../../styles/user/auth.scss";
// import { awsExports } from "../../utility/aws-exports";

// const Login = () => {
//   const [jwtToken, setJwtToken] = useState("");
//   const { userData: user_data } = useSelector((state) => state.userAuth);
//   let userSession = user_data?.signInUserSession;
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     Amplify.configure({
//       Auth: {
//         region: awsExports.REGION,
//         userPoolId: awsExports.NEW_USER_POOL_FOR_EMAIL,
//         userPoolWebClientId: awsExports.NEW_USER_POOL_FOR_EMAIL_CLIENT_ID,
//       },
//     });

//     fetchJwtToken();
//   }, [userSession]);

//   useEffect(() => {
//     const authListener = (data) => {

//       switch (data.payload.event) {
//         case "signUp":
//           break;
//         case "signIn":
//           const usrData = data.payload.data;
//           dispatch(userData(usrData)); // Dispatch user data to redux store
//           break;
//         default:
//           break;
//       }
//     };

//     Hub.listen("auth", authListener);

//     return () => Hub.remove("auth", authListener);
//   }, []);

//   const fetchJwtToken = async () => {
//     try {
//       const session = await Auth.currentSession();
//       const token = session.getIdToken().getJwtToken();
//       setJwtToken(token);
//     } catch (error) {
//       console.log("Error fetching JWT token:", error);
//     }
//   };

//   return (
//     <div
//       style={{
//         backgroundColor: "#2780cd",
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         flexDirection: "column",
//       }}
//       className="userLoginForm"
//     >
//       <Authenticator
//         loginMechanisms={["email"]}
//         initialState="signIn"
//         components={{
//           SignUp: {
//             Header() {
//               const { tokens } = useTheme();
//               return (
//                 <View textAlign="center" padding={tokens.space.large}>
//                   <img src={logo} style={{ maxWidth: "56%" }} />
//                 </View>
//               );
//             },
//             FormFields() {
//               return (
//                 <>
//                   <div>
//                     <label>First Name</label>
//                     <input
//                       style={{
//                         height: "44px",
//                         borderRadius: "4px",
//                         borderColor: "rgb(136, 147, 158)",
//                         width: "100%",
//                         padding: "8px",
//                       }}
//                       type="text"
//                       id="firstName" // Ensure this matches the key used in `formData`
//                       name="firstName" // Ensure this matches the key used in `formData`
//                       placeholder="Enter your first name"
//                       required
//                     />
//                   </div>
//                   <div style={{ marginTop: "16px" }}>
//                     <label>Last Name</label>
//                     <input
//                       style={{
//                         height: "44px",
//                         borderRadius: "4px",
//                         borderColor: "rgb(136, 147, 158)",
//                         width: "100%",
//                         padding: "8px",
//                       }}
//                       type="text"
//                       id="lastName" // Ensure this matches the key used in `formData`
//                       name="lastName" // Ensure this matches the key used in `formData`
//                       placeholder="Enter your last name"
//                       required
//                     />
//                   </div>
//                   <Authenticator.SignUp.FormFields />
//                 </>
//               );
//             },
//           },
//           SignIn: {
//             Header() {
//               const { tokens } = useTheme();
//               return (
//                 <View textAlign="center" padding={tokens.space.large}>
//                   <img src={logo} style={{ maxWidth: "56%" }} />
//                 </View>
//               );
//             },
//           },
//         }}
//         services={{
//           async validateCustomSignUp(formData) {
//             if (!formData.firstName || !formData.lastName) {
//               return {
//                 firstName: "First Name is required",
//                 lastName: "Last Name is required",
//               };
//             }
//           },
//           async handleSignUp(formData) {
//             const { username, password, attributes } = formData;
//             const firstName = document.getElementById("firstName")?.value;
//             const lastName = document.getElementById("lastName")?.value;

//             try {
//               const response = await Auth.signUp({
//                 username,
//                 password,
//                 attributes: {
//                   email: username,
//                   "custom:firstName": firstName,
//                   "custom:lastName": lastName,
//                 },
//               });
//               return response;
//             } catch (error) {
//               console.error("Sign Up Error:", error);
//               throw error;
//             }
//           },
//         }}
//       ></Authenticator>
//     </div>
//   );
// };

// export default Login;

// without firstname and lastname code

import { Authenticator, View, useTheme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logo } from "../../assets/images/admin";
import { ROUTES } from "../../navigation/constants";
import { userData } from "../../redux/reducers/userSlices/userAuth";
import "../../styles/user/auth.scss";
import { awsExports } from "../../utility/aws-exports";

const Login = () => {
  const [jwtToken, setJwtToken] = useState("");
  const { userData: user_data } = useSelector((state) => state.userAuth);
  let userSession = user_data?.signInUserSession;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.NEW_USER_POOL_FOR_EMAIL,
        userPoolWebClientId: awsExports.NEW_USER_POOL_FOR_EMAIL_CLIENT_ID,
      },
    });

    fetchJwtToken();
  }, [userSession]);

  useEffect(() => {
    const authListener = (data) => {
      switch (data.payload.event) {
        case "signUp":
          break;
        case "signIn":
          const usrData = data.payload.data;

          //  navigate(ROUTES.saveSubscriptionDetails);
          dispatch(userData(usrData));

          break;
        default:
          break;
      }
    };

    Hub.listen("auth", authListener);

    return () => Hub.remove("auth", authListener);
  }, []);

  const fetchJwtToken = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      setJwtToken(token);
    } catch (error) {
      console.log("Error fetching JWT token:", error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#2780cd",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      className="userLoginForm"
    >
      <Authenticator
        loginMechanisms={["email"]}
        initialState="signIn"
        components={{
          SignUp: {
            Header() {
              const { tokens } = useTheme();
              return (
                <View textAlign="center" padding={tokens.space.large}>
                  <img src={logo} style={{ maxWidth: "56%" }} />
                </View>
              );
            },
            FormFields() {
              return (
                <>
                  <Authenticator.SignUp.FormFields />
                  {/* <div>
                    <label>Username</label>
                  </div>
                  <input
                    style={{
                      height: "44px",
                      borderRadius: "4px",
                      borderColor: "rgb(136, 147, 158)",
                    }}
                    type="text"
                    name="name"
                    placeholder="Please enter a name"
                  /> */}
                </>
              );
            },
          },
          SignIn: {
            Header() {
              const { tokens } = useTheme();
              return (
                <View textAlign="center" padding={tokens.space.large}>
                  <img src={logo} style={{ maxWidth: "56%" }} />
                </View>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            if (!formData.email) {
              return {
                given_name: "First Name is required",
              };
            }
          },
        }}
      ></Authenticator>
    </div>
  );
};

export default Login;
