import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/common/Loader";
import { useNavigate } from "react-router";
import { getSubscriptionListApi } from "../../redux/thunk/admin/Subscription";
import { view, trash, conditions } from "../../assets/icons/admin";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useRefreshToken } from "../../utility/hooks";

// import toast

function SubscriptionManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refreshAdminToken } = useRefreshToken();

  const adminSubscriptionList = useSelector(
    (state) => state.adSubscription.adminSubscriptionList.data
  );
  const { loader } = useSelector((state) => state.app);

  const newPlanHandler = () => {
    navigate(`/admin/add-pricing`);
  };

  const editPlanHandler = (id) => {
    navigate(`/admin/edit-pricing-plan?id=${id}`);
  };

  useEffect(() => {
    refreshAdminToken();
    dispatch(getSubscriptionListApi());
  }, []);

  return (
    <div>
      <div className="card-sec bg-white p-3">
        <div className="d-flex justify-content-between align-items-center pb-3">
          <h3 className="title mb-0">Subscriptions Management</h3>
          <button className="btn btn-primary" onClick={newPlanHandler}>
            Add New Plan
          </button>
        </div>
        <div className="mt-3">
          <Row>
            {loader ? (
              <Loader />
            ) : adminSubscriptionList?.length > 0 ? (
              adminSubscriptionList?.map((data, index) => {
                let prrr = Number(data.PricePerMonth);
                function centsToDollars(cents) {
                  return cents / 100;
                }
                const dollarsValue = centsToDollars(prrr);
                return (
                  <Col md={6} lg={4} key={index}>
                    <div className="pricing-plans">
                      <div className="plan">
                        <div className="plan-inner">
                          <div className="entry-title">
                            <h3 className="">{data.PlanName}</h3>
                            {data.PlanName === "Trial" ? (
                              <span
                                onClick={() =>
                                  editPlanHandler(data.SubscriptionID)
                                }
                                className="edit-plan"
                              >
                                <Image
                                  src={conditions}
                                  style={{ filter: "brightness(0)" }}
                                />
                              </span>
                            ) : null}
                            <div className="price">
                              ${dollarsValue.toFixed(2)}
                              <span>/PER Month</span>
                            </div>
                          </div>
                          <div className="entry-content">
                            <ul>
                              <li>
                                {data.EmailsAllowedPerMonth} emails per month
                              </li>
                              {data?.PlanName === "Trial"
                                ? (data.Features?.[0]?.L ?? []).map(
                                    (featureItem, index) => (
                                      <li key={index}>{featureItem?.S}</li>
                                    )
                                  )
                                : null}
                            </ul>
                          </div>
                          {/* <div className="btn w-100 text-center" onClick={planUpdateHandler}>
                              <a>Update</a>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div>Record Not found</div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionManagement;
