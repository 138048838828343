import * as yup from "yup";

export const leadSchema = yup.object().shape({
  Leads: yup
    .number()
    .min(1)
    .max(5000, "Enter Lead between 1 to 5000")

    .required("Leads is required"),
  Categories: yup.string().required("Categories is required"),
});
