import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { Dropdown, DropdownButton, Image } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { burger } from "../../assets/images/admin";
import { userLogout } from "../../redux/reducers/userSlices/userAuth";
import { MdSubscriptions } from "react-icons/md";
import { IoMdLogOut } from "react-icons/io";

import "../../styles/admin/header.scss";
import "../../styles/user/header.scss";
import { ROUTES } from "../../navigation/constants";

function Header({ show, setShow }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const logoutHandler = () => {
    dispatch(userLogout());
    signOut();
  };

  return (
    <div className="user_header">
      <div className="title_block">
        {/* <h3>Welcome, {user?.attributes?.given_name} {user?.attributes?.family_name}</h3> */}
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {/* <div className="notification_message">
          <Image src={notification} />
          <span className="messages">8</span>
        </div> */}
        <div className="edit_profile" style={{ cursor: "pointer" }}>
          {" "}
          <DropdownButton
            id="dropdown-basic-button"
            title={<CgProfile size={25} />}
          >
            <Dropdown.Item
              onClick={() => navigate("/user/edit-profile") & setShow(false)}
            >
              <CgProfile />
              <span className="ms-2">Profile</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                navigate(ROUTES.userPaymentHistory) & setShow(false)
              }
            >
              <CgProfile />
              <span className="ms-2">Billing History</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => navigate("/user/subscriptions") & setShow(false)}
            >
              <MdSubscriptions />
              <span className="ms-2">Manage Subscription</span>
            </Dropdown.Item>

            <Dropdown.Item href="" onClick={logoutHandler}>
              <IoMdLogOut />
              <span className="ms-2">Logout</span>
            </Dropdown.Item>
          </DropdownButton>
        </div>

        <div
          className="mobile_burger d-block d-md-none"
          onClick={() => setShow(!show)}
        >
          <Image src={burger} className="toggle_burger" />
        </div>
      </div>
    </div>
  );
}

export default Header;
