import React, { useEffect, useState } from "react";
import "../../styles/user/auth.scss";
import { Amplify, Hub, Logger } from "aws-amplify";
import { awsExports } from "../../utility/aws-exports";
import {
  Authenticator,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { adminAuthSlice } from "../../redux/thunk/admin/adAuth";
import { adminData } from "../../redux/reducers/adminSlices/adAuth";
import { useLocation } from "react-router";
import { Auth } from "aws-amplify";
import { logo } from "../../assets/images/admin";

const AdminLogin = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { adminData: admin_data } = useSelector((state) => state.adAuth);
  let adminSession = admin_data?.signInUserSession;

  const [jwtToken, setJwtToken] = useState("");

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.ADMIN_POOL_ID,
        userPoolWebClientId: awsExports.ADMIN_POOL_APP_CLIENT_ID,
      },
    });

    fetchJwtToken();
  }, [adminSession]);

  useEffect(() => {
    //   // Set up the Hub event listener for auth events
    const authListener = (data) => {
      switch (data.payload.event) {
        case "signUp":
          break;

        case "signIn":
          let usrData = data.payload.data;
          dispatch(adminData(usrData));

          break;
        default:
          break;
      }
    };

    Hub.listen("auth", authListener);

    return () => Hub.remove("auth", authListener);
  }, []); // Run effect only once on mount

  const fetchJwtToken = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      setJwtToken(token);
    } catch (error) {
      console.log("Error fetching JWT token:", error);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#2780cd",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Authenticator
          hideSignUp={location.pathname === "/admin" ? true : false}
          initialState="signIn"
          components={{
            SignIn: {
              Header() {
                const { tokens } = useTheme();
                return (
                  <View textAlign="center" padding={tokens.space.large}>
                    <img src={logo} style={{ maxWidth: "56%" }} />
                  </View>
                );
              },
              FormFields() {
                return (
                  <>
                    <Authenticator.SignUp.FormFields />
                    <div>
                      <label>Email</label>
                    </div>
                    <input
                      style={{
                        height: "44px",
                        borderRadius: "4px",
                        borderColor: "rgb(136, 147, 158)",
                      }}
                      type="text"
                      name="email"
                      placeholder="Please enter a valid email"
                    />
                  </>
                );
              },
            },
          }}
          services={{
            async validateCustomSignUp(formData) {
              if (!formData.email) {
                return {
                  given_name: "First Name is required",
                };
              }
            },
          }}
        ></Authenticator>
      </div>
    </>
  );
};

export default AdminLogin;
