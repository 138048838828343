import React, { useEffect, useState } from "react";
import "./adminChat.css";
import { adminChatServices } from "../../../services/admin/adminChat/adminChatServices";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";
import { setTheChatConverstationDetail } from "../../../redux/reducers/adminSlices/adminChat";
export default function AdminChatSideBar() {
  const adminAuthtoken = useSelector(
    (state) => state.adAuth.adminData.signInUserSession.idToken.jwtToken
  );
  const { userChatDetail } = useSelector((state) => state.adminChat);
  const dispatch = useDispatch();

  const [userChatListData, setUserChatListData] = useState([]);
  useEffect(() => {
    adminChatUserListing();
  }, [userChatDetail?.CONVERSATION_ID]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      adminChatUserListing();
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  //@@ adminChatUserListing  api intigration
  const adminChatUserListing = () => {
    const onSuccess = (res) => {
      if (res.data.success === true) {
        setUserChatListData(res.data.data);
        if (
          userChatDetail?.CONVERSATION_ID == "" ||
          userChatDetail?.CONVERSATION_ID === 0
        ) {
          dispatch(setTheChatConverstationDetail(res?.data?.data[0]));
        }
      }
    };
    const onError = (err) => {
      console.log("getlistintgerr", err);
    };
    adminChatServices.adminChatSideBarUserListing(
      adminAuthtoken,
      onSuccess,
      onError
    );
  };

  // set the user chat detail in redux store
  //@@ param userChatDetail
  const setTheUserDetail = (userChatDetail) => {
    dispatch(setTheChatConverstationDetail(userChatDetail));
  };
  return (
    <div id="plist" className="people-list">
      <ul className="list-unstyled chat-list mt-2 mb-0">
        {userChatListData &&
          userChatListData?.map((userChatListItem) => (
            <li
              className={
                userChatListItem.CONVERSATION_ID ===
                userChatDetail.CONVERSATION_ID
                  ? "clearfix active"
                  : "clearfix"
              }
              onClick={() => setTheUserDetail(userChatListItem)}
            >
              <img
                src="https://i0.wp.com/digitalhealthskills.com/wp-content/uploads/2022/11/3da39-no-user-image-icon-27.png?fit=500%2C500&ssl=1"
                alt="avatar"
              />
              <div className="about">
                <div className="name">{userChatListItem?.USERNAME}</div>
                {/* <div className="status">
                  {" "}
                  <i className="fa fa-circle offline"></i>{" "}
                  {userChatListItem?.SUBJECT}{" "}
                </div> */}

                {userChatListItem?.UnReadCount === 0 ? null : (
                  <span className="uncount_msg">
                    {userChatListItem?.UnReadCount}
                  </span>
                )}
              </div>
            </li>
          ))}
        {userChatListData?.length === 0 ? "no user" : null}
      </ul>
    </div>
  );
}
