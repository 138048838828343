import React, { useEffect, useState } from "react";
import "../../styles/admin/categories.scss";
import "./autocomplete.css";
import { useDispatch, useSelector } from "react-redux";
import { getLeadInfluncerServices } from "../../services/user/LeadByInfluncer/leadByInfluncer";
import { toast } from "react-toastify";
import { Loader } from "../../components/common";
import { CSVLink } from "react-csv";
import { getUserDetail } from "../../redux/thunk/user/usrUsers";
import { useRefreshToken } from "../../utility/hooks";
import { getSubscriptionListApi } from "../../redux/thunk/admin/Subscription";
import {
  hideRootLoader,
  showRootLoader,
} from "../../redux/reducers/common/appSlice";
import { userMakePayment } from "../../redux/thunk/user/usrPayment";
import { useStripe } from "@stripe/react-stripe-js";
import ConfirmationModal from "../../components/user/ConfirmationModal";
import { modifiedUserDataFromDB } from "../../redux/reducers/userSlices/usrUserList";
function LeadByInfluncer() {
  const userAuthtoken = useSelector(
    (state) => state.userAuth.userData.signInUserSession.idToken.jwtToken
  );
  const adminSubscriptionList = useSelector(
    (state) => state.adSubscription.adminSubscriptionList.data
  );
  const loaders = useSelector((state) => state.app);
  const { userData } = useSelector((state) => state.userAuth);
  const [confirmModal, setConfirmModal] = useState(false);

  const { userDataFromDB } = useSelector((state) => state.userList);
  const [clickOnDownloadCsv, setClickOnDownloadCsv] = useState(false);
  const [searchUserData, setSearchUserData] = useState({
    userName: {
      value: "",
      warning: false,
    },
    leadCount: {
      value: 0,
      warning: false,
    },
  });
  const [loader, setLoader] = useState(false);
  const stripe = useStripe();

  const [influncerData, setInfluncerData] = useState([]);
  const dispatch = useDispatch();
  const userDataa = useSelector((state) => state.userAuth.userData);
  const updateTheLeadCountApiBody = {
    sub: userDataa?.attributes?.sub,
  };
  // to refresh the token
  const { refreshUserToken } = useRefreshToken();

  useEffect(() => {
    refreshUserToken(); // refresh the jwt token on based of refresh token
    dispatch(getSubscriptionListApi());
  }, []);

  const getUserDataOnClick = (e) => {
    e.preventDefault();

    let data = { ...searchUserData };
    data = {
      ...data,
      leadCount: {
        ...data.leadCount,
        warning: data.leadCount.value <= 0,
      },
      userName: {
        ...data.userName,
        warning: data.userName.value == "",
      },
    };
    setSearchUserData({ ...data });
    if (data.leadCount.warning === false && data.userName.warning === false) {
      onSumbitApiCall();
    }
  };

  const onSumbitApiCall = () => {
    setLoader(true);
    const onSuccess = (res) => {
      if (res?.data?.success === true) {
        toast.success(res?.data?.message);
        setClickOnDownloadCsv(false);
        setInfluncerData(res?.data?.data);
        dispatch(getUserDetail(updateTheLeadCountApiBody));
      } else if (res?.data?.success === false) {
        if (
          res?.data?.message ===
          "Your limit for free trial is over. Please update your plan to Individual Or Professional Plan"
        ) {
          setConfirmModal(true);
        }
        setClickOnDownloadCsv(true);
        // toast.error(res?.data?.message);
        dispatch(getUserDetail(updateTheLeadCountApiBody));
      }
      setSearchUserData({
        userName: {
          value: "",
          warning: false,
        },
        leadCount: {
          value: "",
          warning: false,
        },
      });
      setLoader(false);
    };
    const onError = (err) => {
      console.log("errr", err);
      setLoader(false);
    };

    const body = {
      instagramUsername: searchUserData.userName.value,
      leadRequested: Number(searchUserData.leadCount.value),
      userId: userDataFromDB.UserID,
    };
    getLeadInfluncerServices.getLeadByInfluncerNameorId(
      userAuthtoken,
      body,
      onSuccess,
      onError,
      {}
    );
  };

  //  handling the plan
  function planHandler(planId, buttonName, clickedCurrentPlan) {
    setConfirmModal(false);
    const values = {
      PlanID: planId,
      UserID: userData?.attributes?.sub,
    };
    dispatch(showRootLoader());
    // Hit API to initiate Stripe payment
    dispatch(userMakePayment({ userAuthtoken, values }))
      .then((res) => {
        if (res?.payload?.success === true) {
          // Initiate Stripe payment confirmation
          stripe
            .confirmCardPayment(res?.payload?.client_secret)
            .then(({ error, paymentIntent }) => {
              if (error) {
                console.log("Error during payment confirmation", error);
              } else {
                if (paymentIntent.status === "succeeded") {
                  if (buttonName === "Select") {
                    let dataSendToRedux = { ...userDataFromDB };
                    dataSendToRedux = {
                      ...dataSendToRedux,
                      CurrentPlan:
                        clickedCurrentPlan === "Individual"
                          ? "Individual"
                          : "Professional",
                    };
                    dispatch(modifiedUserDataFromDB(dataSendToRedux));
                  }
                }
                toast.success("Payment successful");
                dispatch(hideRootLoader());
              }
            })
            .catch((err) => {
              console.log("Err during confirmCardPayment", err);
              dispatch(hideRootLoader());
            });
        }
      })
      .catch((err) => {
        console.log("Err", err);
        dispatch(hideRootLoader());
        toast.error("Error initiating payment");
      });
  }

  return (
    <div className="categories_section">
      <h3 className="title"> Get Leads by Influencer </h3>
      {loader === true ? (
        <Loader />
      ) : (
        <form onSubmit={(e) => getUserDataOnClick(e)}>
          <div className="col-lg-5 col-12">
            <div className="input_label_wrap">
              <label>Influencer</label>
              <input
                type="text"
                placeholder="Enter UserName or UserId"
                value={searchUserData.userName.value}
                onChange={(e) => {
                  setSearchUserData((prev) => ({
                    ...prev,
                    userName: { warning: false, value: e.target.value },
                  }));
                }}
              />
              <small className="text-danger">
                {searchUserData.userName.warning === true
                  ? "enter a username"
                  : ""}
              </small>
            </div>
            <div className="input_label_wrap">
              <label>Lead Count</label>
              <input
                type="number"
                placeholder="Enter lead count"
                value={searchUserData.leadCount.value}
                onChange={(e) => {
                  setSearchUserData((prev) => ({
                    ...prev,
                    leadCount: {
                      warning: e.target.value <= 0,
                      value: e.target.value,
                    },
                  }));
                }}
              />
              <small className="text-danger">
                {searchUserData.leadCount.warning === true
                  ? "Lead count Should be above zero"
                  : ""}
              </small>
            </div>
            <div className="common_btns d-flex">
              <button className="primary_btn" type="submit">
                Download
              </button>

              {/* ---- download the csv file  --*/}
              {clickOnDownloadCsv === false &&
                influncerData &&
                influncerData.length > 0 && (
                  <CSVLink
                    className="btn primary_btn ms-3"
                    onClick={() => {
                      setClickOnDownloadCsv(true);
                      setSearchUserData({
                        userName: {
                          value: "",
                          warning: false,
                        },
                        leadCount: {
                          value: "",
                          warning: false,
                        },
                      });
                    }}
                    data={influncerData.toString()}
                    filename={`${searchUserData.userName.value}_${searchUserData.leadCount.value}`}
                  >
                    Download CSV
                  </CSVLink>
                )}
              {/* ----download the csv file  --*/}
            </div>
          </div>
        </form>
      )}
      {/* {influncerData &&
        Object.keys(influncerData).length > 0 &&
        loader === false ? (
          <Card style={{ width: "18rem" }} className="mt-5">
            <Card.Img src={influncerData.profilePicUrl} />
            <Card.Body>
              <Card.Title>Card Title</Card.Title>
              <Card.Text>
                Followers: {""}
                {influncerData.followerCount}
                {""}
                {""}
                Following:{""}
                {influncerData.followingCount}
              </Card.Text>
            </Card.Body>
          </Card>
        ) : null} */}

      {confirmModal && (
        <ConfirmationModal
          subscriptionData={adminSubscriptionList}
          show={confirmModal}
          hide={() => setConfirmModal(false)}
          title="Free Trial Limit Reached"
          message="You have hit the limit for the free trial.
                  Upgrade to either the Individual or Professional plan."
          loader={loaders.loader}
          planeHandler={planHandler}
        />
      )}
    </div>
  );
}

export default LeadByInfluncer;
